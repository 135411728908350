import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { errorToast } from '@components/toasts/Toasts';
import { createResponseError } from '@shared/api/createResponseError';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { reportAppError } from '@shared/reportAppError';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { useRestaurant } from '../../context/useRestaurant';
import {
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH,
} from '../../paths';
import { getListings } from './apiHelpers';

export const useListings = () => {
  const { pathname } = useLocation();
  const isOnDraft =
    pathname === OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH ||
    pathname === OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH;

  const { id: restaurantId } = useRestaurant();

  const [invalidateListings, setInvalidateListings] = useState(0);

  const refreshListings = () => setInvalidateListings((i) => i + 1);

  const { data: listings, isPending: isLoading } = useAbortEffect(
    {
      effect: async (signal) => {
        const response = await getListings(restaurantId, isOnDraft, signal);
        if (isErrorResponse(response)) {
          throw createResponseError(response);
        }
        return response;
      },
      onError: (e) => {
        errorToast({ message: 'Error loading listings' });
        reportAppError(e);
      },
    },
    [restaurantId, isOnDraft, invalidateListings],
  );

  return {
    isOnDraft,
    listings: listings || [],
    isLoading,
    refreshListings,
  };
};
