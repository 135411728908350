// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditListingDetailsPageBody__content___tzwqp{display:flex;gap:2.5rem}.EditListingDetailsPageBody__content___tzwqp .EditListingDetailsPageBody__displayPanel___UFOJ0{align-self:flex-start;max-width:64%;width:100%}.EditListingDetailsPageBody__content___tzwqp .EditListingDetailsPageBody__sidePanelContainer___XExya{display:flex;flex-direction:column;max-width:34%;width:100%}.EditListingDetailsPageBody__content___tzwqp .EditListingDetailsPageBody__sidePanel___FVMae{flex:1 0 0;display:flex;flex-direction:column;overflow-y:auto;row-gap:1rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/edit/EditListingDetailsPageBody.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CACA,UAAA,CAEA,+FACE,qBAAA,CACA,aAAA,CACA,UAAA,CAGF,qGACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,UAAA,CAGF,4FACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "EditListingDetailsPageBody__content___tzwqp",
	"displayPanel": "EditListingDetailsPageBody__displayPanel___UFOJ0",
	"sidePanelContainer": "EditListingDetailsPageBody__sidePanelContainer___XExya",
	"sidePanel": "EditListingDetailsPageBody__sidePanel___FVMae"
};
export default ___CSS_LOADER_EXPORT___;
