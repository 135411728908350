import { useLocation } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { PageHeader } from '../../../layout/PageHeader';
import {
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
} from '../../../paths';
import type { ListingLocationState } from '../utils/listingUtils';

export const CreateListingDetailsPageHeader = () => {
  const location = useLocation();
  const { isFromDraft = false, isFromFloorPlan = false } =
    (location.state as ListingLocationState) || {};

  const getCancelToPath = () => {
    if (isFromDraft) {
      if (isFromFloorPlan) return OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH;
      return OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH;
    }
    if (isFromFloorPlan) return OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH;
    return OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH;
  };

  return (
    <PageHeader category="Operations" title="Create Listing">
      <LinkStyledAsButton
        label="Cancel"
        to={getCancelToPath()}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Add Time and Price"
        variant={ButtonVariants.Primary}
        type="submit"
      />
    </PageHeader>
  );
};
