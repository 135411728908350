import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

export interface FinishWalkInConfirmationModalProps {
  closeModal: () => void;
  handleFinishWalkIn: () => void;
  isOpen: boolean;
}

export const FinishWalkInConfirmationModal = ({
  closeModal,
  handleFinishWalkIn,
  isOpen,
}: FinishWalkInConfirmationModalProps) => (
  <Modal
    ariaLabel="finish walk-in"
    isOpen={isOpen}
    onClose={closeModal}
    subtitle="Are you sure you wish to finish this walk-in?"
  >
    <ModalActions>
      <Button
        label="Go Back"
        onClick={closeModal}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Confirm"
        onClick={() => {
          handleFinishWalkIn();
          closeModal();
        }}
        variant={ButtonVariants.Primary}
      />
    </ModalActions>
  </Modal>
);
