import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { waiveCancellationFee } from '../apiHelpers';
import { useOccupantContext } from '../state/occupantContext';

export interface WaiveFeeConfirmationModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const WaiveFeeConfirmationModal = ({
  isOpen,
  closeModal,
}: WaiveFeeConfirmationModalProps) => {
  const { selectedOccupant, refreshOccupants } = useOccupantContext();
  const restaurant = useRestaurant();
  const [isWaivingFeePending, setIsWaivingFeePending] = useState(false);

  if (!selectedOccupant) return null;

  const handleConfirm = () => {
    const waive = async () => {
      setIsWaivingFeePending(true);
      const response = await waiveCancellationFee(
        restaurant.id,
        selectedOccupant.id,
      );
      if (response.ok) {
        successToast({
          message:
            "Success: The guest's reservation cancellation fee has been waived.",
        });
      } else {
        const errorMessage = await getErrorResponseMessage(response);
        errorToast({ message: errorMessage });
      }
      setIsWaivingFeePending(false);
      closeModal();
      refreshOccupants();
    };
    void waive();
  };

  return (
    <Modal
      ariaLabel="Confirm Waiving Cancellation Fee"
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you would like to waive the cancellation fee for this
        reservation?"
    >
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Confirm"
          onClick={handleConfirm}
          isDisabled={isWaivingFeePending}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
