import { useEffect, useState } from 'react';
import { errorToast } from '@components/toasts/Toasts';
import { isErrorResponse, isSuccessResponse } from '@shared/types/apiHelpers';
import { useRestaurant } from '../../context/useRestaurant';
import {
  createGuestNotes,
  getGuestNotes,
  type GuestNote,
  updateGuestNotes,
} from '../../guestBook/apiHelpers';
import type { GuestNotesEditFormOnSaveArgs } from './GuestNotesEditForm/GuestNotesEditForm';
import { GuestNotesEditForm } from './GuestNotesEditForm/GuestNotesEditForm';
import { GuestNotesShow } from './GuestNotesShow/GuestNotesShow';

export interface GuestNotesProps {
  guestId: string;
  onUpdate?: () => void;
}

const DEFAULT_ON_UPDATE_CALLBACK = () => null;

export const GuestNotes = ({
  guestId,
  onUpdate = DEFAULT_ON_UPDATE_CALLBACK,
}: GuestNotesProps) => {
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  const [noteData, setNoteData] = useState<GuestNote>();
  const { id: restaurantId } = useRestaurant();

  const fetchGuestNotes = async () => {
    const response = await getGuestNotes(restaurantId, guestId);
    if (isSuccessResponse(response)) {
      setNoteData(response);
    } else {
      setNoteData(undefined);
    }
  };

  useEffect(() => {
    void fetchGuestNotes();
  }, [guestId]);

  const handleSave = ({
    note: guestNoteContent,
  }: GuestNotesEditFormOnSaveArgs): void => {
    void (async () => {
      if (noteData) {
        const updateResponse = await updateGuestNotes({
          guestId,
          guestNoteContent,
          id: noteData.id,
          restaurantId,
        });

        if (updateResponse.ok) {
          setNoteData({ id: noteData.id, note: guestNoteContent });
          setIsEditFormVisible(false);
          await fetchGuestNotes();
          onUpdate();
        } else {
          const errorPayload = await updateResponse.json();
          errorToast({ message: errorPayload.message });
        }
      } else {
        const createResponse = await createGuestNotes({
          guestId,
          guestNoteContent,
          restaurantId,
        });
        if (isErrorResponse(createResponse)) {
          errorToast({ message: createResponse.message });
        } else {
          setNoteData({ id: createResponse.id, note: guestNoteContent });
          setIsEditFormVisible(false);
          await fetchGuestNotes();
          onUpdate();
        }
      }
    })();
  };

  return isEditFormVisible ? (
    <GuestNotesEditForm
      note={noteData?.note || ''}
      onDiscard={() => {
        setIsEditFormVisible(false);
      }}
      onSave={handleSave}
    />
  ) : (
    <GuestNotesShow
      note={noteData?.note || ''}
      onEdit={() => setIsEditFormVisible(true)}
    />
  );
};
