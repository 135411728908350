import cx from 'classnames';
import type { ReactElement } from 'react';
import { Card } from '@components/card/Card';
import { Icon } from '@components/icon/Icon';
import { centsToCompactDollar } from '@utils/currency';
import { appendGuestCountLabel } from '@utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import styles from './AdminAvailabilityItem.scss';
import type { AdminAvailabilityData } from './apiHelpers';

export interface SelectedAdminAvailabilityProps {
  selectedAvailability?: AdminAvailabilityData;
  selectedGuestCount: number;
  setSelectedAvailability: (availability?: AdminAvailabilityData) => void;
}

export type AdminAvailabilityItemProps = {
  availability: AdminAvailabilityData;
  availabilityItemAction: ReactElement;
} & SelectedAdminAvailabilityProps;

export const AdminAvailabilityItem = ({
  availability,
  selectedAvailability,
  selectedGuestCount,
  setSelectedAvailability,
  availabilityItemAction,
}: AdminAvailabilityItemProps) => {
  const {
    listing: { iconName, id: listingId, publicName, isCommunal, price },
    time,
  } = availability;

  const isSelected =
    selectedAvailability?.listing.id === listingId &&
    selectedAvailability?.time === time;

  const handleAvailabilityClick = () => {
    if (selectedAvailability && isSelected) {
      setSelectedAvailability(undefined);
    } else {
      setSelectedAvailability(availability);
    }
  };

  return (
    <li key={listingId}>
      <Card className={styles.card}>
        <button
          className={styles.availabilityInfo}
          data-testid={`${listingId}-${time}`}
          onClick={handleAvailabilityClick}
        >
          <Icon name={iconName} />
          <div className={styles.column}>
            <div className={styles.row}>
              <span className={cx(typography.h7, styles.time)}>
                {ISOTimeTo12HourTime(time)}
              </span>
              <span
                className={cx(typography.h7m, styles.price)}
                data-testid="buy-or-make-offer"
              >
                {centsToCompactDollar(price)}
              </span>
            </div>
            {isCommunal && (
              <div
                className={cx(typography.t1, styles.description)}
                data-testid="guest-count"
              >
                {appendGuestCountLabel(selectedGuestCount)}
              </div>
            )}
            <div className={cx(typography.t1, styles.description)}>
              {publicName}
            </div>
          </div>
        </button>
        {isSelected && availabilityItemAction}
      </Card>
    </li>
  );
};
