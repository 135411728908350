import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './MergeUnmergeTablesButton.scss';

interface MergeUnmergeTablesButtonProps {
  onClick: () => void;
}

export const MergeUnmergeTablesButton = ({
  onClick,
}: MergeUnmergeTablesButtonProps) => (
  <button onClick={onClick} className={cx(typography.h7, styles.button)}>
    <Icon name="splitMerge" />
    Merge / Unmerge Tables
  </button>
);
