import type { AnyIcon } from '@components/icon/Icon';
import type { ErrorResponse } from '@shared/types/apiHelpers';
import API from '../api/apiClient';

interface NewFloorPlanTable {
  iconName: AnyIcon;
  left: string;
  name: string;
  orientation: string;
  top: string;
}

interface UpdatedFloorPlanTable {
  id: string;
  iconName: AnyIcon;
  left: string;
  name: string;
  orientation: string;
  top: string;
}

export interface SaveEditPayload {
  createdFloorPlanTables: NewFloorPlanTable[];
  updatedFloorPlanTables: UpdatedFloorPlanTable[];
  deletedFloorPlanTableIds: string[];
}

export interface ImportedGuest {
  firstName: string;
  lastName: string;
  phone: string;
  notes: string;
}

export const saveEdit = async (
  restaurantId: string,
  floorPlanId: string,
  payload: SaveEditPayload,
): Promise<Response | ErrorResponse> => {
  const response = await API.put(
    `/restaurants/${restaurantId}/floor-plans/${floorPlanId}/floor-plan-tables`,
    payload,
  );

  if (response.ok) {
    return response;
  }

  return response.json();
};
