import { type AnyIcon } from '@components/icon/Icon';

interface ServiceStatusMeta {
  color: string;
  label: string;
}

export type ServiceStatus = keyof typeof SERVICE_STATUS_META;

export const SERVICE_STATUS_META = {
  appetizer: {
    color: 'var(--serviceStatusPurple)',
    label: 'Appetizer',
  },
  arrived: {
    color: 'var(--serviceStatusBlue)',
    label: 'Arrived',
  },
  booked: {
    color: 'var(--serviceStatusGrey)',
    label: 'Booked',
  },
  bottleService: {
    color: 'var(--serviceStatusPurple)',
    label: 'Bottle Service',
  },
  checkDropped: {
    color: 'var(--serviceStatusBlueGreen)',
    label: 'Check Dropped',
  },
  cleared: {
    color: 'var(--serviceStatusBlueGreen)',
    label: 'Cleared',
  },
  confirmed: {
    color: 'var(--serviceStatusGrey)',
    label: 'Confirmed',
  },
  dessert: {
    color: 'var(--serviceStatusPurple)',
    label: 'Dessert',
  },
  drinks: {
    color: 'var(--serviceStatusPurple)',
    label: 'Drinks',
  },
  entree: {
    color: 'var(--serviceStatusPurple)',
    label: 'Entree',
  },
  leftMessage: {
    color: 'var(--serviceStatusGrey)',
    label: 'Left Message',
  },
  noAnswer: {
    color: 'var(--serviceStatusGrey)',
    label: 'No Answer',
  },
  onTheWay: {
    color: 'var(--serviceStatusBlue)',
    label: 'On The Way',
  },
  paid: {
    color: 'var(--serviceStatusBlueGreen)',
    label: 'Paid',
  },
  partiallyArrived: {
    color: 'var(--serviceStatusBlue)',
    label: 'Partially Arrived',
  },
  partiallySeated: {
    color: 'var(--serviceStatusOrange)',
    label: 'Partially Seated',
  },
  postMealDrink: {
    color: 'var(--serviceStatusPurple)',
    label: 'Post Meal Drink',
  },
  runningLate: {
    color: 'var(--serviceStatusBlue)',
    label: 'Running Late',
  },
  seated: {
    color: 'var(--serviceStatusOrange)',
    label: 'Seated',
  },
  tableKnock: {
    color: 'var(--serviceStatusBlueGreen)',
    label: 'Table Knock',
  },
  tableReady: {
    color: 'var(--serviceStatusBlue)',
    label: 'Table Ready',
  },
  unconfirmed: {
    color: 'var(--serviceStatusLightGrey)',
    label: 'Unconfirmed',
  },
  wrongNumber: {
    color: 'var(--serviceStatusGrey)',
    label: 'Wrong Number',
  },
} as const satisfies Partial<Record<AnyIcon, ServiceStatusMeta>>;

// these are in display order...India 👀
export const SERVICE_STATUS_SELECTION_LIST = [
  'unconfirmed',
  'booked',
  'leftMessage',
  'wrongNumber',
  'noAnswer',
  'confirmed',
  'onTheWay',
  'runningLate',
  'arrived',
  'partiallyArrived',
  'tableReady',
  'partiallySeated',
  'seated', // hidden from dropdown
  'drinks',
  'appetizer',
  'entree',
  'dessert',
  'postMealDrink',
  'bottleService',
  'checkDropped',
  'paid',
  'tableKnock',
  'cleared',
] as const satisfies ServiceStatus[];
