import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { SomethingWentWrongHero } from './SomethingWentWrongHero';
import { SomethingWentWrongPage } from './SomethingWentWrongPage';

interface ErrorHandlerProps {
  withoutLayout?: boolean;
}

export const ErrorHandler = ({ withoutLayout = false }: ErrorHandlerProps) => {
  const error = useRouteError();

  useEffect(() => {
    captureException(error);
  }, []);

  if (withoutLayout) return <SomethingWentWrongHero />;

  return <SomethingWentWrongPage />;
};
