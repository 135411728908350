import { get, some } from 'lodash-es';
import { useState } from 'react';
import { type SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { type MultiValue } from 'react-select';
import { Button, ButtonVariants } from '@components/button/Button';
import { EndlessDateFormControl } from '@components/endlessDateFormControl/EndlessDateFormControl';
import {
  type FormSelectItem,
  type SelectStringOption,
} from '@components/formSelect/FormSelect';
import { HookAwareFormInput } from '@components/hookAwareFormInput/HookAwareFormInput';
import { HookAwareFormMultiSelect } from '@components/hookAwareFormSelect/HookAwareFormMultiSelect';
import { HookAwareTextarea } from '@components/hookAwareTextarea/HookAwareTextarea';
import { errorToast } from '@components/toasts/Toasts';
import { type ErrorResponse, isErrorResponse } from '@shared/types/apiHelpers';
import { DAYS_IN_WEEK, todayInTimezone } from '@utils/date';
import { OPERATIONS_EVENTS_PATH } from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import {
  createRestaurantEvent,
  editRestaurantEvent,
  type NewRestaurantEvent,
  type RestaurantEvent,
} from './apiHelpers';
import {
  REPEAT_OPTION_DAILY,
  REPEAT_OPTIONS_DEFAULT,
  REPEAT_OPTIONS_WEEKDAYS,
} from './constants';
import styles from './RestaurantEventForm.scss';

interface RestaurantEventFormData {
  description: string;
  endDate: string;
  repeat: SelectStringOption[];
  startDate: string;
}
const ALL_DAYS_OF_WEEK = ['0', '1', '2', '3', '4', '5', '6'];
const DAILY_VALUE = '-1';
const ONE_REPEAT_VALUE = 1;

const mapDayPickerValuesToArrayOfNumbers = (
  repeat: SelectStringOption[],
): string[] => {
  if (repeat.length === ONE_REPEAT_VALUE && repeat[0].value === DAILY_VALUE) {
    return ALL_DAYS_OF_WEEK;
  }

  return repeat.map((selectedDay) => selectedDay.value);
};

export const isDailySelected = (
  repeatOptions: MultiValue<FormSelectItem>,
): boolean => some(repeatOptions, REPEAT_OPTION_DAILY);

export type DaysOfWeek =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday';

const getRepeatOptions = (
  repeatOptions: SelectStringOption[] = [],
): SelectStringOption[] =>
  isDailySelected(repeatOptions) || repeatOptions.length === DAYS_IN_WEEK
    ? REPEAT_OPTIONS_WEEKDAYS
    : REPEAT_OPTIONS_DEFAULT;

export const getRepeatSelectValue = (
  repeat: string[],
): SelectStringOption[] => {
  const DAY_INDEX_MAP: Record<string, DaysOfWeek> = {
    '0': 'Sunday',
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday',
  };

  if (repeat.length === DAYS_IN_WEEK) {
    return [
      {
        label: 'Daily',
        value: '-1',
      },
    ];
  }
  return repeat.map((dayIndex: string) => ({
    label: DAY_INDEX_MAP[dayIndex],
    value: dayIndex,
  }));
};

export const RestaurantEventForm = () => {
  const restaurant = useRestaurant();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const restaurantEvent: RestaurantEvent = get(
    location,
    'state.restaurantEvent',
    {},
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<RestaurantEventFormData>({
    defaultValues: {
      description: restaurantEvent.description,
      endDate: restaurantEvent.endDate || undefined,
      repeat: restaurantEvent.repeat
        ? getRepeatSelectValue(restaurantEvent.repeat)
        : [],
      startDate:
        restaurantEvent.startDate || todayInTimezone(restaurant.timezone),
    },
  });

  const endDate = useWatch({ control, name: 'endDate' });
  const repeat = useWatch({ control, name: 'repeat' });
  const startDate = useWatch({ control, name: 'startDate' });
  const showRepeatInput = startDate && startDate !== endDate;

  const repeatOptions: SelectStringOption[] = repeat
    ? getRepeatOptions(repeat)
    : REPEAT_OPTIONS_DEFAULT;

  const handleRepeatChange = (
    value: MultiValue<FormSelectItem>,
  ): MultiValue<SelectStringOption> => {
    const result = isDailySelected(value)
      ? REPEAT_OPTIONS_WEEKDAYS
      : (value as MultiValue<SelectStringOption>);

    return result;
  };

  const onValidSubmit: SubmitHandler<RestaurantEventFormData> = async (
    data,
  ) => {
    setIsLoading(true);

    const transformedRepeatValue = mapDayPickerValuesToArrayOfNumbers(
      data.repeat || [],
    );

    const eventPayload: NewRestaurantEvent = {
      description: data.description,
      endDate: data.endDate,
      repeat: transformedRepeatValue,
      startDate: data.startDate,
    };
    let response: Response | ErrorResponse;

    if (restaurantEvent.id) {
      response = await editRestaurantEvent(restaurant.id, {
        ...eventPayload,
        id: restaurantEvent.id,
      });
    } else {
      response = await createRestaurantEvent(restaurant.id, eventPayload);
    }

    if (isErrorResponse(response)) {
      errorToast({ message: response.message });
      setIsLoading(false);
    } else {
      navigate(OPERATIONS_EVENTS_PATH);
    }
  };

  const handleDiscardClick = () => {
    navigate(OPERATIONS_EVENTS_PATH);
  };

  return (
    <>
      <PageHeader title="Events" />
      <PageContent>
        <form
          aria-labelledby="form-title"
          className={styles.form}
          onSubmit={handleSubmit(onValidSubmit)}
        >
          <header>
            <h2 className={typography.h5} id="form-title">
              {restaurantEvent.id ? 'Edit Event' : 'New Event'}
            </h2>
          </header>
          <HookAwareTextarea
            control={control}
            errors={errors}
            isDisabled={false}
            label="Description"
            maxLength={200}
            name="description"
            register={register}
            validations={{
              minLength: {
                message: 'Description must be at least 4 characters',
                value: 4,
              },
              required: true,
            }}
          />
          <HookAwareFormInput
            errors={errors}
            inputType="date"
            isDisabled={false}
            label="Start Date"
            name="startDate"
            register={register}
            validations={{
              required: true,
            }}
          />
          <EndlessDateFormControl
            className={styles.endDate}
            control={control}
            errors={errors}
            label="End Date"
            name="endDate"
            register={register}
            validations={{
              ...(startDate && {
                min: {
                  value: startDate,
                  message: 'End Date must be on or after Start Date',
                },
              }),
            }}
          />
          {showRepeatInput && (
            <HookAwareFormMultiSelect
              containerClassName={styles.repeatContainer}
              control={control}
              errors={errors}
              formSelectClassName={styles.repeatFormSelect}
              handleChange={handleRepeatChange}
              hasError={'repeat' in errors}
              isDisabled={false}
              label="Repeat"
              name="repeat"
              options={repeatOptions}
              setTooltipIsOpen={() => false}
              shouldUnregister
              validations={{ required: true }}
            />
          )}
          <div className={styles.buttonContainer}>
            <Button
              label="Discard Edits"
              onClick={handleDiscardClick}
              type="button"
              variant={ButtonVariants.Secondary}
            />
            <Button
              isDisabled={isLoading}
              label="Save"
              type="submit"
              variant={ButtonVariants.Primary}
            />
          </div>
        </form>
      </PageContent>
    </>
  );
};
