import cx from 'classnames';
import type { ReactNode } from 'react';
import typography from '~styles/typography.scss';
import styles from './Layout.scss';

export interface PageHeaderProps {
  category?: string;
  title: string;
  children?: ReactNode;
}

export const PageHeader = ({ category, title, children }: PageHeaderProps) => (
  <header className={styles.pageHeader}>
    <div className={styles.pageMargin}>
      <h1 className={typography.h4}>
        {category && (
          <div className={cx(styles.category, typography.t3)}>{category}</div>
        )}
        {title}
      </h1>
      <div className={styles.actions}>{children}</div>
    </div>
  </header>
);
