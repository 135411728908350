// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HookAwareTextarea__fieldContainer___mLoVp{align-items:flex-start;display:flex;justify-content:space-between}.HookAwareTextarea__fieldContainer___mLoVp .HookAwareTextarea__labelContainer___ln8T2{align-items:center;display:flex;gap:8px}.HookAwareTextarea__fieldContainer___mLoVp .HookAwareTextarea__labelContainer___ln8T2 label{margin-top:.875rem}.HookAwareTextarea__fieldContainer___mLoVp .HookAwareTextarea__labelContainer___ln8T2 .HookAwareTextarea__labelError___jiw11{color:var(--pink)}.HookAwareTextarea__fieldContainer___mLoVp .HookAwareTextarea__inputContainer___qDBbd textarea{background:var(--darkGrey100);border-radius:5px;border:3px solid var(--darkGrey100);color:var(--darkBlue);font-family:-apple-system,BlinkMacSystemFont,Inter,sans-serif;height:8.5rem;padding:1rem 1rem 2.5rem;width:25rem;margin-bottom:-4px}.HookAwareTextarea__fieldContainer___mLoVp .HookAwareTextarea__inputContainer___qDBbd textarea:active,.HookAwareTextarea__fieldContainer___mLoVp .HookAwareTextarea__inputContainer___qDBbd textarea:focus,.HookAwareTextarea__fieldContainer___mLoVp .HookAwareTextarea__inputContainer___qDBbd textarea:hover{border:3px solid var(--white)}.HookAwareTextarea__fieldContainer___mLoVp .HookAwareTextarea__inputContainer___qDBbd .HookAwareTextarea__inputError___gOWet{background-color:var(--red100);color:var(--pink)}.HookAwareTextarea__fieldContainer___mLoVp .HookAwareTextarea__inputContainer___qDBbd>div{width:25rem}", "",{"version":3,"sources":["webpack://./src/shared/components/hookAwareTextarea/HookAwareTextarea.scss"],"names":[],"mappings":"AAAA,2CACE,sBAAA,CACA,YAAA,CACA,6BAAA,CAEA,sFACE,kBAAA,CACA,YAAA,CACA,OAAA,CAEA,4FACE,kBAAA,CAGF,6HACE,iBAAA,CAKF,+FACE,6BAAA,CACA,iBAAA,CACA,mCAAA,CACA,qBAAA,CACA,6DAAA,CACA,aAAA,CACA,wBAAA,CACA,WAAA,CACA,kBAAA,CAEA,gTAGE,6BAAA,CAIJ,6HACE,8BAAA,CACA,iBAAA,CAGF,0FACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldContainer": "HookAwareTextarea__fieldContainer___mLoVp",
	"labelContainer": "HookAwareTextarea__labelContainer___ln8T2",
	"labelError": "HookAwareTextarea__labelError___jiw11",
	"inputContainer": "HookAwareTextarea__inputContainer___qDBbd",
	"inputError": "HookAwareTextarea__inputError___gOWet"
};
export default ___CSS_LOADER_EXPORT___;
