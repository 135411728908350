import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import typography from '~styles/typography.scss';
import styles from './SeatOutsideListingConfirmationModal.scss';

export interface SeatOutsideListingConfirmationModalProps {
  guestName: string;
  closeModal: () => void;
  handleConfirm: () => void;
}

export const SeatOutsideListingConfirmationModal = ({
  guestName,
  closeModal,
  handleConfirm,
}: SeatOutsideListingConfirmationModalProps) => {
  const onConfirm = () => {
    handleConfirm();
    closeModal();
  };

  return (
    <Modal isOpen onClose={closeModal} title="Warning">
      <p className={cx(typography.c2, styles.message)}>
        You are about to seat {guestName} at a table outside their purchased
        selection. Proceed?
        <br />
        <Link
          rel="noopener noreferrer nofollow"
          target="_blank"
          className={styles.link}
          to={`${process.env.WEB_CUSTOMER_HOST}/terms-of-service`}
        >
          Terms of&nbsp;Service
        </Link>
      </p>
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Confirm Seating"
          onClick={onConfirm}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
