import type { ApiResponse } from '@shared/types/apiHelpers';
import API from '../api/apiClient';

export interface RestaurantMetadata {
  id: string;
  name: string;
  postalCode: string;
  timezone: string;
  maxReservationGuests: number;
  coverLimit: number | null;
}

export const getRestaurants = async (): Promise<
  ApiResponse<RestaurantMetadata[]>
> => {
  const response = await API.get('/restaurants/');
  return (await response.json()) as ApiResponse<RestaurantMetadata[]>;
};
