import cx from 'classnames';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Card } from '@components/card/Card';
import { successToast } from '@components/toasts/Toasts';
import { isErrorResponse } from '@shared/types/apiHelpers';
import typography from '~styles/typography.scss';
import { useAuth } from '../context/authContext';
import { FORGOT_PASSWORD_ROOT_PATH, ROOT_PATH } from '../paths';
import styles from './styles.scss';

export const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState('');
  const { loginAndSetRestaurants } = useAuth();

  const { state } = useLocation();
  const navigate = useNavigate();

  if (state?.message) {
    successToast({
      message: state.message,
      onClose: () => {
        navigate(ROOT_PATH, {});
      },
    });
  }

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response = await loginAndSetRestaurants(email, password);

    if (isErrorResponse(response)) {
      setAuthError(response.message);
    }
  };

  return (
    <main className={styles.pageContainer}>
      <Card className={styles.card}>
        <form className={styles.formContainer} onSubmit={handleOnSubmit}>
          <h1 className={cx(typography.h4, styles.formHeader)}>Log In</h1>
          <label className={cx(typography.label, styles.formLabel)}>
            Email
            <input
              className={cx(styles.input, typography.body)}
              data-testid="email-input"
              name="email"
              onChange={(event) => {
                setAuthError('');
                setEmail(event.target.value);
              }}
              type="email"
            />
          </label>
          <label className={cx(typography.label, styles.formLabel)}>
            Password
            <input
              className={cx(styles.input, typography.body)}
              data-testid="password-input"
              name="password"
              onChange={(event) => {
                setAuthError('');
                setPassword(event.target.value);
              }}
              type="password"
            />
          </label>
          {authError && <p className={styles.error}>{authError}</p>}
          <Button
            className={cx(styles.submitButton, typography.body)}
            isDisabled={!email || !password}
            label="Submit"
            type="submit"
            variant={ButtonVariants.Primary}
          />
          <Link
            className={styles.resetPasswordLink}
            to={FORGOT_PASSWORD_ROOT_PATH}
          >
            Forgot Password
          </Link>
        </form>
      </Card>
    </main>
  );
};
