import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import type { Listing } from '../operations/listings/apiHelpers';
import * as listingApiHelpers from '../operations/listings/apiHelpers';
import { useRestaurant } from './useRestaurant';

interface PublishedListingsContextValue {
  listings: Listing[];
}

const PublishedListingsContext =
  createContext<PublishedListingsContextValue | null>(null);

export const usePublishedListings = () =>
  useDefinedContext(PublishedListingsContext);

export const PublishedListingsContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const { id: restaurantId } = useRestaurant();
  const [listings, setListings] = useState<Listing[]>([]);

  useAbortEffect(
    async (signal) => {
      const response = await listingApiHelpers.getListings(
        restaurantId,
        false,
        signal,
      );
      if (isSuccessResponse(response)) {
        setListings(response);
      }
    },
    [restaurantId],
  );

  const value = useMemo<PublishedListingsContextValue>(
    () => ({ listings }),
    [listings],
  );

  return (
    <PublishedListingsContext.Provider value={value}>
      {children}
    </PublishedListingsContext.Provider>
  );
};
