// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestTagPill__tag___yQAm2{border-radius:80px;border:1px solid var(--white);display:inline-block;margin:0 4px 4px 0;padding:4px 8px;font-weight:700}.GuestTagPill__tag___yQAm2 svg{margin-right:4px;width:16px}.GuestTagPill__green___Z8_vj{border:1px solid var(--green200);color:var(--green200)}.GuestTagPill__white___J2Qk0{border:1px solid var(--white);color:var(--white)}.GuestTagPill__gold___ySFWt{border:1px solid var(--gold);color:var(--gold);background-color:var(--darkGrey400)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/guestTags/GuestTagPill/GuestTagPill.scss"],"names":[],"mappings":"AAAA,2BACE,kBAAA,CACA,6BAAA,CACA,oBAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CAEA,+BACE,gBAAA,CACA,UAAA,CAIJ,6BACE,gCAAA,CACA,qBAAA,CAGF,6BACE,6BAAA,CACA,kBAAA,CAGF,4BACE,4BAAA,CACA,iBAAA,CACA,mCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "GuestTagPill__tag___yQAm2",
	"green": "GuestTagPill__green___Z8_vj",
	"white": "GuestTagPill__white___J2Qk0",
	"gold": "GuestTagPill__gold___ySFWt"
};
export default ___CSS_LOADER_EXPORT___;
