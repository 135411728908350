import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { PageHeader } from '../../../layout/PageHeader';

export const CreateListingTimeAndPricePageHeader = () => {
  const navigate = useNavigate();
  return (
    <PageHeader category="Operations" title="Create Listing">
      <Button
        label="Back"
        onClick={() => navigate(-1)}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Save Draft"
        type="submit"
        name="draft"
        variant={ButtonVariants.Secondary}
      />
      <Button
        label="Publish Listing"
        type="submit"
        name="publish"
        variant={ButtonVariants.Primary}
      />
    </PageHeader>
  );
};
