// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "nav.AdminHeader__header___oWEJn{align-items:center;display:flex;height:var(--headerHeight);justify-content:space-between;column-gap:.5rem}.AdminHeader__restaurantName___AfdLF{margin-right:auto}.AdminHeader__logo___u83lK{margin-right:.5rem;min-width:1.5rem;stroke:var(--accent300);width:1.5rem}.AdminHeader__restaurantSelector___CuJa3{margin-right:auto}.AdminHeader__restaurantSelector___CuJa3>div{border:1px solid rgba(0,0,0,0);background-color:var(--primary400)}.AdminHeader__restaurantSelector___CuJa3>div:hover{border:1px solid rgba(0,0,0,0)}.AdminHeader__restaurantSelector___CuJa3 .select__single-value{font-size:.875rem;font-weight:400;line-height:1rem}.AdminHeader__restaurantSelectorLabel___bY5Kc{font-style:italic}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/layout/AdminHeader.scss"],"names":[],"mappings":"AAEA,gCACE,kBAAA,CACA,YAAA,CACA,0BAAA,CACA,6BAAA,CACA,gBAAA,CAGF,qCACE,iBAAA,CAGF,2BACE,kBAAA,CACA,gBAAA,CACA,uBAAA,CACA,YAAA,CAGF,yCACE,iBAAA,CAEA,6CACE,8BAAA,CACA,kCAAA,CAGF,mDACE,8BAAA,CAIF,+DACE,iBAAA,CACA,eAAA,CACA,gBAAA,CAKJ,8CACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "AdminHeader__header___oWEJn",
	"restaurantName": "AdminHeader__restaurantName___AfdLF",
	"logo": "AdminHeader__logo___u83lK",
	"restaurantSelector": "AdminHeader__restaurantSelector___CuJa3",
	"restaurantSelectorLabel": "AdminHeader__restaurantSelectorLabel___bY5Kc"
};
export default ___CSS_LOADER_EXPORT___;
