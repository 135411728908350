// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestImport__container___x0Cl7{display:flex;flex-direction:column;row-gap:2rem}.GuestImport__container___x0Cl7 form{display:flex;flex-direction:column;align-items:flex-start;row-gap:1rem}.GuestImport__container___x0Cl7 table{width:100%}.GuestImport__container___x0Cl7 table tr{display:grid;justify-items:flex-start;grid-template-columns:1fr 1fr 1fr 1fr;gap:1rem}.GuestImport__container___x0Cl7 table tbody{border-top:1px solid var(--darkGrey200)}.GuestImport__container___x0Cl7 table tbody tr:first-child{margin-top:1rem}.GuestImport__container___x0Cl7 .GuestImport__error___eZ6sn{color:var(--pink)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/support/GuestImport.scss"],"names":[],"mappings":"AAAA,gCACE,YAAA,CACA,qBAAA,CACA,YAAA,CAEA,qCACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,YAAA,CAGF,sCACE,UAAA,CAEA,yCACE,YAAA,CACA,wBAAA,CACA,qCAAA,CACA,QAAA,CAGF,4CACE,uCAAA,CAEA,2DACE,eAAA,CAKN,4DACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GuestImport__container___x0Cl7",
	"error": "GuestImport__error___eZ6sn"
};
export default ___CSS_LOADER_EXPORT___;
