import { Spinner } from '@components/Spinner';
import { useEditListingContext } from './EditListingContext';
import { EditListingTimeAndPriceForm } from './EditListingTimeAndPriceForm';

export const EditListingTimeAndPricePage = () => {
  const { isListingLoading, existingListing } = useEditListingContext();
  if (isListingLoading) {
    return <Spinner />;
  }

  if (!isListingLoading && !existingListing) {
    throw new Error('Listing not found');
  }

  if (existingListing) {
    return <EditListingTimeAndPriceForm existingListing={existingListing} />;
  }
  return null;
};
