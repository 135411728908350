import cx from 'classnames';
import { useMemo } from 'react';
import { IconButton } from '@components/iconButton/IconButton';
import { centsToWholeDollar } from '@utils/currency';
import { formatWeekDays, toShortDateRange } from '@utils/date';
import { ISOTimeAddMinutes, ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { ListingKebab } from '../kebab/ListingKebab';
import { useListingsContext } from '../ListingsContext';
import {
  listingsOverlap,
  seatingTypeFromIsCommunal,
} from '../utils/listingUtils';
import styles from './ListingDetailsSheet.scss';
import { ListingOverlapWarning } from './ListingOverlapWarning';

export const ListingDetailsSheet = () => {
  const {
    allListings,
    clearSelectedListing,
    selectedListing: listing,
  } = useListingsContext();

  // memoize since this can be expensive
  const hasOverlap = useMemo(
    () =>
      listing &&
      listing.status !== 'draft' &&
      allListings.some(
        (other) => other.id !== listing.id && listingsOverlap(listing, other),
      ),
    [allListings, listing],
  );

  if (!listing) {
    return null;
  }

  const {
    endDate,
    endTime,
    interval,
    inventoryCount,
    maximumGuests,
    minimumGuests,
    name,
    price,
    publicName,
    repeat,
    startDate,
    startTime,
    turnTime,
  } = listing;
  return (
    <article aria-label="Listing Details" className={styles.main}>
      {hasOverlap && <ListingOverlapWarning />}
      <section>
        <div className={styles.heading}>
          <h2 className={cx(typography.h5, styles.name)}>{name}</h2>
          <ListingKebab listing={listing} />
          <IconButton
            ariaLabel="close listing details"
            onClick={() => clearSelectedListing()}
            iconName="close"
            className={styles.button}
          />
        </div>
        <p className={cx(typography.t1, styles.lifespan)}>
          {toShortDateRange(startDate, endDate)}
        </p>
      </section>
      <section className={styles.timeAndPriceCard}>
        <div className={typography.h7m}>{formatWeekDays(repeat)}</div>
        <div className={styles.timeAndPrice}>
          <div
            className={cx(typography.t1, styles.timeRange)}
            data-testid="listing-details-time-range"
          >
            <span className={typography.t1}>
              {ISOTimeTo12HourTime(startTime)}–{ISOTimeTo12HourTime(endTime)}
            </span>
            <span className={typography.t1}>
              {` (last turn ends ${ISOTimeTo12HourTime(ISOTimeAddMinutes(endTime, turnTime))})`}
            </span>
          </div>
          <div className={typography.h7}>{centsToWholeDollar(price)}</div>
        </div>
      </section>
      <section className={styles.details}>
        <h3 className={cx(typography.h7, styles.title)}>Listing Details</h3>
        <dl>
          <div className={styles.detail}>
            <dt className={typography.t1}>Guest Facing Name</dt>
            <dd className={typography.t1}>{publicName}</dd>
          </div>
          <div className={styles.detail}>
            <dt className={typography.t1}>Seating Type</dt>
            <dd className={typography.t1}>
              {seatingTypeFromIsCommunal(listing.isCommunal)}
            </dd>
          </div>
          <div className={styles.detail}>
            <dt className={typography.t1}>Min-Max Guests</dt>
            <dd className={typography.t1}>
              {minimumGuests}-{maximumGuests}
            </dd>
          </div>
          <div className={styles.detail}>
            <dt className={typography.t1}>Inventory</dt>
            <dd className={typography.t1}>{inventoryCount}</dd>
          </div>
          <div className={styles.detail}>
            <dt className={typography.t1}>Turn Time</dt>
            <dd className={typography.t1}>{turnTime} min</dd>
          </div>
          <div className={styles.detail}>
            <dt className={typography.t1}>Interval</dt>
            <dd className={typography.t1}>{interval} min</dd>
          </div>
        </dl>
      </section>
    </article>
  );
};
