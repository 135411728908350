// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListingKebab__kebab___vRtzO>button{padding:0}.ListingKebab__kebab___vRtzO>button svg{height:24px;width:24px;fill:var(--white)}.ListingKebab__kebab___vRtzO>div>button:last-child{padding-top:0}.ListingKebab__kebab___vRtzO>div>button:last-child::before{content:\"\";background-color:var(--darkGrey200);border-radius:1rem;display:block;height:.065rem;margin-top:.5rem;margin-bottom:1rem;width:100%}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/kebab/ListingKebab.scss"],"names":[],"mappings":"AACE,oCACE,SAAA,CAEA,wCACE,WAAA,CACA,UAAA,CACA,iBAAA,CAMA,mDACE,aAAA,CAEA,2DACE,UAAA,CACA,mCAAA,CACA,kBAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kebab": "ListingKebab__kebab___vRtzO"
};
export default ___CSS_LOADER_EXPORT___;
