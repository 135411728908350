import cx from 'classnames';
import { SnapshotFloorPlan } from '@components/floorPlan/SnapshotFloorPlan';
import { Icon } from '@components/icon/Icon';
import { useFeatureFlagContext } from '@shared/context/featureFlagContext';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { toShortDateFormat } from '@utils/date';
import { appendGuestCountLabel } from '@utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import type { RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import { isReservationFinished } from 'restaurantAdmin/reservations/reservationUtils';
import typography from '~styles/typography.scss';
import { RefundButton } from '../components/refundButton/RefundButton';
import { RemediationForm } from '../components/remediationForm/RemediationForm';
import { ReservationGuestSheetReceipt } from '../reservations/service/sidePanel/ReservationGuestSheetReceipt';
import type { GuestReservation } from './apiHelpers';
import styles from './GuestReservationDetailsDrawer.scss';
import { TransactionLog } from './TransactionLog';

interface GuestReservationDetailsDrawerProps {
  guestReservation: GuestReservation;
  onClose: () => void;
}

export const GuestReservationDetailsDrawer = ({
  onClose,
  guestReservation,
}: GuestReservationDetailsDrawerProps) => {
  const { isEnabled } = useFeatureFlagContext<RestaurantAdminFeatureFlag>();
  const { ref } = useHandleClickOutside(onClose);
  const { transactionDetails } = guestReservation;
  const { refundAmount } = transactionDetails;
  const isRefunded = !!refundAmount;
  const tableName =
    guestReservation.seatedTableName &&
    isReservationFinished(guestReservation.status)
      ? `(${guestReservation.seatedTableName})`
      : '';

  return (
    <div
      aria-label="reservation details"
      className={styles.drawer}
      ref={ref}
      role="dialog"
    >
      <button
        aria-label="close reservation details"
        className={styles.closeButton}
        onClick={onClose}
      >
        <Icon name="close" />
      </button>
      <section className={styles.floorPlanSection}>
        <hgroup>
          <h3 className={typography.h6}>
            {`${toShortDateFormat(guestReservation.date)}, ${ISOTimeTo12HourTime(
              guestReservation.time,
            )}`}
          </h3>
          <p className={cx(typography.h6, styles.capitalize)}>
            {`${guestReservation.status} ${tableName}`}
          </p>
        </hgroup>
        <div>
          <p className={cx(typography.c3, styles.capitalize)}>
            {guestReservation.publicName}
          </p>
          <p className={typography.c3}>
            {appendGuestCountLabel(guestReservation.guestCount)}
          </p>
        </div>
        <SnapshotFloorPlan reservationId={guestReservation.id} />
      </section>
      <ReservationGuestSheetReceipt
        className={styles.receiptSection}
        fee={transactionDetails.fee}
        price={transactionDetails.price}
        receiptNumber={transactionDetails.receiptNumber}
        refundAmount={refundAmount}
        tax={transactionDetails.tax}
      />
      {transactionDetails.createdBy.fullName && (
        <section className={styles.bookedBySection}>
          <h3 className={typography.c3}>Booked By</h3>
          <p className={typography.c3}>
            {transactionDetails.createdBy.fullName}
          </p>
        </section>
      )}
      <RemediationForm
        disableConfirmationModalPortal
        reservationId={guestReservation.id}
        reservationStatus={guestReservation.status}
      />
      <RefundButton
        reservationId={guestReservation.id}
        reservationPrice={transactionDetails.price}
        isRefunded={isRefunded}
      />
      {isEnabled('transactionLogFlag') && (
        <TransactionLog reservation={guestReservation} />
      )}
    </div>
  );
};
