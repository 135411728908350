import cx from 'classnames';
import { Button, ButtonVariants } from '@components/button/Button';
import { CancellationPolicyNotice } from '@components/cancellationPolicyNotice/CancelationPolicyNotice';
import { CardElement } from '@components/cardElement/CardElement';
import { CurrencyInput } from '@components/currencyInput/CurrencyInput';
import { Error } from '@components/error/Error';
import { Icon } from '@components/icon/Icon';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { MAXIMUM_LIST_PRICE_IN_DOLLARS } from '@shared/reservations/constants';
import { centsToDollar, wholeDollarsToCents } from '@utils/currency';
import typography from '~styles/typography.scss';
import styles from './ConciergeDrawer.scss';
import { ConciergeReceipt } from './ConciergeReceipt';
import { PhoneNumberInput } from './PhoneNumberInput';
import { useAvailabilityDrawer } from './state/availabilityDrawerContext';
import { CheckoutTypes, useAdminCheckout } from './useAdminCheckout';

export const ConciergeDrawer = () => {
  const { closeDrawer, data, isOpen } = useAvailabilityDrawer();

  const { ref } = useHandleClickOutside(closeDrawer);
  const {
    cancellationPolicy,
    customPriceInDollars,
    formErrors,
    handleBookReservationClick,
    handleCardElementOnChange,
    handleCheckoutTypeOnChange,
    handleCustomPriceChange,
    isBookButtonDisabled,
    onFirstNameChange,
    onLastNameChange,
    onPhoneNumberChange,
    pricingInfo,
    responseError,
    selectedCheckoutType,
  } = useAdminCheckout();

  const containerClassNames = cx({
    [styles.container]: true,
    [styles.isOpen]: isOpen,
    [styles.isClosed]: !isOpen,
  });

  const drawerClassNames = cx({
    [styles.drawer]: true,
    [styles.drawerOpen]: isOpen,
    [styles.drawerClosed]: !isOpen,
  });

  if (!data) {
    // No availability selected
    return null;
  }
  const customPriceInCents = Number(customPriceInDollars) * 100;

  const showReceipt =
    pricingInfo &&
    (selectedCheckoutType === CheckoutTypes.FullPrice ||
      selectedCheckoutType === CheckoutTypes.CustomPrice);

  const showFullPriceOption = data.listingPrice > 0;

  const receiptPrice =
    selectedCheckoutType === CheckoutTypes.FullPrice
      ? data.listingPrice
      : wholeDollarsToCents(parseInt(customPriceInDollars, 10));

  const isCustomPriceSelected =
    selectedCheckoutType === CheckoutTypes.CustomPrice;

  return (
    <div
      aria-hidden={!isOpen}
      aria-labelledby="book_reservation_drawer_label"
      aria-modal="true"
      className={containerClassNames}
      role="dialog"
    >
      <div className={styles.curtain} />
      <div className={drawerClassNames} ref={ref}>
        <button
          aria-label="close"
          className={styles.closeButton}
          onClick={closeDrawer}
        >
          <Icon name="close" />
        </button>
        <h2 className={styles.drawerLabel} id="book_reservation_drawer_label">
          Book Reservation
        </h2>
        <p className={cx(typography.c2, styles.subtitle)}>
          Host-booked reservations are free of charge by default.
        </p>
        <div className={styles.drawerForm}>
          <div className={styles.checkoutType}>
            <span className={styles.checkoutTypeLabel}>Checkout type</span>
            <div className={styles.radioGroup} role="radiogroup">
              <label>
                <span>Free reservation</span>
                <input
                  checked={selectedCheckoutType === CheckoutTypes.NoPrice}
                  name="checkoutType"
                  onChange={handleCheckoutTypeOnChange}
                  type="radio"
                  value={CheckoutTypes.NoPrice}
                />
              </label>
              {showFullPriceOption && (
                <label>
                  <span>Full price</span>
                  <input
                    checked={selectedCheckoutType === CheckoutTypes.FullPrice}
                    name="checkoutType"
                    onChange={handleCheckoutTypeOnChange}
                    type="radio"
                    value={CheckoutTypes.FullPrice}
                  />
                </label>
              )}
              <label>
                <span>Custom price</span>
                <input
                  checked={isCustomPriceSelected}
                  name="checkoutType"
                  onChange={handleCheckoutTypeOnChange}
                  type="radio"
                  value={CheckoutTypes.CustomPrice}
                />
              </label>
            </div>
          </div>
          {isCustomPriceSelected && (
            <>
              <CurrencyInput
                className={styles.customPriceInput}
                id="customPriceInput"
                label="Custom price"
                maxValue={MAXIMUM_LIST_PRICE_IN_DOLLARS}
                name="customPriceInput"
                onChange={handleCustomPriceChange}
                placeholder="Custom price"
                value={customPriceInDollars}
              />
              <div className={styles.row}>
                <div className={typography.label}>Original price</div>
                <div className={typography.label}>
                  {centsToDollar(data.listingPrice)}
                </div>
              </div>
            </>
          )}
          {showReceipt && (
            <>
              <ConciergeReceipt
                fee={pricingInfo.fee}
                price={receiptPrice}
                priceLabel={
                  isCustomPriceSelected ? 'Custom price' : 'Purchase price'
                }
                tax={pricingInfo.tax}
                total={pricingInfo.total}
              />
              {cancellationPolicy && (
                <CancellationPolicyNotice
                  availabilityTime={data.time}
                  cancellationFee={cancellationPolicy.fee}
                  date={data.date}
                  hoursBeforeReservation={
                    cancellationPolicy.hoursBeforeReservation
                  }
                  purchasePrice={
                    isCustomPriceSelected
                      ? customPriceInCents
                      : data.listingPrice
                  }
                  threshold={cancellationPolicy.threshold}
                />
              )}
              <CardElement onChange={handleCardElementOnChange} />
            </>
          )}
          <label className={styles.label}>
            <span className={cx(typography.c2, styles.labelText)}>
              First name
            </span>
            <div className={styles.inputErrorWrapper}>
              <input
                data-1p-ignore
                data-lpignore
                autoComplete="false"
                className={cx(typography.t1, styles.input)}
                name="firstName"
                onChange={onFirstNameChange}
                type="text"
              />
              {formErrors.firstName && (
                <p className={styles.error}>{formErrors.firstName}</p>
              )}
            </div>
          </label>
          <label className={styles.label}>
            <span className={cx(typography.c2, styles.labelText)}>
              Last name
            </span>
            <div className={styles.inputErrorWrapper}>
              <input
                data-1p-ignore
                data-lpignore
                autoComplete="false"
                className={cx(typography.t1, styles.input)}
                name="lastName"
                onChange={onLastNameChange}
                type="text"
              />
              {formErrors.lastName && (
                <p className={styles.error}>{formErrors.lastName}</p>
              )}
            </div>
          </label>
          <label
            className={cx(styles.label, styles.phoneNumberLabel)}
            htmlFor="phone"
          >
            <span className={cx(typography.c2, styles.labelText)}>
              Guest phone
            </span>
            <PhoneNumberInput
              className={styles.phoneNumberInput}
              error={formErrors.phone}
              name="phone"
              onChange={onPhoneNumberChange}
            />
          </label>
        </div>

        <div className={styles.formActions}>
          <Error className={styles.error} message={responseError} />
          <Button
            isDisabled={isBookButtonDisabled}
            label="Book Reservation"
            onClick={handleBookReservationClick}
            variant={ButtonVariants.Primary}
          />
          <Button
            label="Cancel"
            onClick={closeDrawer}
            variant={ButtonVariants.Tertiary}
          />
        </div>
      </div>
    </div>
  );
};
