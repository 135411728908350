// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UpcomingTimerSwitch__label___c7QcA{align-items:center;display:flex;column-gap:8px;justify-content:flex-end}.UpcomingTimerSwitch__switchTrack___RbvHI{background:var(--hairline);border-radius:80px;cursor:pointer;height:24px;position:relative;width:40px}.UpcomingTimerSwitch__switchTrack___RbvHI.UpcomingTimerSwitch__checked___brGUf{background:var(--gold)}.UpcomingTimerSwitch__switchTrack___RbvHI.UpcomingTimerSwitch__focused___qAi5s{box-shadow:0 0 0 1px var(--primary100)}.UpcomingTimerSwitch__switchThumb___wbFOU{background-color:var(--white);border-radius:80px;height:18px;left:4px;position:absolute;top:3px;transition-duration:120ms;transition-property:left;width:18px}.UpcomingTimerSwitch__switchThumb___wbFOU.UpcomingTimerSwitch__checked___brGUf{left:18px}.UpcomingTimerSwitch__switchInput___olZCo{cursor:inherit;opacity:0;z-index:1}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/floorPlan/UpcomingTimerSwitch.scss"],"names":[],"mappings":"AAEA,oCACE,kBAAA,CACA,YAAA,CACA,cAAA,CACA,wBAAA,CAGF,0CACE,0BAAA,CACA,kBAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CACA,UAAA,CAEA,+EACE,sBAAA,CAGF,+EACE,sCAAA,CAIJ,0CACE,6BAAA,CACA,kBAAA,CACA,WAAA,CACA,QAAA,CACA,iBAAA,CACA,OAAA,CACA,yBAAA,CACA,wBAAA,CACA,UAAA,CAEA,+EACE,SAAA,CAIJ,0CACE,cAAA,CACA,SAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "UpcomingTimerSwitch__label___c7QcA",
	"switchTrack": "UpcomingTimerSwitch__switchTrack___RbvHI",
	"checked": "UpcomingTimerSwitch__checked___brGUf",
	"focused": "UpcomingTimerSwitch__focused___qAi5s",
	"switchThumb": "UpcomingTimerSwitch__switchThumb___wbFOU",
	"switchInput": "UpcomingTimerSwitch__switchInput___olZCo"
};
export default ___CSS_LOADER_EXPORT___;
