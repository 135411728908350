import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { deleteRestaurantEvent } from './apiHelpers';

export interface DeleteRestaurantEventConfirmationModalProps {
  restaurantEventId: string;
  restaurantId: string;
  closeModal: () => void;
  refetchRestaurantEvents: () => void;
}

export const DeleteRestaurantEventConfirmationModal = ({
  closeModal,
  refetchRestaurantEvents,
  restaurantEventId,
  restaurantId,
}: DeleteRestaurantEventConfirmationModalProps) => {
  const handleDeleteRestaurantEvent = async (): Promise<void> => {
    closeModal();

    await deleteRestaurantEvent({
      restaurantEventId,
      restaurantId,
    });

    refetchRestaurantEvents();
  };

  return (
    <Modal
      ariaLabel="confirm delete modal"
      isOpen
      onClose={closeModal}
      subtitle="Are you sure you would like to delete this event?"
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Yes, delete event"
          onClick={handleDeleteRestaurantEvent}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
