import {
  CardElement as StripeCardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type {
  PaymentMethodResult,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js';
import type { E164Number } from 'libphonenumber-js';
import { debounce } from 'lodash-es';
import { useCallback, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { useNavigate } from 'react-router-dom';
import { successToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import type { PricingInfo } from '@shared/pricingInfoApi';
import { getPricingInfo } from '@shared/pricingInfoApi';
import { isCancellationPolicyApplicable } from '@shared/reservations/reservationUtil';
import { type CancellationPolicy } from '@shared/reservations/types';
import { CONFLICT } from '@shared/statusCodes';
import {
  getErrorResponseMessage,
  isSuccessResponse,
} from '@shared/types/apiHelpers';
import { wholeDollarsToCents } from '@utils/currency';
import { ISOTimeTo12HourTime } from '@utils/time';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import {
  type AdminRestaurant,
  getAdminRestaurant,
} from 'restaurantAdmin/settings/team/apiHelpers';
import { RESERVATIONS_SERVICE_PATH } from '../../paths';
import type { HostBookReservationPayload } from './apiHelpers';
import { hostBookReservation } from './apiHelpers';
import { useAvailabilityDrawer } from './state/availabilityDrawerContext';

const UPDATE_PRICING_INFO_DEBOUNCE_INTERVAL = 1000;

export enum CheckoutTypes {
  FullPrice = 'fullPrice',
  NoPrice = 'noPrice',
  CustomPrice = 'customPrice',
}

interface UseAdminCheckout {
  cancellationPolicy?: CancellationPolicy | null;
  customPriceInDollars: string;
  formErrors: {
    customPriceInDollars: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  handleBookReservationClick: () => Promise<void>;
  handleCardElementOnChange: ({
    complete,
  }: StripeCardElementChangeEvent) => void;
  handleCheckoutTypeOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;
  handleCustomPriceChange: (value: string) => void;
  isBookButtonDisabled: boolean;
  onFirstNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onLastNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPhoneNumberChange: (value?: E164Number) => void;
  pricingInfo?: PricingInfo;
  responseError: string;
  selectedCheckoutType: CheckoutTypes;
}

interface AdminCheckoutFormData {
  firstName: string;
  lastName: string;
  pricingInfo: PricingInfo;
  customPriceInDollars: string;
  phone: string;
}

const DEFAULT_PRICING_INFO: PricingInfo = {
  fee: 0,
  tax: 0,
  total: 0,
};

const FORM_FIELDS_INITIAL_STATE: AdminCheckoutFormData = {
  firstName: '',
  lastName: '',
  pricingInfo: DEFAULT_PRICING_INFO,
  customPriceInDollars: '',
  phone: '',
};

const useFetchAdminRestaurant = (restaurantId: string) => {
  const [adminRestaurant, setAdminRestaurant] =
    useState<AdminRestaurant | null>(null);

  const refetch = async () => {
    const response = await getAdminRestaurant(restaurantId);
    if (isSuccessResponse(response)) {
      setAdminRestaurant(response);
    }
  };

  useAbortEffect(
    async (signal) => {
      const response = await getAdminRestaurant(restaurantId, signal);
      if (isSuccessResponse(response)) {
        setAdminRestaurant(response);
      }
    },
    [restaurantId],
  );

  return { adminRestaurant, refetch };
};

export const useAdminCheckout = (): UseAdminCheckout => {
  const restaurant = useRestaurant();
  const { closeDrawer, data } = useAvailabilityDrawer();

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [formFields, setFormFields] = useState<AdminCheckoutFormData>(
    FORM_FIELDS_INITIAL_STATE,
  );
  const { firstName, lastName, pricingInfo, customPriceInDollars, phone } =
    formFields;
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    customPriceInDollars: '',
  });
  const [responseError, setResponseError] = useState('');
  const [selectedCheckoutType, setSelectedCheckoutType] = useState(
    CheckoutTypes.NoPrice,
  );
  const [isCardElementFormComplete, setIsCardElementFormComplete] =
    useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { adminRestaurant, refetch } = useFetchAdminRestaurant(restaurant.id);

  const NAME_MINIMUM_LENGTH = 1;
  const isValidName = (name: string) =>
    name.trim().length < NAME_MINIMUM_LENGTH;

  const fetchPricingInfoWithoutDebounce = async (priceInCents: number) => {
    const pricingInfoResponse = await getPricingInfo({
      price: priceInCents,
      zipCode: restaurant.postalCode,
    });
    if (isSuccessResponse(pricingInfoResponse)) {
      setFormFields((prevState) => ({
        ...prevState,
        pricingInfo: pricingInfoResponse,
      }));
    }
  };

  const fetchPricingInfo = useCallback(
    debounce(async (priceInCents: number) => {
      const pricingInfoResponse = await getPricingInfo({
        price: priceInCents,
        zipCode: restaurant.postalCode,
      });
      if (isSuccessResponse(pricingInfoResponse)) {
        setFormFields((prevState) => ({
          ...prevState,
          pricingInfo: pricingInfoResponse,
        }));
      }
    }, UPDATE_PRICING_INFO_DEBOUNCE_INTERVAL),
    [],
  );

  const handleCardElementOnChange = ({
    complete,
  }: StripeCardElementChangeEvent) => {
    setIsCardElementFormComplete(complete);
  };

  const clearStaleResponseError = () => {
    if (responseError) {
      setResponseError('');
    }
  };

  const onFirstNameChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    clearStaleResponseError();
    const { name, value } = target;
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: isValidName(value) ? 'Please enter your first name.' : '',
    }));
    setFormFields((prevState) => ({ ...prevState, firstName: value }));
  };

  const onLastNameChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    clearStaleResponseError();
    const { name, value } = target;
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: isValidName(value) ? 'Please enter your last name.' : '',
    }));
    setFormFields((prevState) => ({ ...prevState, lastName: value }));
  };

  const onPhoneNumberChange = (value?: E164Number) => {
    clearStaleResponseError();
    if (value) {
      setFormErrors((prevState) => ({
        ...prevState,
        phone: !isValidPhoneNumber(value)
          ? 'Please enter a valid phone number.'
          : '',
      }));
      setFormFields((prevState) => ({ ...prevState, phone: value }));
    }
  };

  const createPaymentMethod = async (): Promise<
    PaymentMethodResult | undefined
  > => {
    if (!stripe || !elements) {
      return undefined;
    }
    const stripeCardElement = elements.getElement(StripeCardElement);
    let stripeResponse;
    if (stripeCardElement) {
      stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: stripeCardElement,
      });

      if (stripeResponse.error?.message) {
        setResponseError(stripeResponse.error.message);
        return undefined;
      }
    }

    return stripeResponse;
  };

  const handleBookReservationClick = async () => {
    if (!data) return;

    setIsProcessing(true);

    let paymentMethodResponse;

    if (selectedCheckoutType !== CheckoutTypes.NoPrice) {
      paymentMethodResponse = await createPaymentMethod();
      if (!paymentMethodResponse) {
        setIsProcessing(false);
        return;
      }
    }

    const price =
      selectedCheckoutType === CheckoutTypes.CustomPrice
        ? wholeDollarsToCents(Number(customPriceInDollars))
        : data.listingPrice;

    const getExpectedCancellationPolicyId = () => {
      if (selectedCheckoutType === CheckoutTypes.NoPrice) return null;
      if (!adminRestaurant?.cancellationPolicy) return null;
      if (
        !isCancellationPolicyApplicable(
          price,
          adminRestaurant?.cancellationPolicy?.threshold,
        )
      )
        return null;
      return adminRestaurant.cancellationPolicy.id;
    };

    const hostBookReservationPayload: HostBookReservationPayload = {
      date: data.date,
      expectedCancellationPolicyId: getExpectedCancellationPolicyId(),
      firstName,
      guestCount: data.guestCount,
      lastName,
      listingId: data.listingId,
      listingPrice: data.listingPrice,
      paymentMethodId: paymentMethodResponse?.paymentMethod?.id,
      phone,
      restaurantId: restaurant.id,
      time: data.time,
    };

    if (selectedCheckoutType === CheckoutTypes.CustomPrice) {
      hostBookReservationPayload.customPrice = wholeDollarsToCents(
        Number(customPriceInDollars),
      );
    }

    const response = await hostBookReservation(hostBookReservationPayload);

    if (response.ok) {
      const formattedTime = ISOTimeTo12HourTime(data.time);
      closeDrawer();
      navigate(`${RESERVATIONS_SERVICE_PATH}?date=${data.date}`);
      successToast({
        message: `${firstName} ${lastName}'s ${formattedTime} reservation was booked.`,
      });
    } else {
      let errorMessage: string;
      if (response.status === CONFLICT) {
        refetch();
        errorMessage =
          "We've encountered an error. " +
          'Something may have changed. ' +
          'Please review the updated cancellation policy and confirm your purchase';
      } else {
        errorMessage = await getErrorResponseMessage(response);
      }
      setResponseError(errorMessage);
      setIsProcessing(false);
    }
  };

  const hasErrors = Object.values(formErrors).some((field) => !!field);

  const isAnyFieldEmpty =
    selectedCheckoutType === CheckoutTypes.CustomPrice
      ? !firstName || !lastName || !phone || !customPriceInDollars
      : !firstName || !lastName || !phone;

  const isInvalid =
    selectedCheckoutType !== CheckoutTypes.NoPrice
      ? !isCardElementFormComplete || isAnyFieldEmpty
      : isAnyFieldEmpty;

  const isBookButtonDisabled = hasErrors || isInvalid || isProcessing;

  const handleCheckoutTypeOnChange = async ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCheckoutType(value as CheckoutTypes);

    if (data && value === CheckoutTypes.FullPrice) {
      // in case we switch back to custom price, clear this value
      setFormFields((prevState) => ({
        ...prevState,
        customPriceInDollars: '',
        pricingInfo: DEFAULT_PRICING_INFO,
      }));
      await fetchPricingInfoWithoutDebounce(data.listingPrice);
    } else if (value === CheckoutTypes.NoPrice) {
      setFormFields((prevState) => ({
        ...prevState,
        customPriceInDollars: '',
        pricingInfo: DEFAULT_PRICING_INFO,
      }));
    } else {
      // in case we're switching from full price to custom, clear any pricing info
      setFormFields((prevState) => ({
        ...prevState,
        pricingInfo: DEFAULT_PRICING_INFO,
      }));
    }
  };

  const handleCustomPriceChange = async (value: string) => {
    setFormFields((prevState) => ({
      ...prevState,
      customPriceInDollars: value,
    }));
    if (value) {
      await fetchPricingInfo(wholeDollarsToCents(Number(value)));
    }
  };

  return {
    cancellationPolicy: adminRestaurant?.cancellationPolicy,
    customPriceInDollars,
    formErrors,
    handleBookReservationClick,
    handleCardElementOnChange,
    handleCheckoutTypeOnChange,
    handleCustomPriceChange,
    isBookButtonDisabled,
    onFirstNameChange,
    onLastNameChange,
    onPhoneNumberChange,
    pricingInfo,
    responseError,
    selectedCheckoutType,
  };
};
