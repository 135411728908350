import { formatPhone } from '@utils/formatPhone';
import { ISOTimeTo12HourTime } from '@utils/time';
import type { ReservationAttributes } from '../apiHelpers';
import styles from './DailyReservationsReportPage.scss';

export const ReservationReportItem = ({
  guest,
  guestCount,
  time,
  isStranded,
  hostBookedBy,
  guestTags,
  guestNotes,
}: ReservationAttributes) => (
  <div className={styles.reservation}>
    <span>
      <strong>{ISOTimeTo12HourTime(time)}</strong>
    </span>
    <span>
      <strong>{`${guest.firstName} ${guest.lastName}`}</strong>
    </span>
    <span>{isStranded && 'STRANDED'}</span>
    <span>{`${guestCount} Guest(s)`}</span>
    <span className={styles.fillRow}>{formatPhone(guest.phone)}</span>
    {!!guestTags.length && (
      <>
        <span>Tags: </span>
        <span className={styles.fillRow}>{guestTags.join(', ')}</span>
      </>
    )}
    {guestNotes && (
      <>
        <span>Notes: </span>
        <span className={styles.fillRow}>{guestNotes}</span>
      </>
    )}
    {hostBookedBy && (
      <>
        <span>Booked By: </span>
        <span className={styles.fillRow}>{hostBookedBy}</span>
      </>
    )}
  </div>
);
