import { isReservation, type OccupantsReservation } from '../apiHelpers';
import { useOccupantContext } from '../state/occupantContext';
import { GuestMetrics } from './GuestMetrics';
import styles from './OccupantMetrics.scss';
import { ReservationsMetrics } from './ReservationsMetrics';

export const OccupantMetrics = () => {
  const { occupants } = useOccupantContext();

  const uncanceledReservations = occupants.filter(
    (occupant) =>
      isReservation(occupant) && !occupant.status.includes('canceled'),
  ) as OccupantsReservation[];

  const uncanceledOccupants = occupants.filter(
    (occupant) => !occupant.status.includes('canceled'),
  );

  return (
    <section className={styles.container} aria-label="occupant metrics">
      <ReservationsMetrics reservations={uncanceledReservations} />
      <GuestMetrics occupants={uncanceledOccupants} />
    </section>
  );
};
