// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestNotesShow__showForm___Rfbbu{background-color:var(--panelBlue);border-radius:8px;padding:1rem}.GuestNotesShow__showForm___Rfbbu h3{display:inline-block;margin:0 0 16px}.GuestNotesShow__showForm___Rfbbu button{align-items:center;background:none;border:none;color:var(--white);cursor:pointer;display:flex;float:right}.GuestNotesShow__showForm___Rfbbu button svg{height:16px;margin-right:4px;stroke:var(--white);width:16px}.GuestNotesShow__showForm___Rfbbu p{align-items:center;color:var(--white70);display:flex;flex-wrap:wrap;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/guestNotes/GuestNotesShow/GuestNotesShow.scss"],"names":[],"mappings":"AAAA,kCACE,iCAAA,CACA,iBAAA,CACA,YAAA,CAEA,qCACE,oBAAA,CACA,eAAA,CAGF,yCACE,kBAAA,CACA,eAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CACA,YAAA,CACA,WAAA,CAEA,6CACE,WAAA,CACA,gBAAA,CACA,mBAAA,CACA,UAAA,CAIJ,oCACE,kBAAA,CACA,oBAAA,CACA,YAAA,CACA,cAAA,CACA,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showForm": "GuestNotesShow__showForm___Rfbbu"
};
export default ___CSS_LOADER_EXPORT___;
