// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServiceStatusIcon__main___FlDVM{align-items:center;border-radius:50%;display:flex;height:2.5rem;justify-content:center;width:2.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/serviceStatus/ServiceStatusIcon.scss"],"names":[],"mappings":"AAAA,iCACE,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,aAAA,CACA,sBAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "ServiceStatusIcon__main___FlDVM"
};
export default ___CSS_LOADER_EXPORT___;
