import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { useAuth } from 'restaurantAdmin/context/authContext';

interface SignOutModalProps {
  closeModal: () => void;
}

export const SignOutModal = ({ closeModal }: SignOutModalProps) => {
  const { logout } = useAuth();

  const handleSignOutOnClick = () => {
    logout(() => {
      closeModal();
    });
  };

  return (
    <Modal
      ariaLabel="Sign out"
      isOpen
      onClose={closeModal}
      subtitle="Are you sure you wish to sign out?"
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Sign Out"
          onClick={handleSignOutOnClick}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
