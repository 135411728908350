import layoutStyles from '../../../layout/Layout.scss';
import { EditListingDetailsPageBody } from '../edit/EditListingDetailsPageBody';
import { useListingsContext } from '../ListingsContext';
import { useCreateListingContext } from './CreateListingContext';
import { CreateListingDetailsPageHeader } from './CreateListingDetailsPageHeader';

export const CreateListingDetailsPage = () => {
  const { floorPlan, isLoading } = useListingsContext();
  const { detailsControl: formControl, handleOnClickAddTimeAndPrice } =
    useCreateListingContext();

  if (isLoading) return null;

  return (
    <form
      className={layoutStyles.pageWrapper}
      onSubmit={handleOnClickAddTimeAndPrice}
      noValidate
    >
      <CreateListingDetailsPageHeader />
      <EditListingDetailsPageBody
        control={formControl}
        isEditing={false}
        floorPlan={floorPlan}
      />
    </form>
  );
};
