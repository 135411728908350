import type { FeatureConfig } from '@shared/context/featureFlagContext';
import type { Environment } from '@shared/environments';

export type RestaurantAdminFeatureFlag =
  | 'serviceStatusFlag'
  | 'testFlag'
  | 'transactionLogFlag';

export const featureFlagConfig: Readonly<
  Record<Environment, FeatureConfig<RestaurantAdminFeatureFlag>>
> = Object.freeze({
  development: {
    serviceStatusFlag: true,
    testFlag: true,
    transactionLogFlag: true,
  },
  ci: {
    serviceStatusFlag: true,
    testFlag: true,
    transactionLogFlag: true,
  },
  staging: {
    serviceStatusFlag: true,
    testFlag: true,
    transactionLogFlag: true,
  },
  demo: {
    serviceStatusFlag: false,
    testFlag: false,
    transactionLogFlag: false,
  },
  production: {
    serviceStatusFlag: false,
    testFlag: false,
    transactionLogFlag: false,
  },
});
