// to be removed when serviceStatusFlag is removed
import cx from 'classnames';
import { forwardRef } from 'react';
import type { TableIconCommonProps } from '@components/floorPlan/InteractiveFloorPlanTable';
import {
  calculateContainerStyle,
  calculateIconStyle,
} from '@components/floorPlan/utils';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import { getInitials } from '@utils/formatName';
import typography from '~styles/typography.scss';
import type { SeatedGuest } from '../../../floorPlans/apiHelpers';
import styles from './SeatedGuestFloorPlanTable.scss';

export interface SeatedGuestFloorPlanTableProps extends TableIconCommonProps {
  handleTableOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled: boolean;
  isHighlighted: boolean;
  seatedGuest: SeatedGuest;
  tableName: string;
}

export const SeatedGuestFloorPlanTable = forwardRef<
  HTMLDivElement,
  SeatedGuestFloorPlanTableProps
>(
  (
    {
      handleTableOnClick,
      iconName,
      iconWidthScale,
      isDisabled,
      isHighlighted,
      left,
      orientation,
      seatedGuest,
      tableIconScale,
      tableName,
      testId,
      top,
    },
    ref,
  ) => {
    const iconSuffix = () => {
      if (isHighlighted) return 'Selected';
      return '';
    };
    const newIconName = `${iconName}${iconSuffix()}` as AnyIcon;

    return (
      <div
        className={styles.container}
        data-testid={testId}
        ref={ref}
        style={calculateContainerStyle({
          left,
          tableIconScale,
          top,
        })}
        title={`${seatedGuest.guest.firstName} ${seatedGuest.guest.lastName} seated at table ${tableName}, party of ${seatedGuest.reservation.guestCount}`}
      >
        <button
          className={styles.button}
          disabled={isDisabled}
          onClick={handleTableOnClick}
          type="button"
        >
          <div
            className={cx(
              iconName === 'barSeat' && styles.barSeat,
              styles.guestSeat,
            )}
          >
            <p className={cx(typography.t3, styles.guestSeatText)}>
              {getInitials(
                seatedGuest.guest.firstName,
                seatedGuest.guest.lastName,
              )}
            </p>
            <p className={cx(typography.t3, styles.guestSeatText)}>
              {seatedGuest.reservation.guestCount}
            </p>
          </div>
          <Icon
            name={newIconName}
            style={calculateIconStyle({
              iconWidthScale,
              orientation,
              tableIconScale,
            })}
            testId={`icon-${newIconName}`}
          />
        </button>
      </div>
    );
  },
);

SeatedGuestFloorPlanTable.displayName = 'SeatedGuestFloorPlanTable';
