import { createResponseError } from '@shared/api/createResponseError';
import { type ApiResponse, isErrorResponse } from '@shared/types/apiHelpers';
import API from '../../api/apiClient';
import { type ReservationStatus, type WalkInStatus } from '../apiHelpers';
import { type ServiceStatus } from './serviceStatus/ServiceStatus';

export interface TransactionDetails {
  createdBy: { fullName?: string };
  fee: number;
  price: number;
  receiptNumber: string;
  refundAmount: number | null;
  tax: number;
}

export interface ReservationsApiGuest {
  email: string;
  firstName: string;
  id: string;
  isDeleted: boolean;
  lastName: string;
  phone: string;
}

export type AvailableWalkInTime = number | null | 'NA';

export interface ServiceWalkIn {
  type: 'walkIn';
  hasUpcomingReservation: boolean;
  date: string;
  firstName?: string;
  guestCount: number;
  id: string;
  lastName?: string;
  seatedTableId: string;
  seatedTableName: string;
  serviceStatus: ServiceStatus;
  status: WalkInStatus;
  time: string;
}

export interface ServiceReservation {
  type: 'reservation';
  date: string;
  guest: ReservationsApiGuest;
  guestCount: number;
  hasDietaryRestrictionOrAllergy: boolean;
  hasNote: boolean;
  id: string;
  isStranded: boolean;
  isVIP: boolean;
  listingId: string;
  seatedTableName: string | null;
  serviceStatus: ServiceStatus;
  status: ReservationStatus;
  time: string;
  transactionDetails: TransactionDetails;
}

export type ServiceOccupant = ServiceReservation | ServiceWalkIn;

export const isReservation = (
  occupant: ServiceOccupant | undefined,
): occupant is ServiceReservation => occupant?.type === 'reservation';

export const isWalkIn = (
  occupant: ServiceOccupant | undefined,
): occupant is ServiceWalkIn => occupant?.type === 'walkIn';

export const getInfiniteOccupants = async (
  restaurantId: string,
  limit: number,
  offset: number,
): Promise<ApiResponse<ServiceOccupant[]>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/service/occupants?limit=${limit}&offset=${offset}`,
  );

  return response.json();
};

export const getServiceReservationDetails = async (
  restaurantId: string,
  reservationId: string,
): Promise<ApiResponse<ServiceReservation>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/service/reservations/${reservationId}`,
  );

  return response.json();
};

export const getServiceWalkInDetails = async (
  restaurantId: string,
  walkInId: string,
): Promise<ApiResponse<ServiceWalkIn>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/service/walk-ins/${walkInId}`,
  );

  return response.json();
};

export const createReservationServiceStatus = async (
  restaurantId: string,
  reservationId: string,
  serviceStatus: ServiceStatus,
): Promise<void> => {
  const response = await API.post(
    `/restaurants/${restaurantId}/service/reservations/${reservationId}/service-statuses`,
    { serviceStatus },
  );
  if (isErrorResponse(response)) throw createResponseError(response);
};

export const createWalkInServiceStatus = async (
  restaurantId: string,
  walkInId: string,
  serviceStatus: ServiceStatus,
): Promise<void> => {
  const response = await API.post(
    `/restaurants/${restaurantId}/service/walk-ins/${walkInId}/service-statuses`,
    { serviceStatus },
  );
  if (isErrorResponse(response)) throw createResponseError(response);
};
