import { formatInTimeZone } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { Icon } from '@components/icon/Icon';
import { createResponseError } from '@shared/api/createResponseError';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { toUTCDateTime, toWeekdayShortMonthDateAndYear } from '@utils/date';
import { appendGuestCountLabel } from '@utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../context/useRestaurant';
import { useError } from '../errors/useError';
import {
  getTransactionLogForReservation,
  type GuestReservation,
  type ReservationEvent,
} from './apiHelpers';
import styles from './TransactionLog.scss';

interface TransactionLogProps {
  reservation: GuestReservation;
}

const reservationEventTypeDisplayName = {
  initialBooking: 'Initial booking',
};

export const TransactionLog = ({
  reservation: {
    date,
    guestCount,
    id: reservationId,
    time,
    transactionDetails,
  },
}: TransactionLogProps) => {
  const setError = useError();
  const restaurant = useRestaurant();
  const { timezone } = restaurant;
  const [transactionLog, setTransactionLog] = useState<ReservationEvent[]>([]);

  useEffect(() => {
    const fetchTransactionLog = (): void => {
      void (async () => {
        try {
          const response = await getTransactionLogForReservation(
            restaurant.id,
            reservationId,
          );
          if (isErrorResponse(response)) {
            throw createResponseError(response);
          } else {
            setTransactionLog(response);
          }
        } catch (e) {
          setError(e);
        }
      })();
    };
    fetchTransactionLog();
  }, []);

  return (
    <section aria-labelledby="logHeading" className={styles.container}>
      <header>
        <Icon name="list" />
        <h3 className={typography.h7} id="logHeading">
          Transaction Log
        </h3>
      </header>
      {transactionLog.length ? (
        <>
          <p className={typography.t1}>
            All dates and times are local to the restaurant.
          </p>
          <dl>
            {transactionLog.map((reservationEvent) => {
              const createdAtDisplay = formatInTimeZone(
                new Date(reservationEvent.createdAt),
                timezone,
                'yyyy-MM-dd hh:mm a',
              );
              const createdBy = transactionDetails.createdBy.fullName;

              return (
                <div key={reservationEvent.id}>
                  <dt className={typography.h8}>{createdAtDisplay}</dt>
                  <dd className={typography.t2}>
                    {reservationEventTypeDisplayName[reservationEvent.type]}
                    {createdBy ? ` by ${createdBy} ` : ' '}
                    {`for ${toWeekdayShortMonthDateAndYear(toUTCDateTime(date, time, timezone))}, `}
                    {`${ISOTimeTo12HourTime(time)}, `}
                    {appendGuestCountLabel(guestCount)}
                  </dd>
                </div>
              );
            })}
          </dl>
        </>
      ) : (
        <p className={typography.t1}>
          No events have been recorded for this reservation.
        </p>
      )}
    </section>
  );
};
