import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import type { CSSProperties } from 'react';
import type { TableIconCommonProps } from '@components/floorPlan/InteractiveFloorPlanTable';
import {
  calculateContainerStyle,
  calculateIconStyle,
  DEFAULT_ICON_WIDTH,
} from '@components/floorPlan/utils';
import { type AnyIcon, Icon } from '@components/icon/Icon';
import styles from './DraggableFloorPlanTable.scss';
import { useFloorPlanEditorContext } from './floorPlanEditorContext';

export interface DraggableFloorPlanTableProps extends TableIconCommonProps {
  id: string;
  isHighlighted: boolean;
}

export const DraggableFloorPlanTable = ({
  id,
  isHighlighted,
  iconName,
  iconWidthScale,
  left,
  name,
  orientation,
  tableIconScale,
  top,
}: DraggableFloorPlanTableProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    data: {
      iconName,
      iconWidthScale,
      id,
      left,
      name,
      orientation,
      top,
    },
    id,
  });
  const { setSelectedFloorPlanTableId } = useFloorPlanEditorContext();

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  const handleOnClick = () => {
    setSelectedFloorPlanTableId(id);
  };

  const iconSuffix = isHighlighted ? 'Selected' : '';
  const newIconName = `${iconName}${iconSuffix}` as AnyIcon;

  const calculateNewTableStyle = (): CSSProperties => ({
    left: Number(left) * tableIconScale,
    top: Number(top) * tableIconScale,
    height: DEFAULT_ICON_WIDTH * tableIconScale * Number(iconWidthScale),
    width: DEFAULT_ICON_WIDTH * tableIconScale * Number(iconWidthScale),
  });

  const newTableStyle =
    name === 'New Table'
      ? { ...calculateNewTableStyle() }
      : { display: 'none' };

  return (
    <div
      className={styles.container}
      style={style}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <button
        className={styles.container}
        aria-label={`table-${name}`}
        onClick={handleOnClick}
        type="button"
      >
        <Icon
          className={styles.container}
          name={newIconName}
          style={{
            ...calculateContainerStyle({
              left,
              tableIconScale,
              top,
              ...style,
            }),
            ...calculateIconStyle({
              iconWidthScale,
              orientation,
              tableIconScale,
            }),
          }}
          testId={`icon-${iconName}`}
        />
        <span className={styles.newTable} style={newTableStyle} />
      </button>
    </div>
  );
};
