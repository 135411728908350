import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { KebabOption } from '@components/kebab/Kebab';
import { Kebab, KebabOptionVariants } from '@components/kebab/Kebab';
import {
  OPERATIONS_LISTING_EDIT_PATH,
  OPERATIONS_LISTINGS_CREATE_DETAILS_PATH,
  OPERATIONS_LISTINGS_EDIT_DETAILS_TERMINAL_PATH,
  OPERATIONS_LISTINGS_EDIT_TIME_AND_PRICE_TERMINAL_PATH,
} from 'restaurantAdmin/paths';
import type { Listing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import type { ListingLocationState } from '../utils/listingUtils';
import { DeleteListingConfirmationModal } from './DeleteListingConfirmationModal';
import styles from './ListingKebab.scss';
import { PublishListingConfirmationModal } from './PublishListingConfirmationModal';
import { UnpublishListingConfirmationModal } from './UnpublishListingConfirmationModal';

interface ListingKebabProps {
  listing: Listing;
}

export const ListingKebab = ({ listing }: ListingKebabProps) => {
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [isConfirmPublishModalOpen, setIsConfirmPublishModalOpen] =
    useState(false);
  const [isConfirmUnpublishModalOpen, setIsConfirmUnpublishModalOpen] =
    useState(false);

  const navigate = useNavigate();
  const { isOnFloorPlan } = useListingsContext();

  const options: KebabOption[] = [
    ...(listing.status === 'draft'
      ? [
          {
            label: 'Publish Listing',
            onClick: () => setIsConfirmPublishModalOpen(true),
          },
        ]
      : []),
    ...(listing.status === 'active'
      ? [
          {
            label: 'Unpublish Listing',
            onClick: () => setIsConfirmUnpublishModalOpen(true),
          },
        ]
      : []),
    {
      label: 'Duplicate Listing',
      onClick: () =>
        navigate(OPERATIONS_LISTINGS_CREATE_DETAILS_PATH, {
          state: {
            duplicateListingId: listing.id,
            isFromFloorPlan: isOnFloorPlan,
          } as ListingLocationState,
        }),
    },
    {
      label: 'Edit Listing Details',
      onClick: () =>
        navigate(
          `${OPERATIONS_LISTING_EDIT_PATH}/${listing.id}/${OPERATIONS_LISTINGS_EDIT_DETAILS_TERMINAL_PATH}`,
          {
            state: { isFromFloorPlan: isOnFloorPlan } as ListingLocationState,
          },
        ),
    },
    {
      label: 'Edit Time and Price',
      onClick: () =>
        navigate(
          `${OPERATIONS_LISTING_EDIT_PATH}/${listing.id}/${OPERATIONS_LISTINGS_EDIT_TIME_AND_PRICE_TERMINAL_PATH}`,
          {
            state: { isFromFloorPlan: isOnFloorPlan } as ListingLocationState,
          },
        ),
    },
    {
      label: 'Delete Listing',
      onClick: () => setIsConfirmDeleteModalOpen(true),
      variant: KebabOptionVariants.Pink,
    },
  ];

  return (
    <>
      <Kebab className={styles.kebab} options={options} />
      <DeleteListingConfirmationModal
        closeModal={() => setIsConfirmDeleteModalOpen(false)}
        isOpen={isConfirmDeleteModalOpen}
        listing={listing}
      />
      <PublishListingConfirmationModal
        closeModal={() => setIsConfirmPublishModalOpen(false)}
        isOpen={isConfirmPublishModalOpen}
        listing={listing}
      />
      <UnpublishListingConfirmationModal
        closeModal={() => setIsConfirmUnpublishModalOpen(false)}
        isOpen={isConfirmUnpublishModalOpen}
        listing={listing}
      />
    </>
  );
};
