import { isSameDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import type { OccupantState } from './occupantReducer';

const ZERO_INDEX_OFFSET = 1;

export const selectedOccupant = (state: OccupantState) =>
  state.occupants[state.selectedIndex];

export const occupantPosition = (state: OccupantState) =>
  `Reservation ${state.selectedIndex + ZERO_INDEX_OFFSET} of ${
    state.occupants.length
  }`;

export const isSelectedReservationDateToday =
  (state: OccupantState) => (timezone: string) =>
    isSameDay(state.reservationDate, toZonedTime(new Date(), timezone));
