import cx from 'classnames';
import type { FormEvent } from 'react';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Card } from '@components/card/Card';
import type { ErrorResponse } from '@shared/types/apiHelpers';
import { isValid } from '@utils/passwordValidator';
import typography from '~styles/typography.scss';
import { ROOT_PATH } from '../paths';
import { updateAdminPassword } from './apiHelpers';
import styles from './styles.scss';

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [response, setResponse] = useState<Response | ErrorResponse>();

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!token) return;

    const updateAdminPasswordResponse = await updateAdminPassword(
      password,
      token,
    );
    setResponse(updateAdminPasswordResponse);
  };

  const arePasswordsFilledIn = password && confirmPassword;

  const isPasswordResetValid =
    arePasswordsFilledIn && password === confirmPassword && isValid(password);

  const passwordResetSuccess = response && 'ok' in response;
  const passwordResetError = response && 'error' in response;

  return (
    <main className={styles.pageContainer}>
      <Card className={styles.card}>
        <form className={styles.formContainer} onSubmit={handleOnSubmit}>
          <h1 className={cx(typography.h4, styles.formHeader)}>
            Reset Password
          </h1>
          <label
            htmlFor="password"
            className={cx(typography.label, styles.formLabel)}
          >
            Password
            <input
              className={cx(styles.input, typography.body)}
              id="password"
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              type="password"
            />
          </label>
          <label
            className={cx(typography.label, styles.formLabel)}
            htmlFor="confirmPassword"
          >
            Confirm Password
            <input
              className={cx(styles.input, typography.body)}
              id="confirmPassword"
              name="confirmPassword"
              onChange={(event) => setConfirmPassword(event.target.value)}
              type="password"
            />
          </label>
          <button
            className={cx(styles.submitButton, typography.body)}
            disabled={!isPasswordResetValid || passwordResetSuccess}
            type="submit"
          >
            Submit
          </button>
          {arePasswordsFilledIn && !isPasswordResetValid && (
            <p className={cx(typography.body, styles.invalidPasswordsMessage)}>
              Password and confirmation must match, and be at least 8 characters
              and include a number and a special character.
            </p>
          )}
          {passwordResetSuccess && (
            <p className={cx(typography.body, styles.successResponseMessage)}>
              Your password was successfully reset, please
              <Link className={styles.loginLink} to={ROOT_PATH}>
                Login
              </Link>
              again.
            </p>
          )}
          {passwordResetError && (
            <p className={cx(typography.body, styles.errorResponseMessage)}>
              {response.message}
            </p>
          )}
        </form>
      </Card>
    </main>
  );
};
