// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhoneNumberInput__inputGroup___Cuebd{margin:1rem 0}.PhoneNumberInput__inputGroupLabel___BXJJz{display:block;margin-bottom:.625rem}.PhoneNumberInput__inputContainer___xsj2O{align-items:center;background-color:var(--primary300);border-radius:.25rem;border:2px solid var(--darkGrey300);display:flex;padding:0 1rem}.PhoneNumberInput__input___GILte{width:100%}.PhoneNumberInput__input___GILte input{background-color:var(--primary300);border:none;color:var(--white);font-size:1rem;margin-left:.5rem;padding:1rem 0;width:100%}.PhoneNumberInput__input___GILte:-webkit-autofill,.PhoneNumberInput__input___GILte:-webkit-autofill:hover,.PhoneNumberInput__input___GILte:-webkit-autofill:focus{-webkit-text-fill-color:var(--white);-webkit-box-shadow:0 0 0 100px var(--primary300) inset;box-shadow:0 0 0 100px var(--primary300) inset}.PhoneNumberInput__inputError___Oll5c{border:2px solid var(--pink)}.PhoneNumberInput__error___Tdp6n{color:var(--pink);font-size:.75rem !important;margin:0 !important;text-align:center}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/concierge/PhoneNumberInput.scss"],"names":[],"mappings":"AAAA,sCACE,aAAA,CAGF,2CACE,aAAA,CACA,qBAAA,CAGF,0CACE,kBAAA,CACA,kCAAA,CACA,oBAAA,CACA,mCAAA,CACA,YAAA,CACA,cAAA,CAGF,iCACE,UAAA,CAEA,uCACE,kCAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,cAAA,CACA,UAAA,CAIJ,kKAGE,oCAAA,CACA,sDAAA,CACA,8CAAA,CAGF,sCACE,4BAAA,CAGF,iCACE,iBAAA,CACA,2BAAA,CACA,mBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputGroup": "PhoneNumberInput__inputGroup___Cuebd",
	"inputGroupLabel": "PhoneNumberInput__inputGroupLabel___BXJJz",
	"inputContainer": "PhoneNumberInput__inputContainer___xsj2O",
	"input": "PhoneNumberInput__input___GILte",
	"inputError": "PhoneNumberInput__inputError___Oll5c",
	"error": "PhoneNumberInput__error___Tdp6n"
};
export default ___CSS_LOADER_EXPORT___;
