import cx from 'classnames';
import ArrowLeft from '@assets/icons/arrow-left.svg';
import CloseIcon from '@assets/icons/close.svg';
import { Card } from '@components/card/Card';
import typography from '~styles/typography.scss';
import styles from './OccupantSheetWrapper.scss';

interface OccupantSheetProps {
  children: React.ReactNode;
  occupantPosition: string;
  onClickNextOccupant: () => void;
  onClickPreviousOccupant: () => void;
  onClose: () => void;
}

export const OccupantSheetWrapper = ({
  children,
  occupantPosition,
  onClickNextOccupant,
  onClickPreviousOccupant,
  onClose,
}: OccupantSheetProps) => (
  <Card bordered aria-label="Occupant Sheet" className={styles.container}>
    <div className={styles.toolBar}>
      <div>
        <button
          aria-label="previous reservation"
          className={styles.button}
          onClick={onClickPreviousOccupant}
        >
          <ArrowLeft className={styles.icon} />
        </button>
        <span className={cx(styles.occupantPosition, typography.h7)}>
          {occupantPosition}
        </span>
        <button
          aria-label="next reservation"
          className={styles.button}
          onClick={onClickNextOccupant}
        >
          <ArrowLeft className={cx(styles.icon, styles.arrowRightIcon)} />
        </button>
      </div>
      <button
        aria-label="close sheet"
        className={styles.button}
        onClick={onClose}
      >
        <CloseIcon className={styles.icon} />
      </button>
    </div>
    {children}
  </Card>
);
