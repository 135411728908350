import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { PageHeader } from '../../../layout/PageHeader';
import {
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
  OPERATIONS_LISTINGS_CREATE_DETAILS_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
} from '../../../paths';
import { useListingsContext } from '../ListingsContext';
import type { ListingLocationState } from '../utils/listingUtils';
import styles from './ListingsPageHeader.scss';

export const ListingsPageHeader = () => {
  const { clearSelectedListing, isOnDraft, isOnFloorPlan } =
    useListingsContext();

  const tabLinkClassNames = ({ isActive }: { isActive: boolean }) =>
    cx({
      [styles.tabLink]: true,
      [styles.isActive]: isActive,
    });

  return (
    <PageHeader category="Operations" title="Listings">
      <nav>
        <NavLink
          className={tabLinkClassNames}
          onClick={clearSelectedListing}
          to={
            isOnDraft
              ? OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH
              : OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH
          }
        >
          Calendar
        </NavLink>
        <NavLink
          className={tabLinkClassNames}
          onClick={clearSelectedListing}
          to={
            isOnDraft
              ? OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH
              : OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH
          }
        >
          Floor Plan
        </NavLink>
      </nav>
      <LinkStyledAsButton
        variant={ButtonVariants.Secondary}
        to={OPERATIONS_LISTINGS_CREATE_DETAILS_PATH}
        state={
          {
            isFromDraft: isOnDraft,
            isFromFloorPlan: isOnFloorPlan,
          } as ListingLocationState
        }
        label="Create Listing"
      />
    </PageHeader>
  );
};
