import { type Control } from 'react-hook-form';
import { Card } from '@components/card/Card';
import typography from '~styles/typography.scss';
import { PageContent } from '../../../layout/PageContent';
import { EditListingAdminCalendar } from '../calendar/EditListingAdminCalendar';
import { ListingLifeSpanFieldSet } from '../form/ListingLifeSpanFieldSet';
import { PriceFieldSet } from '../form/PriceFieldSet';
import { ReservableDaysFieldSet } from '../form/ReservableDaysFieldSet';
import { ReservableTimesFieldSet } from '../form/ReservableTimesFieldSet';
import type {
  ListingDetailsFormData,
  ListingTimeAndPriceFormData,
} from '../types';
import styles from './EditListingTimeAndPricePageBody.scss';

interface EditListingTimeAndPricePageBodyProps {
  detailsControl: Control<ListingDetailsFormData>;
  listingName: string;
  timeAndPriceControl: Control<ListingTimeAndPriceFormData>;
}

export const EditListingTimeAndPricePageBody = ({
  detailsControl,
  listingName,
  timeAndPriceControl,
}: EditListingTimeAndPricePageBodyProps) => (
  <PageContent className={styles.main}>
    <Card className={styles.displayPanel}>
      <EditListingAdminCalendar
        className={styles.calendar}
        detailsControl={detailsControl}
        timeAndPriceControl={timeAndPriceControl}
      />
    </Card>
    <div className={styles.sidePanelContainer}>
      <Card bordered className={styles.sidePanel}>
        <h2 className={typography.h7}>Reservable Time and Price</h2>
        <h3 className={typography.h4}>{listingName}</h3>
        <ListingLifeSpanFieldSet control={timeAndPriceControl} />
        <ReservableDaysFieldSet control={timeAndPriceControl} />
        <ReservableTimesFieldSet control={timeAndPriceControl} />
        <PriceFieldSet control={timeAndPriceControl} />
      </Card>
    </div>
  </PageContent>
);
