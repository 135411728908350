import cx from 'classnames';
import { groupBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toISODateFormat, toWeekdayMonthDateAndYear } from '@utils/date';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import type { RestaurantReservationsReportResponse } from '../apiHelpers';
import { getRestaurantsReservationsReport } from './apiHelpers';
import styles from './DailyReservationsReportPage.scss';
import { ReservationReportItem } from './ReservationReportItem';

export const DailyReservationsReportPage = () => {
  const [searchParams] = useSearchParams();
  const { id: restaurantId } = useRestaurant();
  const [report, setReport] = useState<RestaurantReservationsReportResponse>();

  const date = searchParams.get('date') || toISODateFormat(new Date());

  useEffect(() => {
    const fetchRestaurantReservationsReport = async () => {
      const response = await getRestaurantsReservationsReport(
        restaurantId,
        date,
      );
      setReport(response);
      window.print();
    };

    fetchRestaurantReservationsReport();
  }, []);

  const reservationsGroupedByTime = groupBy(report?.reservations, 'time');

  return (
    <div className={styles.container}>
      <h1 className={cx(styles.heading, typography.d2)}>{report?.name}</h1>
      <h2 className={cx(styles.heading, typography.h2)}>
        {toWeekdayMonthDateAndYear(new Date(`${date}T12:00:00`))}
      </h2>
      <h3
        className={cx(styles.heading, typography.h3)}
      >{`${report?.reservations.length} Reservation(s)`}</h3>
      <ul className={styles.reservationsList}>
        {Object.keys(reservationsGroupedByTime).map((time) => {
          const timeGroupId = `time-group-${time}`;

          return (
            <li aria-labelledby={timeGroupId} key={`time-divider-${time}`}>
              <h4
                className={cx(styles.timeDivider, typography.h4)}
                id={timeGroupId}
              >
                {ISOTimeTo12HourTime(time)}
              </h4>
              <ul
                aria-label={`reservations starting at ${ISOTimeTo12HourTime(time)}`}
              >
                {reservationsGroupedByTime[time].map((reservation) => (
                  <ReservationReportItem
                    key={JSON.stringify(reservation)}
                    {...reservation}
                  />
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
