import { Option } from '@mui/base/Option';
import { Select } from '@mui/base/Select';
import { Button, ButtonVariants } from '@components/button/Button';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import { ICON_NAMES } from '@shared/types/floorPlans';
import styles from './FloorPlanEditor.scss';
import { useFloorPlanEditorContext } from './floorPlanEditorContext';

export const FloorPlanEditorActions = () => {
  const {
    handleOnAdd,
    handleOnDelete,
    handleOnReset,
    handleOnSave,
    selectedFloorPlanTable,
  } = useFloorPlanEditorContext();

  return (
    <div className={styles.actions}>
      <Button
        isDisabled={!selectedFloorPlanTable}
        label="Delete"
        onClick={handleOnDelete}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        className={styles.button}
        label="Reset"
        onClick={handleOnReset}
        variant={ButtonVariants.Tertiary}
      />
      <Select
        aria-label="add"
        className={styles.add}
        onChange={handleOnAdd}
        placeholder="Add"
        slotProps={{
          listbox: {
            style: {
              maxHeight: '80vh',
              overflowY: 'scroll',
            },
          },
        }}
        value={null}
      >
        {ICON_NAMES.map((iconName) => (
          <Option className={styles.option} key={iconName} value={iconName}>
            <Icon name={iconName as AnyIcon} /> {iconName}
          </Option>
        ))}
      </Select>
      <Button
        className={styles.button}
        label="Save"
        onClick={handleOnSave}
        variant={ButtonVariants.Primary}
      />
    </div>
  );
};
