import cx from 'classnames';
import type { ChangeEvent, FormEvent } from 'react';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import typography from '~styles/typography.scss';
import { PageContent } from '../layout/PageContent';
import { PageHeader } from '../layout/PageHeader';
import type { ImportedGuest } from './apiHelpers';
import styles from './GuestImport.scss';

const VALID_HEADERS = ['firstName', 'lastName', 'phone', 'notes'];

export const GuestImport = () => {
  const [data, setData] = useState<string[][]>([]);
  const [hasMissingData, setHasMissingData] = useState(false);
  const [hasInvalidHeaders, setHasInvalidHeaders] = useState(false);

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const [headers, ...rows] = data;

    const importedGuestsJson = rows.map((row) =>
      headers.reduce(
        (jsonRow, header, index) => ({ ...jsonRow, [header]: row[index] }),
        {} as ImportedGuest,
      ),
    );

    // debugging the parsed converted csv to json to later be used when persisting to server
    // left in for dev purposes only
    console.log({ importedGuestsJson });
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData([]);
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      setHasInvalidHeaders(false);
      if (!e.target?.result) return;

      const csv = String(e.target.result);
      const parsed = csv
        .split(/\r?\n/)
        .map((line) => line.split(','))
        .filter((line) => line.length > 1);

      const [headers, ...rows] = parsed;

      setHasMissingData(rows.some((row) => row.some((cell) => !cell)));

      const hasValidHeaders = headers.every((header) =>
        VALID_HEADERS.includes(header),
      );
      if (hasValidHeaders) {
        setData(parsed);
      } else {
        setHasInvalidHeaders(true);
      }
    };

    reader.readAsText(file);
  };

  const [headers, ...rows] = data;

  return (
    <>
      <PageHeader title="Guest Import" />
      <PageContent className={styles.container}>
        <form onSubmit={handleOnSubmit}>
          <Button
            isDisabled={!rows.length || hasMissingData}
            label="Do it, I dare you"
            type="submit"
            variant={ButtonVariants.Primary}
          />
          <label htmlFor="guest-importer">Import Guests</label>
          <input
            type="file"
            accept=".csv"
            id="guest-importer"
            onChange={handleOnChange}
          />
          {hasInvalidHeaders && (
            <p className={styles.error}>
              To be importable, a CSV must have the following headers:
              firstName, lastName, phone, notes.
            </p>
          )}
        </form>
        {!!rows.length && (
          <table>
            <thead>
              <tr>
                {headers.map((field) => (
                  <th className={typography.c1} key={field}>
                    {field}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((guest) => (
                <tr
                  className={cx(guest.some((info) => !info) && styles.error)}
                  key={JSON.stringify(guest)}
                >
                  {guest.map((field) => (
                    <td className={typography.c3} key={field}>
                      {field}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </PageContent>
    </>
  );
};
