// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SeatedWalkInFloorPlanTable__container___h8yd3{display:flex;position:absolute;padding:0;border:none}.SeatedWalkInFloorPlanTable__container___h8yd3 .SeatedWalkInFloorPlanTable__button___ENVmp{background-color:rgba(0,0,0,0);padding:0;border:none}.SeatedWalkInFloorPlanTable__container___h8yd3 .SeatedWalkInFloorPlanTable__icon___QPrC7{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:1;display:flex;flex-direction:column;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/floorPlan/SeatedWalkInFloorPlanTable.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,iBAAA,CACA,SAAA,CACA,WAAA,CAEA,2FACE,8BAAA,CACA,SAAA,CACA,WAAA,CAGF,yFAEE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,SAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SeatedWalkInFloorPlanTable__container___h8yd3",
	"button": "SeatedWalkInFloorPlanTable__button___ENVmp",
	"icon": "SeatedWalkInFloorPlanTable__icon___QPrC7"
};
export default ___CSS_LOADER_EXPORT___;
