import { isPersistedPrimaryMergedTable } from 'restaurantAdmin/floorPlans/utils';
import type { MergeUnmergeState } from './mergeUnmergeTablesReducer';

export const canUndoMerge = (state: MergeUnmergeState): boolean =>
  state.mergedTables.length > 0 &&
  state.selectedTables.length === 1 &&
  state.mergedTables
    .map((mergedTable) => mergedTable.primaryMergeTableId)
    .includes(state.selectedTables[0].id);

export const isMergeable = (state: MergeUnmergeState): boolean =>
  state.selectedTables.length > 1;

export const isUnmergeable = (state: MergeUnmergeState): boolean =>
  state.selectedTables.length === 1 &&
  isPersistedPrimaryMergedTable(state.selectedTables[0]) &&
  state.mergedTables.length === 0;

export const isReadyToPersistMerge = (state: MergeUnmergeState): boolean =>
  state.mergedTables.length > 0 || state.unMergedTableIds.length > 0;

export const isStagedPrimaryMergedTableId =
  (state: MergeUnmergeState) => (tableId: string) =>
    state.mergedTables.some(
      ({ primaryMergeTableId }) => primaryMergeTableId === tableId,
    );

export const isStagedForUnmerge =
  (state: MergeUnmergeState) => (tableId: string) =>
    state.unMergedTableIds.includes(tableId);

export const isSelectedForMerge =
  (state: MergeUnmergeState) =>
  (tableId: string): boolean =>
    !!state.selectedTables.find((table) => table.id === tableId);

export const isStagedSecondaryMergedTableId =
  (state: MergeUnmergeState) => (tableId: string) =>
    state.mergedTables
      .flatMap((mergedTable) => mergedTable.secondaryMergeTableIds)
      .includes(tableId);
