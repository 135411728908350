import { UNAUTHORIZED } from '@shared/statusCodes';

interface GetOptions {
  redirect?: boolean;
  signal?: AbortSignal;
}

export default {
  get: async (
    path: string,
    { redirect = true, signal }: GetOptions = {},
  ): Promise<Response> => {
    const response = await fetch(`${process.env.API_HOST}${path}`, {
      method: 'GET',
      credentials: 'include',
      signal,
    });

    if (response.status === UNAUTHORIZED && redirect) {
      window.location.assign('/');
    }

    return response;
  },
  post: async (path: string, body?: object): Promise<Response> =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  delete: async (path: string): Promise<Response> =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  put: async (path: string, body?: object): Promise<Response> =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
};
