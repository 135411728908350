import { useWatch } from 'react-hook-form';
import layoutStyles from '../../../layout/Layout.scss';
import { EditListingTimeAndPricePageBody } from '../edit/EditListingTimeAndPricePageBody';
import { useCreateListingContext } from './CreateListingContext';
import { CreateListingTimeAndPricePageHeader } from './CreateListingTimeAndPricePageHeader';

export const CreateListingTimeAndPricePage = () => {
  const { timeAndPriceControl, detailsControl, handleOnSubmit } =
    useCreateListingContext();

  const publicName = useWatch({
    control: detailsControl,
    name: 'publicName',
  });

  return (
    <form
      className={layoutStyles.pageWrapper}
      onSubmit={handleOnSubmit}
      noValidate
    >
      <CreateListingTimeAndPricePageHeader />
      <EditListingTimeAndPricePageBody
        detailsControl={detailsControl}
        listingName={publicName}
        timeAndPriceControl={timeAndPriceControl}
      />
    </form>
  );
};
