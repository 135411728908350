import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import type { ServiceOccupant } from '../apiHelpers';
import { isReservation } from '../apiHelpers';
import { useReservationServiceContext } from '../state/reservationServiceContext';
import { ActiveReservationCard } from './ActiveReservationCard';
import styles from './InfiniteOccupantList.scss';
import { SeatedWalkInCard } from './SeatedWalkInCard';
import { groupOccupantsByFifteenMinuteIncrement } from './utils';

interface OccupantsByTimeProps {
  occupantsForDate: ServiceOccupant[];
}

export const OccupantsByTime = ({ occupantsForDate }: OccupantsByTimeProps) => {
  const {
    handleOnSelectOccupant,
    occupants,
    refreshFloorPlan,
    refreshOccupants,
    selectedOccupant,
  } = useReservationServiceContext();

  const occupantsByTime =
    groupOccupantsByFifteenMinuteIncrement(occupantsForDate);

  if (!occupants.length) {
    return (
      <div className={styles.noContent}>
        There are no upcoming reservations to display
      </div>
    );
  }

  return (
    <>
      {Object.entries(occupantsByTime).map(([time, timeOccupants]) => {
        const timeGroupId = `time-group-${time}`;

        return (
          <li aria-labelledby={timeGroupId} key={`time-divider-${time}`}>
            <h4 className={typography.h6} id={timeGroupId}>
              {ISOTimeTo12HourTime(time)}
            </h4>
            <ul
              aria-label={`occupants starting at ${ISOTimeTo12HourTime(time)}`}
            >
              {timeOccupants.map((occupant) => {
                const isSelected = Boolean(
                  selectedOccupant?.id === occupant.id,
                );

                if (isReservation(occupant)) {
                  return (
                    <ActiveReservationCard
                      isSelected={isSelected}
                      key={occupant.id}
                      onClick={() => {
                        handleOnSelectOccupant(occupant.id, occupant.type);
                      }}
                      onUpdated={() => {
                        refreshOccupants();
                        refreshFloorPlan();
                      }}
                      reservation={occupant}
                    />
                  );
                }

                return (
                  <SeatedWalkInCard
                    key={occupant.id}
                    onClick={() => {
                      handleOnSelectOccupant(occupant.id, occupant.type);
                    }}
                    onUpdated={() => {
                      refreshOccupants();
                      refreshFloorPlan();
                    }}
                    walkIn={occupant}
                  />
                );
              })}
            </ul>
          </li>
        );
      })}
    </>
  );
};
