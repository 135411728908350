import type { ApiResponse, ErrorResponse } from '@shared/types/apiHelpers';
import API from '../api/apiClient';
import { type RestaurantMetadata } from '../restaurants/apiHelpers';

export interface RestaurantsMetadata {
  fullName: string;
  restaurants: RestaurantMetadata[];
}

export interface RestaurantAdminAuthData {
  fullName: string;
  isAuthenticated: boolean;
  restaurants: RestaurantMetadata[];
}

export interface RegisterAdminPayload {
  email: string;
  fullName: string;
  isTosAccepted: boolean;
  password: string;
  token: string;
}

export const checkAuth = async (): Promise<
  ApiResponse<RestaurantAdminAuthData>
> => {
  const response = await API.get('/check-auth-admin', { redirect: false });

  return response.json() as unknown as ApiResponse<RestaurantAdminAuthData>;
};

export const login = async (
  email: string,
  password: string,
): Promise<ApiResponse<RestaurantsMetadata>> => {
  const response = await API.post('/admin-login', {
    email,
    password,
  });

  return response.json() as unknown as ApiResponse<RestaurantsMetadata>;
};

export const requestPasswordReset = (email: string): Promise<Response> =>
  API.post('/admin/forgot-password', { email });

export const signOut = async (): Promise<Response> =>
  API.post('/admin-sign-out');

export const updateAdminPassword = async (
  password: string,
  token: string,
): Promise<Response | ErrorResponse> => {
  const response = await API.post(`/admin/reset-password`, { password, token });

  if (response.ok) {
    return response;
  }

  return response.json();
};

export const registerAdmin = async (
  payload: RegisterAdminPayload,
): Promise<Response> =>
  API.post('/restaurants/admins/complete-registration', payload);
