import { createAction } from '@shared/context/reducerUtils';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';

interface MergingTable {
  primaryMergeTableId: string;
  secondaryMergeTableIds: string[];
}

export interface MergeUnmergeState {
  selectedTables: HostFloorPlanTable[];
  mergedTables: MergingTable[];
  unMergedTableIds: string[];
}
enum MergeUnmergeTablesActionType {
  SelectTable = 'SelectTable',
  UnselectTable = 'UnselectTable',
  ClearMergeState = 'ClearMergeState',
  StageMerge = 'StageMerge',
  StageUnmerge = 'StageUnmerge',
  UndoMerge = 'UndoMerge',
}

export const selectTable = (table: HostFloorPlanTable) =>
  createAction(MergeUnmergeTablesActionType.SelectTable, { table });
export const unSelectTable = (tableId: string) =>
  createAction(MergeUnmergeTablesActionType.UnselectTable, { tableId });
export const clearMerges = () =>
  createAction(MergeUnmergeTablesActionType.ClearMergeState, null);
export const stageMerge = () =>
  createAction(MergeUnmergeTablesActionType.StageMerge, null);
export const stageUnMerge = () =>
  createAction(MergeUnmergeTablesActionType.StageUnmerge, null);
export const undoMerge = () =>
  createAction(MergeUnmergeTablesActionType.UndoMerge, null);

export const mergeUnmergeTablesInitialState: MergeUnmergeState = {
  selectedTables: [],
  mergedTables: [],
  unMergedTableIds: [],
};

export const mergeUnmergeTablesReducer = (
  state: MergeUnmergeState,
  action:
    | ReturnType<typeof selectTable>
    | ReturnType<typeof unSelectTable>
    | ReturnType<typeof clearMerges>
    | ReturnType<typeof stageMerge>
    | ReturnType<typeof stageUnMerge>
    | ReturnType<typeof undoMerge>,
): MergeUnmergeState => {
  switch (action.type) {
    case MergeUnmergeTablesActionType.SelectTable:
      return {
        ...state,
        selectedTables: [...state.selectedTables, action.payload.table],
      };
    case MergeUnmergeTablesActionType.UnselectTable:
      return {
        ...state,
        selectedTables: state.selectedTables.filter(
          (table) => table.id !== action.payload.tableId,
        ),
      };
    case MergeUnmergeTablesActionType.ClearMergeState:
      return mergeUnmergeTablesInitialState;
    case MergeUnmergeTablesActionType.StageMerge: {
      const [primarySelectedTable, ...secondarySelectedTables] =
        state.selectedTables;
      const secondarySelectedTableIds = secondarySelectedTables.map(
        (table) => table.id,
      );
      const secondariesThatAreMerged = state.mergedTables.filter(
        (alreadyMergedTable) =>
          secondarySelectedTableIds.includes(
            alreadyMergedTable.primaryMergeTableId,
          ),
      );
      const secondariesSecondaryIds = secondariesThatAreMerged.flatMap(
        (table) => table.secondaryMergeTableIds,
      );
      const mergedTablesFilteredOfOldPrimaries = state.mergedTables.filter(
        (table) =>
          !secondarySelectedTableIds.includes(table.primaryMergeTableId),
      );

      return {
        ...state,
        selectedTables: [],
        mergedTables: [
          ...mergedTablesFilteredOfOldPrimaries,
          {
            primaryMergeTableId: primarySelectedTable.id,
            secondaryMergeTableIds: [
              ...secondarySelectedTableIds,
              ...secondariesSecondaryIds,
            ],
          },
        ],
      };
    }
    case MergeUnmergeTablesActionType.StageUnmerge:
      return {
        ...state,
        selectedTables: [],
        unMergedTableIds: [
          ...state.unMergedTableIds,
          ...state.selectedTables.map((table) => table.id),
        ],
      };
    case MergeUnmergeTablesActionType.UndoMerge:
      return {
        ...state,
        selectedTables: [],
        mergedTables: state.mergedTables.filter(
          (mt) => mt.primaryMergeTableId !== state.selectedTables[0].id,
        ),
      };
    default:
      return state;
  }
};
