import { addDays, parseISO } from 'date-fns';
import { todayInTimezone, toISODateFormat } from '@utils/date';
import { DateSelector } from '../../../components/dateSelector/DateSelector';
import { useRestaurant } from '../../../context/useRestaurant';
import { useListingsContext } from '../ListingsContext';

export const FloorPlanDateSelector = () => {
  const { timezone } = useRestaurant();
  const { selectedDate, setSelectedDate } = useListingsContext();

  const todayInRestaurant = todayInTimezone(timezone);
  const isToday = selectedDate === todayInRestaurant;

  const handleOnClickToday = () => setSelectedDate(todayInRestaurant);
  const handleOnChangeCalendarDate = (date: Date) =>
    setSelectedDate(toISODateFormat(date));
  const setNextDay = () => {
    const nextDay = addDays(parseISO(selectedDate), 1);
    setSelectedDate(toISODateFormat(nextDay));
  };
  const setPreviousDay = () => {
    const previousDay = addDays(parseISO(selectedDate), -1);
    setSelectedDate(toISODateFormat(previousDay));
  };

  return (
    <DateSelector
      handleOnClickToday={handleOnClickToday}
      isToday={isToday}
      handleOnChangeCalendarDate={handleOnChangeCalendarDate}
      selectedDate={parseISO(selectedDate)}
      setNextDay={setNextDay}
      setPreviousDay={setPreviousDay}
    />
  );
};
