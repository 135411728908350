import cx from 'classnames';
import { get } from 'lodash-es';
import { useEffect, useState } from 'react';
import type {
  Control,
  FieldErrors,
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseFormRegister,
} from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import hookAwareFormInputStyles from '@components/hookAwareFormInput/HookAwareFormInput.scss';
import { getValidationValue } from '@components/hookAwareFormInput/utils';
import { ValidationErrorMessage } from '@components/validationErrorMessage/ValidationErrorMessage';
import typography from '~styles/typography.scss';
import styles from './EndlessDateFormControl.scss';

export interface EndlessDateFormControlProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  className?: string;
  control: Control<T>;
  errors: FieldErrors;
  label: string;
  name: Name;
  register: UseFormRegister<T>;
  validations?: UseControllerProps<T, Name>['rules'];
}

export const EndlessDateFormControl = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  errors,
  label,
  name,
  register,
  validations,
}: EndlessDateFormControlProps<T, Name>) => {
  const watch = useWatch({ control });
  const dateValue = get(watch, name);
  const [isDateEndless, setIsDateEndless] = useState(!dateValue);
  const registeredField = register(name, {
    required: !isDateEndless,
    shouldUnregister: true,
    ...(validations || {}),
  });
  const hasError = name in errors;

  useEffect(() => {
    if (isDateEndless) {
      void (async () => {
        await registeredField.onChange({ target: { value: '' } });
      })();
    } else {
      void (async () => {
        await registeredField.onChange({ target: { value: dateValue } });
      })();
    }
  }, [dateValue, isDateEndless]);

  const min = getValidationValue({
    inputType: 'date',
    key: 'min',
    validations,
  });

  const handleSetEndlessDateClick = () => {
    setIsDateEndless(false);
  };

  const handleEndlessClick = () => {
    setIsDateEndless(true);
  };

  const handleSpaceKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.key === ' ') {
      setIsDateEndless(!isDateEndless);
    }
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.labelContainer}>
        <label
          className={cx({
            [typography.c2_20]: true,
            [styles.labelError]: hasError,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      </div>
      <div className={styles.switchEndlessDateContainer}>
        <div
          aria-checked={isDateEndless}
          className={styles.switch}
          onKeyDown={handleSpaceKeyDown}
          role="switch"
          tabIndex={0}
        >
          {
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              className={cx({
                [typography.c2_20]: isDateEndless,
                [styles.checkEndless]: !isDateEndless,
              })}
              onClick={handleEndlessClick}
            >
              Endless
            </div>
          }
          {isDateEndless && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              onClick={handleSetEndlessDateClick}
              className={cx(typography.c2_20, styles.setEndlessDate)}
            >
              {`Set ${label}`}
            </div>
          )}
        </div>
        {!isDateEndless && (
          <div className={hookAwareFormInputStyles.inputContainer}>
            <input
              aria-errormessage={`${name}-error`}
              aria-invalid={hasError}
              autoComplete="off"
              className={cx({
                [typography.t1]: true,
                [hookAwareFormInputStyles.inputError]: hasError,
              })}
              id={name}
              min={min}
              type="date"
              {...registeredField}
            />
            <ValidationErrorMessage errors={errors} label={label} name={name} />
          </div>
        )}
      </div>
    </div>
  );
};
