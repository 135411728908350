// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StrandedPage__pageContent___U9uSO{display:flex;flex-direction:column}.StrandedPage__content___oGEtw{display:flex;flex:1 0 0;flex-direction:column;min-height:0}.StrandedPage__content___oGEtw .StrandedPage__layout___VWOXH{display:flex;min-height:0;gap:8%;margin-top:1.5rem}.StrandedPage__content___oGEtw .StrandedPage__layout___VWOXH .StrandedPage__floorPlan____QdOm{max-width:58%;min-width:58%}.StrandedPage__content___oGEtw .StrandedPage__availabilityContainer___tgqVJ{display:flex;gap:1rem;margin-top:1rem;z-index:var(--z_desktopAvailabilityFilter)}.StrandedPage__reserveButton___ZvAEc{height:2rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/stranded/StrandedPage.scss"],"names":[],"mappings":"AAAA,mCACE,YAAA,CACA,qBAAA,CAGF,+BACE,YAAA,CACA,UAAA,CACA,qBAAA,CACA,YAAA,CAEA,6DACE,YAAA,CACA,YAAA,CACA,MAAA,CACA,iBAAA,CAEA,8FACE,aAAA,CACA,aAAA,CAIJ,4EACE,YAAA,CACA,QAAA,CACA,eAAA,CACA,0CAAA,CAIJ,qCACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "StrandedPage__pageContent___U9uSO",
	"content": "StrandedPage__content___oGEtw",
	"layout": "StrandedPage__layout___VWOXH",
	"floorPlan": "StrandedPage__floorPlan____QdOm",
	"availabilityContainer": "StrandedPage__availabilityContainer___tgqVJ",
	"reserveButton": "StrandedPage__reserveButton___ZvAEc"
};
export default ___CSS_LOADER_EXPORT___;
