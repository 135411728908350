import cx from 'classnames';
import { type Control, useController } from 'react-hook-form';
import typography from '~styles/typography.scss';
import type { ListingTimeAndPriceFormData } from '../types';
import styles from './ReservableDaysFieldSet.scss';

export interface ReservableDaysFieldSetProps {
  control: Control<ListingTimeAndPriceFormData>;
}

const EVERYDAY = ['0', '1', '2', '3', '4', '5', '6'];
const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const ReservableDaysFieldSet = ({
  control,
}: ReservableDaysFieldSetProps) => {
  const {
    field: { onChange: handleOnChangeRepeat, value: repeat },
    fieldState: { error },
  } = useController({
    control,
    name: 'repeat',
    rules: {
      required: true,
    },
  });

  const handleOnClickEveryday = () => {
    handleOnChangeRepeat(EVERYDAY);
  };

  const handleOnClickDay = (dayIndex: string) => () => {
    const newRepeat = [...repeat];

    if (newRepeat.includes(dayIndex)) {
      newRepeat.splice(repeat.indexOf(dayIndex), 1);
      handleOnChangeRepeat(newRepeat);
    } else {
      newRepeat.push(dayIndex);
      handleOnChangeRepeat(newRepeat);
    }
  };

  const handleSpaceKeyDown =
    (dayIndex: string) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      const newRepeat = [...repeat];

      if (event.key === ' ') {
        if (newRepeat.includes(dayIndex)) {
          newRepeat.splice(repeat.indexOf(dayIndex), 1);
          handleOnChangeRepeat(newRepeat);
        } else {
          newRepeat.push(dayIndex);
          handleOnChangeRepeat(newRepeat);
        }
      }
    };

  return (
    <fieldset
      className={styles.fieldset}
      data-testid="reservable-days-fieldset"
    >
      <h3 className={typography.t3}>reservable days</h3>
      <label
        className={cx({
          [typography.c2_20]: true,
          [styles.labelError]: !!error,
        })}
        htmlFor="repeat"
      >
        Repeats On
        <button
          onClick={handleOnClickEveryday}
          type="button"
          className={typography.c3_20}
        >
          Everyday
        </button>
      </label>
      <div
        role="group"
        aria-labelledby="repeat"
        className={styles.checkboxContainer}
      >
        <ul>
          {DAYS_OF_WEEK.map((day, index) => (
            <li key={day}>
              <div
                className={cx({
                  [typography.c2_20]: true,
                  [styles.error]: !!error,
                })}
                role="checkbox"
                aria-checked={repeat.includes(String(index))}
                tabIndex={0}
                onClick={handleOnClickDay(String(index))}
                onKeyDown={handleSpaceKeyDown(String(index))}
              >
                {day}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </fieldset>
  );
};
