import cx from 'classnames';
import { secondsToMilliseconds } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ExternalRefundLink } from '@components/externalRefundLink/ExternalRefundLink';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import { centsToDollar } from '@shared/utils/currency';
import { toShortDateTime } from '@shared/utils/date';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { cancel } from 'restaurantAdmin/reservations/apiHelpers';
import typography from '~styles/typography.scss';
import styles from './CancelConfirmationModal.scss';

export interface CancelConfirmationModalProps {
  cancellationPolicy: {
    fee: number;
  } | null;
  closeModal: () => void;
  disablePortal?: boolean;
  eligibleForCancellationFeeUntil: string;
  isEligibleForCancellationFeeUponCancellation: boolean;
  isOpen: boolean;
  reservationId: string;
}

const CURRENT_PAGE = 0;

export const CancelConfirmationModal = ({
  cancellationPolicy,
  closeModal,
  disablePortal,
  eligibleForCancellationFeeUntil,
  isEligibleForCancellationFeeUponCancellation,
  isOpen,
  reservationId,
}: CancelConfirmationModalProps) => {
  const restaurant = useRestaurant();
  const navigate = useNavigate();

  const [isPending, setIsPending] = useState(false);

  const cancelOnlyCopy = () => (
    <p className={cx(typography.c2, styles.message)}>
      Are you sure you would like to cancel this reservation? Please reference
      the Peak &nbsp;
      <ExternalRefundLink className={styles.link} />
      &nbsp; for any concerns regarding cancellations.
    </p>
  );

  const cancelWithFeeCollectionDetailsCopy = () => (
    <p className={cx(typography.c2, styles.message)}>
      This reservation has a cancellation fee of{' '}
      {centsToDollar(cancellationPolicy?.fee)} attached. If you skip collecting
      the fee right now, you have until{' '}
      {toShortDateTime(
        toZonedTime(eligibleForCancellationFeeUntil, restaurant.timezone),
      )}{' '}
      to collect this fee on the Occupants page. Please reference the Peak{' '}
      <ExternalRefundLink className={styles.link} /> for any concerns regarding
      cancellations.
    </p>
  );

  const refreshPage = () =>
    setTimeout(() => navigate(CURRENT_PAGE), secondsToMilliseconds(3));

  const handleCancel = () => {
    setIsPending(true);

    void (async () => {
      const response = await cancel(restaurant.id, reservationId);

      if (response.ok) {
        successToast({
          message:
            "Success: The guest's reservation has been successfully canceled.",
        });
        refreshPage();
      } else {
        const errorMessage = await getErrorResponseMessage(response);
        errorToast({ message: errorMessage });
        setIsPending(false);
      }
    })();
    closeModal();
  };

  return (
    <Modal
      ariaLabel="Cancel Confirmation"
      disablePortal={disablePortal}
      isOpen={isOpen}
      onClose={closeModal}
    >
      {isEligibleForCancellationFeeUponCancellation
        ? cancelWithFeeCollectionDetailsCopy()
        : cancelOnlyCopy()}
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isPending}
          label="Confirm"
          onClick={handleCancel}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
