import { kebabCase } from 'lodash-es';
import type { FloorPlanTablesRenderer } from '@components/floorPlan/FloorPlan';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { type RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  isFloorPlanTableSeated,
  isFloorPlanTableWithSeatedReservation,
  isPersistedPrimaryMergedTable,
  isPersistedSecondaryMergedTable,
} from 'restaurantAdmin/floorPlans/utils';
import { useMergeUnmergeTables } from 'restaurantAdmin/reservations/mergeUnmergeTables/state/mergeUnmergeTablesContext';
import { SeatedFloorPlanTable } from '../service/floorPlan/SeatedFloorPlanTable';
import { SeatedGuestFloorPlanTable } from '../service/floorPlan/SeatedGuestFloorPlanTable';
import { SeatedWalkInFloorPlanTable } from '../service/floorPlan/SeatedWalkInFloorPlanTable';
import { UnoccupiedFloorPlanTable } from '../service/floorPlan/UnoccupiedFloorPlanTable';

export interface MergeUnmergeFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  tables: HostFloorPlanTable[];
}

export const MergeUnmergeFloorPlan = ({
  backgroundSrc,
  className,
  tables,
}: MergeUnmergeFloorPlanProps) => {
  const {
    isStagedSecondaryMergedTableId,
    isStagedPrimaryMergedTableId,
    isStagedForUnmerge,
    isSelectedForMerge,
    selectTableForMerge,
    unselectTableForMerge,
  } = useMergeUnmergeTables();

  const handleTableOnClick = (floorPlanTable: HostFloorPlanTable) => {
    if (isSelectedForMerge(floorPlanTable.id)) {
      unselectTableForMerge(floorPlanTable.id);
    } else {
      selectTableForMerge(floorPlanTable);
    }
  };

  const mergeAwareTables = tables
    .map((table) => {
      if (isStagedPrimaryMergedTableId(table.id)) {
        return {
          ...table,
          iconName: 'mergedTable',
          orientation: '0.00',
        };
      }
      if (
        isPersistedPrimaryMergedTable(table) &&
        !isStagedForUnmerge(table.id)
      ) {
        return {
          ...table,
          iconName: 'mergedTable',
          orientation: '0.00',
        };
      }
      if (isStagedSecondaryMergedTableId(table.id)) {
        // hidden from floor plan
        return null;
      }
      if (
        isPersistedSecondaryMergedTable(table) &&
        !isStagedForUnmerge(table.mergeTableId)
      )
        // hidden from floor plan if associated with a primary merged table
        return null;
      return table;
    })
    .filter((table): table is HostFloorPlanTable => table !== null);

  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) => (
    <>
      {mergeAwareTables.map((floorPlanTable) => {
        const testId = kebabCase(`floor-plan-table-${floorPlanTable.name}`);
        if (isStagedSecondaryMergedTableId(floorPlanTable.id)) return null;
        if (
          isPersistedSecondaryMergedTable(floorPlanTable) &&
          !isStagedForUnmerge(floorPlanTable.mergeTableId)
        )
          return null;
        if (
          isFloorPlanTableSeated(floorPlanTable) &&
          floorPlanTable.seatedOccupant
        ) {
          return (
            <FeatureFlagOn<RestaurantAdminFeatureFlag>
              key={floorPlanTable.id}
              element={
                <SeatedFloorPlanTable
                  iconName={floorPlanTable.iconName}
                  iconWidthScale={floorPlanTable.iconWidthScale}
                  isDisabled
                  isHighlighted={false}
                  key={floorPlanTable.id}
                  left={floorPlanTable.left}
                  name={floorPlanTable.name}
                  orientation={floorPlanTable.orientation}
                  seatedOccupant={floorPlanTable.seatedOccupant}
                  tableIconScale={tableIconScale}
                  tableName={floorPlanTable.name}
                  testId={testId}
                  top={floorPlanTable.top}
                />
              }
              fallback={
                isFloorPlanTableWithSeatedReservation(floorPlanTable) ? (
                  <SeatedGuestFloorPlanTable
                    iconWidthScale={floorPlanTable.iconWidthScale}
                    iconName={floorPlanTable.iconName}
                    isDisabled
                    isHighlighted={false}
                    key={floorPlanTable.id}
                    left={floorPlanTable.left}
                    name={floorPlanTable.name}
                    orientation={floorPlanTable.orientation}
                    seatedGuest={floorPlanTable.seatedParty}
                    tableIconScale={tableIconScale}
                    tableName={floorPlanTable.name}
                    testId={testId}
                    top={floorPlanTable.top}
                  />
                ) : (
                  <SeatedWalkInFloorPlanTable
                    iconWidthScale={floorPlanTable.iconWidthScale}
                    iconName={floorPlanTable.iconName}
                    isDisabled
                    isHighlighted={false}
                    key={floorPlanTable.id}
                    left={floorPlanTable.left}
                    name={floorPlanTable.name}
                    orientation={floorPlanTable.orientation}
                    seatedWalkIn={floorPlanTable.seatedParty}
                    tableIconScale={tableIconScale}
                    tableName={floorPlanTable.name}
                    testId={testId}
                    top={floorPlanTable.top}
                  />
                )
              }
              name="serviceStatusFlag"
            />
          );
        }
        return (
          <UnoccupiedFloorPlanTable
            availableTime={null}
            handleTableOnClick={() => {
              void handleTableOnClick(floorPlanTable);
            }}
            iconWidthScale={floorPlanTable.iconWidthScale}
            isDisabled={false}
            iconName={floorPlanTable.iconName}
            isHighlighted={isSelectedForMerge(floorPlanTable.id)}
            isSelected={false}
            key={floorPlanTable.id}
            left={floorPlanTable.left}
            name={floorPlanTable.name}
            orientation={floorPlanTable.orientation}
            tableIconScale={tableIconScale}
            testId={testId}
            top={floorPlanTable.top}
          />
        );
      })}
    </>
  );

  return (
    <FloorPlan
      backgroundSrc={backgroundSrc}
      className={className}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
      testId="merge-unmerge-floor-plan"
    />
  );
};
