import cx from 'classnames';
import { secondsToMilliseconds } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ExternalRefundLink } from '@components/externalRefundLink/ExternalRefundLink';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import { centsToDollar } from '@shared/utils/currency';
import { toShortDateTime } from '@shared/utils/date';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import {
  collectCancellationFee,
  waiveCancellationFee,
} from 'restaurantAdmin/reservations/occupants/apiHelpers';
import typography from '~styles/typography.scss';
import { cancel } from '../../reservations/apiHelpers';
import styles from './CancelConfirmationModal.scss';

const CURRENT_PAGE = 0;

const CANCEL_SUCCESS_MESSAGE =
  "Success: The guest's reservation has been successfully canceled.";
const COLLECT_SUCCESS_MESSAGE =
  "Success: The guest's reservation cancellation fee has been collected.";
const WAIVE_SUCCESS_MESSAGE =
  "Success: The guest's reservation cancellation fee has been waived.";

export interface CancelConfirmationModalProps {
  cancellationPolicy: {
    fee: number;
  } | null;
  closeModal: () => void;
  disablePortal?: boolean;
  eligibleForCancellationFeeUntil: string;
  isEligibleForCancellationFeeUponCancellation: boolean;
  isOpen: boolean;
  reservationId: string;
}

export const CancelConfirmationModal = ({
  cancellationPolicy,
  closeModal,
  disablePortal,
  eligibleForCancellationFeeUntil,
  isEligibleForCancellationFeeUponCancellation,
  isOpen,
  reservationId,
}: CancelConfirmationModalProps) => {
  const restaurant = useRestaurant();
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);

  const displayErrorToast = async (response: Response) => {
    const errorMessage = await getErrorResponseMessage(response);
    errorToast({ message: errorMessage });
  };

  const refreshPage = () =>
    setTimeout(() => navigate(CURRENT_PAGE), secondsToMilliseconds(3));

  const handleCancel = () => {
    setIsPending(true);

    void (async () => {
      const response = await cancel(restaurant.id, reservationId);

      if (response.ok) {
        successToast({ message: CANCEL_SUCCESS_MESSAGE });
        refreshPage();
      } else {
        await displayErrorToast(response);
        setIsPending(false);
      }
    })();
    closeModal();
  };

  const handleCollectFee = () => {
    setIsPending(true);

    void (async () => {
      const cancelResponse = await cancel(restaurant.id, reservationId);

      if (cancelResponse.ok) {
        successToast({ message: CANCEL_SUCCESS_MESSAGE });
        const collectFeeResponse = await collectCancellationFee(
          restaurant.id,
          reservationId,
        );
        if (collectFeeResponse.ok) {
          successToast({ message: COLLECT_SUCCESS_MESSAGE });
        } else {
          await displayErrorToast(collectFeeResponse);
        }
        refreshPage();
      } else {
        await displayErrorToast(cancelResponse);
        setIsPending(false);
      }
    })();
    closeModal();
  };

  const handleWaiveFee = () => {
    setIsPending(true);

    void (async () => {
      const cancelResponse = await cancel(restaurant.id, reservationId);

      if (cancelResponse.ok) {
        successToast({ message: CANCEL_SUCCESS_MESSAGE });
        const collectFeeResponse = await waiveCancellationFee(
          restaurant.id,
          reservationId,
        );
        if (collectFeeResponse.ok) {
          successToast({ message: WAIVE_SUCCESS_MESSAGE });
        } else {
          await displayErrorToast(collectFeeResponse);
        }
        refreshPage();
      } else {
        await displayErrorToast(cancelResponse);
        setIsPending(false);
      }
    })();
    closeModal();
  };

  const onlyCancelCopy = () => (
    <p className={cx(typography.c2, styles.message)}>
      Are you sure you would like to cancel this reservation? Please reference
      the Peak &nbsp;
      <ExternalRefundLink className={styles.link} />
      &nbsp; for any concerns regarding cancellations.
    </p>
  );

  const cancelAndCollectCopy = () => (
    <p className={cx(typography.c2, styles.message)}>
      This reservation has a cancellation fee of{' '}
      {centsToDollar(cancellationPolicy?.fee)} attached. If you skip collecting
      the fee right now, you have until{' '}
      {toShortDateTime(
        toZonedTime(eligibleForCancellationFeeUntil, restaurant.timezone),
      )}{' '}
      to collect this fee on the Occupants page. Please reference the Peak{' '}
      <ExternalRefundLink className={styles.link} /> for any concerns regarding
      cancellations.
    </p>
  );

  return (
    <Modal
      ariaLabel={
        isEligibleForCancellationFeeUponCancellation
          ? 'Cancel and Collect Confirmation'
          : 'Cancel Confirmation'
      }
      disablePortal={disablePortal}
      isOpen={isOpen}
      onClose={closeModal}
    >
      {isEligibleForCancellationFeeUponCancellation
        ? cancelAndCollectCopy()
        : onlyCancelCopy()}
      <ModalActions stack={isEligibleForCancellationFeeUponCancellation}>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isPending}
          label={
            isEligibleForCancellationFeeUponCancellation
              ? 'Confirm Cancel'
              : 'Confirm'
          }
          onClick={handleCancel}
          variant={ButtonVariants.Primary}
        />
        {isEligibleForCancellationFeeUponCancellation && (
          <Button
            isDisabled={isPending}
            label="Cancel & Collect Fee"
            onClick={handleCollectFee}
            variant={ButtonVariants.Secondary}
          />
        )}
        {isEligibleForCancellationFeeUponCancellation && (
          <Button
            isDisabled={isPending}
            label="Cancel & Waive Fee"
            onClick={handleWaiveFee}
            variant={ButtonVariants.Secondary}
          />
        )}
      </ModalActions>
    </Modal>
  );
};
