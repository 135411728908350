import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './GuestTagPill.scss';

interface GuestTagPillProps {
  title: string;
  category: string;
}

const VIP = 'VIP';

export const GuestTagPill = ({ title, category }: GuestTagPillProps) => (
  <li
    className={cx({
      [styles.tag]: true,
      [typography.t1]: true,
      [styles.green]: ['allergies', 'dietary restrictions'].includes(category),
      [styles.white]: category === 'guest attributes',
      [styles.gold]: category === 'other',
    })}
  >
    {title === VIP && <Icon ariaLabel="crown icon" name="crown" />}
    {title}
  </li>
);
