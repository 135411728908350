import cx from 'classnames';
import { uniq } from 'lodash-es';
import type { KeyboardEvent, MouseEvent } from 'react';
import { useEffect } from 'react';
import { type Control, useController, useWatch } from 'react-hook-form';
import type { IconNameType } from '@shared/types/floorPlans';
import typography from '~styles/typography.scss';
import type { ListingDetailsFormData } from '../types';
import styles from './DisplayIconFieldSet.scss';
import { DisplayIconRadioButton } from './DisplayIconRadioButton';

export interface DisplayIconFieldSetProps {
  control: Control<ListingDetailsFormData>;
}

export const DisplayIconFieldSet = ({ control }: DisplayIconFieldSetProps) => {
  const {
    field: { onChange: handleOnChangeIconName, value: iconName },
    fieldState: { error },
  } = useController({
    control,
    name: 'iconName',
    rules: {
      required: true,
    },
  });

  const highlightedTables = useWatch({
    control,
    name: 'highlightedTables',
  });

  const highlightedFloorPlanIcons = uniq(
    highlightedTables.map((highlightedTable) => highlightedTable.iconName),
  );

  const handleDisplayIconClick = (
    e: MouseEvent | KeyboardEvent,
    name: IconNameType,
  ) => {
    e.preventDefault();
    if (name === iconName) return;

    handleOnChangeIconName(name);
  };

  const displayIconIsInvalid = !!error && !!highlightedFloorPlanIcons.length;

  useEffect(() => {
    if (!highlightedFloorPlanIcons.includes(iconName)) {
      handleOnChangeIconName(undefined);
    }
    if (highlightedFloorPlanIcons.length === 1) {
      handleOnChangeIconName(highlightedFloorPlanIcons[0]);
    }
  }, [highlightedFloorPlanIcons]);

  return (
    <fieldset className={styles.fieldset} data-testid="display-icon-fieldset">
      <h3 className={typography.t3} id="displayIconFieldSetTitle">
        display icon
      </h3>
      {highlightedFloorPlanIcons.length ? (
        <div
          aria-labelledby="displayIconFieldSetTitle"
          className={styles.displayIconButtons}
          role="radiogroup"
        >
          {highlightedFloorPlanIcons.map((name, index) => (
            <DisplayIconRadioButton
              displayIconIsInvalid={displayIconIsInvalid}
              displayIconName={iconName}
              handleDisplayIconClick={handleDisplayIconClick}
              highlightedFloorPlanIcons={highlightedFloorPlanIcons}
              iconName={name}
              index={index}
              key={name}
            />
          ))}
        </div>
      ) : (
        <div className={cx(typography.t2, styles.displayIconEmptyState)}>
          Select at least one table in the floor plan above to use as a display
          icon.
        </div>
      )}
    </fieldset>
  );
};
