import type { Control } from 'react-hook-form';
import { Card } from '@components/card/Card';
import type { FloorPlanData } from '@shared/types/floorPlans';
import { PageContent } from '../../../layout/PageContent';
import { DisplayIconFieldSet } from '../form/DisplayIconFieldSet';
import { FloorPlanFieldset } from '../form/FloorPlanFieldset';
import { LabelsFieldSet } from '../form/LabelsFieldSet';
import { SeatingTypeFieldSet } from '../form/SeatingTypeFieldSet';
import { type ListingDetailsFormData } from '../types';
import styles from './EditListingDetailsPageBody.scss';

interface EditListingDetailsBodyProps {
  control: Control<ListingDetailsFormData>;
  isEditing: boolean;
  floorPlan: FloorPlanData;
}

export const EditListingDetailsPageBody = ({
  control,
  isEditing,
  floorPlan,
}: EditListingDetailsBodyProps) => (
  <PageContent className={styles.content}>
    <Card className={styles.displayPanel}>
      <FloorPlanFieldset control={control} floorPlan={floorPlan} />
    </Card>
    <div className={styles.sidePanelContainer}>
      <Card bordered className={styles.sidePanel}>
        <LabelsFieldSet control={control} />
        <SeatingTypeFieldSet control={control} isDisabledForEdit={isEditing} />
        <DisplayIconFieldSet control={control} />
      </Card>
    </div>
  </PageContent>
);
