import type { AnyIcon } from '@components/icon/Icon';
import { toISODateFormat } from '@utils/date';
import API from '../../api/apiClient';

interface AvailabilityListing {
  iconName: AnyIcon;
  id: string;
  isCommunal: boolean;
  publicName: string;
  price: number;
}

export interface AdminAvailabilityData {
  guestCount: number;
  listing: AvailabilityListing;
  time: string;
}

export interface HostBookReservationPayload {
  customPrice?: number;
  expectedCancellationPolicyId: string | null;
  date: string; // '2022-03-07
  firstName: string;
  guestCount: number;
  lastName: string;
  listingPrice: number;
  paymentMethodId?: string;
  phone: string; // '+15555555555'
  restaurantId: string;
  listingId: string;
  time: string; // '00:00:00
}

export const getAvailabilities = async (
  restaurantId: string,
  date: Date,
): Promise<AdminAvailabilityData[]> => {
  const response = await API.get(
    `/admin/restaurants/${restaurantId}/availabilities/${toISODateFormat(
      date,
    )}`,
  );

  return response.json();
};

export const hostBookReservation = async (
  payload: HostBookReservationPayload,
): Promise<Response> =>
  API.post(`/restaurants/${payload.restaurantId}/reservations`, payload);
