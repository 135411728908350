import type { ApiResponse } from '@shared/types/apiHelpers';
import API from '../../api/apiClient';
import { type ReservationStatus } from '../apiHelpers';

interface Guest {
  email?: string;
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
}
interface TransactionDetails {
  createdBy: {
    fullName?: string;
  };
  fee: number;
  price: number;
  receiptNumber: string;
  tax: number;
}
export interface StrandedReservation {
  date: string;
  guest: Guest;
  guestCount: number;
  id: string;
  status: ReservationStatus;
  time: string;
  transactionDetails: TransactionDetails;
}

interface RescueArgs {
  restaurantId: string;
  strandedReservationId: string;
  time: string;
  listingId: string;
  notifyGuest: boolean;
}

export const getAllUpcomingStrandedReservations = async (
  restaurantId: string,
): Promise<ApiResponse<StrandedReservation[]>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/reservations/stranded`,
  );
  return response.json();
};

export const rescue = async ({
  restaurantId,
  strandedReservationId,
  time,
  listingId,
  notifyGuest,
}: RescueArgs): Promise<Response> =>
  API.put(
    `/restaurants/${restaurantId}/reservations/stranded/${strandedReservationId}`,
    {
      time,
      listingId,
      notifyGuest,
    },
  );
