import { useEffect, useState } from 'react';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import type { FloorPlanData } from '@shared/types/floorPlans';
import { useError } from 'restaurantAdmin/errors/useError';
import { getFloorPlanForRestaurantId } from 'restaurantAdmin/floorPlans/apiHelpers';
import { useRestaurant } from '../context/useRestaurant';

export const useAdminFloorPlan = () => {
  const [floorPlan, setFloorPlan] = useState<FloorPlanData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id: restaurantId } = useRestaurant();
  const setError = useError();

  const fetchFloorPlan = (): void => {
    void (async () => {
      try {
        const data = await getFloorPlanForRestaurantId(restaurantId);
        if (isSuccessResponse(data)) setFloorPlan(data);
      } catch (e) {
        setError(e);
      }

      setIsLoading(false);
    })();
  };

  useEffect(() => {
    fetchFloorPlan();
  }, [restaurantId]);

  return { floorPlan, isLoading, fetchFloorPlan };
};
