import cx from 'classnames';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Icon } from '@components/icon/Icon';
import { errorToast } from '@components/toasts/Toasts';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import { getFullName } from '@shared/utils/formatName';
import { appendGuestCountLabel } from '@shared/utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import { type RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import { discardWalkIn, finishWalkIn } from '../../apiHelpers';
import { type ServiceWalkIn } from '../apiHelpers';
import { ServiceStatusSelect } from '../serviceStatus/ServiceStatusSelect';
import { useReservationServiceContext } from '../state/reservationServiceContext';
import { DiscardWalkInConfirmationModal } from './DiscardWalkInConfirmationModal';
import { FinishWalkInConfirmationModal } from './FinishWalkInConfirmationModal';
import styles from './SeatedWalkInSheet.scss';
import { WalkInSheetWarning } from './WalkInSheetWarning';

export interface WalkInSheetProps {
  walkIn: ServiceWalkIn;
  onEnableSeatMode: () => void;
}

export const SeatedWalkInSheet = ({
  walkIn,
  onEnableSeatMode,
}: WalkInSheetProps) => {
  const restaurant = useRestaurant();
  const { refreshFloorPlan, refreshOccupants, resetViewMode } =
    useReservationServiceContext();

  const { seatedTableName } = walkIn;
  const walkInName =
    getFullName(walkIn.firstName, walkIn.lastName) || 'Walk-In';
  const {
    isOpen: isFinishWalkInModalOpen,
    open: openFinishWalkInModal,
    close: closeFinishWalkInModal,
  } = useIsOpen();
  const {
    isOpen: isDiscardWalkInModalOpen,
    open: openDiscardWalkInModal,
    close: closeDiscardWalkInModal,
  } = useIsOpen();
  const [isDiscardWalkInLoading, setIsDiscardWalkInLoading] = useState(false);

  const handleDiscardWalkIn = () => {
    setIsDiscardWalkInLoading(true);
    void (async () => {
      const response = await discardWalkIn({
        restaurantId: restaurant.id,
        walkInId: walkIn.id,
      });
      if (response.ok) {
        refreshOccupants();
        refreshFloorPlan();
        closeDiscardWalkInModal();
        setIsDiscardWalkInLoading(false);
      } else {
        const errorMessage = await getErrorResponseMessage(response);
        errorToast({ message: errorMessage });
        setIsDiscardWalkInLoading(false);
      }
    })();
    resetViewMode();
  };

  const handleFinishWalkIn = async () => {
    const response = await finishWalkIn({
      restaurantId: restaurant.id,
      walkInId: walkIn.id,
    });

    if (response.ok) {
      refreshFloorPlan();
      refreshOccupants();
    } else {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }
    resetViewMode();
  };

  return (
    <div className={styles.container}>
      <WalkInSheetWarning floorPlanTableId={walkIn.seatedTableId} />
      <div className={styles.heading}>
        <div className={styles.walkInIdentifier}>
          <div className={styles.icon}>
            <FeatureFlagOn<RestaurantAdminFeatureFlag>
              element={
                <ServiceStatusSelect
                  value={walkIn.serviceStatus}
                  occupantType="walkIn"
                  occupantId={walkIn.id}
                  onUpdated={() => {
                    refreshFloorPlan();
                    refreshOccupants();
                  }}
                />
              }
              fallback={
                <Icon
                  ariaLabel="walkInNoBackground"
                  name="walkInNoBackground"
                />
              }
              name="serviceStatusFlag"
            />
          </div>
          <h2 className={cx(typography.h5, styles.name)}>{walkInName}</h2>
        </div>
        <p className={(typography.t1, styles.status)}>seated</p>
      </div>
      <div className={styles.subheading}>
        <span className={typography.h6}>
          {ISOTimeTo12HourTime(walkIn.time)}
        </span>
        <span className={typography.t1}>
          {appendGuestCountLabel(walkIn.guestCount)}
        </span>
      </div>
      <div className={cx(typography.t1, styles.seatedTableName)}>
        table #{seatedTableName}
      </div>
      <Button
        label="Finish Walk-in"
        onClick={openFinishWalkInModal}
        variant={ButtonVariants.Primary}
      />
      <FinishWalkInConfirmationModal
        isOpen={isFinishWalkInModalOpen}
        handleFinishWalkIn={handleFinishWalkIn}
        closeModal={closeFinishWalkInModal}
      />
      <Button
        label="Change Seat"
        onClick={onEnableSeatMode}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Discard Walk-in"
        onClick={openDiscardWalkInModal}
        variant={ButtonVariants.Tertiary}
      />
      <DiscardWalkInConfirmationModal
        handleDiscardWalkIn={handleDiscardWalkIn}
        isLoading={isDiscardWalkInLoading}
        isOpen={isDiscardWalkInModalOpen}
        closeModal={closeDiscardWalkInModal}
      />
    </div>
  );
};
