// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HookAwareFormSelect__dropdownIndicator___dXCMr{height:24px;rotate:-90deg;stroke:var(--darkBlue);width:24px}.HookAwareFormSelect__error___cjUNk{color:var(--pink)}.HookAwareFormSelect__labelClassName___IQ71V{align-items:flex-start;display:flex;flex-direction:row;justify-content:space-between;margin-top:0;width:100%}.HookAwareFormSelect__labelClassName___IQ71V>span{font-size:16px;line-height:24px;margin-top:.75rem}.HookAwareFormSelect__selectPlusError___plhxs{display:flex;flex-direction:column;gap:.5rem;width:100%}", "",{"version":3,"sources":["webpack://./src/shared/components/hookAwareFormSelect/HookAwareFormSelect.scss"],"names":[],"mappings":"AAAA,gDACE,WAAA,CACA,aAAA,CACA,sBAAA,CACA,UAAA,CAGF,oCACE,iBAAA,CAGF,6CACE,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,YAAA,CACA,UAAA,CAEA,kDACE,cAAA,CACA,gBAAA,CACA,iBAAA,CAIJ,8CACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownIndicator": "HookAwareFormSelect__dropdownIndicator___dXCMr",
	"error": "HookAwareFormSelect__error___cjUNk",
	"labelClassName": "HookAwareFormSelect__labelClassName___IQ71V",
	"selectPlusError": "HookAwareFormSelect__selectPlusError___plhxs"
};
export default ___CSS_LOADER_EXPORT___;
