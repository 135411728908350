import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { RESERVATIONS_SERVICE_PATH } from '../paths';
import { LoginForm } from './LoginForm';

export const LoginPage = () => {
  const { isLoading, isAuthenticated } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(RESERVATIONS_SERVICE_PATH);
    }
  }, [isLoading, isAuthenticated]);

  return isLoading ? null : <LoginForm />;
};
