import { type Control, useWatch } from 'react-hook-form';
import { ControlledFormEndlessDateInput } from '@components/formInputs/ControlledFormEndlessDateInput';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { todayInTimezone } from '@utils/date';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import type { ListingTimeAndPriceFormData } from '../types';
import styles from './ListingLifeSpanFieldSet.scss';

export interface ListingLifeSpanFieldSetProps {
  control: Control<ListingTimeAndPriceFormData>;
}

export const ListingLifeSpanFieldSet = ({
  control,
}: ListingLifeSpanFieldSetProps) => {
  const { timezone } = useRestaurant();
  const startDate = useWatch({
    control,
    name: 'startDate',
  });

  const todayISO = todayInTimezone(timezone);
  const minimumEndDate = startDate > todayISO ? startDate : todayISO;

  return (
    <fieldset
      className={styles.fieldset}
      data-testid="listing-life-span-fieldset"
    >
      <h3 className={typography.t3}>listing life span</h3>
      <ControlledFormInput
        control={control}
        type="date"
        label="Start Date"
        name="startDate"
        rules={{
          required: true,
        }}
      />
      <ControlledFormEndlessDateInput
        control={control}
        label="End Date"
        name="endDate"
        rules={{
          min: {
            value: minimumEndDate,
            message:
              'End date must be after the Start Date and cannot be in the past.',
          },
        }}
      />
    </fieldset>
  );
};
