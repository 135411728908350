import {
  createContext,
  type ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { createResponseError } from '@shared/api/createResponseError';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import { useRestaurant } from '../../../context/useRestaurant';
import * as apiHelpers from '../apiHelpers';
import {
  canUndoMerge,
  isMergeable,
  isReadyToPersistMerge,
  isSelectedForMerge,
  isStagedForUnmerge,
  isStagedPrimaryMergedTableId,
  isStagedSecondaryMergedTableId,
  isUnmergeable,
} from './mergeUnmergeTablesDerivedState';
import {
  clearMerges,
  mergeUnmergeTablesInitialState,
  mergeUnmergeTablesReducer,
  selectTable,
  stageMerge,
  stageUnMerge,
  undoMerge,
  unSelectTable,
} from './mergeUnmergeTablesReducer';

export interface MergeUnmergeTablesContextState {
  // derived state
  canUndoMerge: boolean;
  isMergeable: boolean;
  isReadyToPersistMerge: boolean;
  isSelectedForMerge: (tableId: string) => boolean;
  isStagedForUnmerge: (tableId: string) => boolean;
  isStagedPrimaryMergedTableId: (tableId: string) => boolean;
  isStagedSecondaryMergedTableId: (tableId: string) => boolean;
  isUnmergeable: boolean;
  // handlers
  clearMergeState: () => void;
  persistMergedTables: () => Promise<void>;
  selectTableForMerge: (table: HostFloorPlanTable) => void;
  stageMerge: () => void;
  stageUnmerge: () => void;
  undoMerge: () => void;
  unselectTableForMerge: (tableId: string) => void;
}

const MergeUnmergeTablesContext = createContext<MergeUnmergeTablesContextState>(
  {} as MergeUnmergeTablesContextState,
);
MergeUnmergeTablesContext.displayName = 'MergeUnmergeTables';

export const useMergeUnmergeTables = (): MergeUnmergeTablesContextState =>
  useContext(MergeUnmergeTablesContext);

export const MergeUnmergeTablesProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    mergeUnmergeTablesReducer,
    mergeUnmergeTablesInitialState,
  );
  const { mergedTables, unMergedTableIds } = state;

  // custom hooks
  const { id: restaurantId } = useRestaurant();

  const persistMergedTables = async () => {
    const response = await apiHelpers.mergeTables(
      restaurantId,
      mergedTables,
      unMergedTableIds,
    );

    if (!response.ok) {
      const errorPayload = await response.json();
      throw createResponseError(errorPayload);
    }
  };

  const value = useMemo<MergeUnmergeTablesContextState>(
    () => ({
      // derived state
      canUndoMerge: canUndoMerge(state),
      isMergeable: isMergeable(state),
      isReadyToPersistMerge: isReadyToPersistMerge(state),
      isSelectedForMerge: isSelectedForMerge(state),
      isStagedForUnmerge: isStagedForUnmerge(state),
      isStagedPrimaryMergedTableId: isStagedPrimaryMergedTableId(state),
      isStagedSecondaryMergedTableId: isStagedSecondaryMergedTableId(state),
      isUnmergeable: isUnmergeable(state),
      // handlers
      clearMergeState: () => dispatch(clearMerges()),
      persistMergedTables,
      selectTableForMerge: (selectedTable: HostFloorPlanTable) =>
        dispatch(selectTable(selectedTable)),
      stageMerge: () => dispatch(stageMerge()),
      stageUnmerge: () => dispatch(stageUnMerge()),
      undoMerge: () => dispatch(undoMerge()),
      unselectTableForMerge: (unSelectedTableId: string) =>
        dispatch(unSelectTable(unSelectedTableId)),
    }),
    [state],
  );

  return (
    <MergeUnmergeTablesContext.Provider value={value}>
      {children}
    </MergeUnmergeTablesContext.Provider>
  );
};
