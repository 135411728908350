import { min } from 'lodash-es';
import type {
  HostFloorPlanTable,
  SeatedGuest,
  SeatedWalkIn,
} from './apiHelpers';

type HostFloorPlanTableWithSeatedWalkIn = HostFloorPlanTable & {
  seatedParty: SeatedWalkIn;
};
type HostFloorPlanTableWithSeatedReservation = HostFloorPlanTable & {
  seatedParty: SeatedGuest;
};
export const isFloorPlanTableSeated = (
  hostFloorPlanTable: HostFloorPlanTable,
): hostFloorPlanTable is
  | HostFloorPlanTableWithSeatedWalkIn
  | HostFloorPlanTableWithSeatedReservation =>
  hostFloorPlanTable.seatedParty !== null;

export const isFloorPlanTableWithSeatedReservation = (
  hostFloorPlanTable: HostFloorPlanTable,
): hostFloorPlanTable is HostFloorPlanTableWithSeatedReservation =>
  isFloorPlanTableSeated(hostFloorPlanTable) &&
  'reservation' in hostFloorPlanTable.seatedParty;

type PersistedMergedTable = HostFloorPlanTable & { mergeTableId: string };

const isPersistedMergedTable = (
  table: HostFloorPlanTable,
): table is PersistedMergedTable => table.mergeTableId !== null;

export const isPersistedSecondaryMergedTable = (
  table: HostFloorPlanTable,
): table is PersistedMergedTable =>
  isPersistedMergedTable(table) && table.mergeTableId !== table.id;

export const isPersistedPrimaryMergedTable = (
  table: HostFloorPlanTable,
): table is PersistedMergedTable =>
  isPersistedMergedTable(table) && table.mergeTableId === table.id;

export const convertToMergeAwareTable = (
  nonSecondaryTable: HostFloorPlanTable,
  allTables: HostFloorPlanTable[],
): HostFloorPlanTable => {
  if (!isPersistedPrimaryMergedTable(nonSecondaryTable))
    return nonSecondaryTable;

  const availableTimesOfMergedTables: (number | null)[] = allTables
    .filter(
      (table) =>
        table.mergeTableId === nonSecondaryTable.id && table.availableTime,
    )
    .map((notPrimaryTable) => notPrimaryTable.availableTime);

  const soonestAvailableTimeAmongMergedTables =
    (availableTimesOfMergedTables.length &&
      min(availableTimesOfMergedTables)) ||
    null;

  return {
    ...nonSecondaryTable,
    availableTime: soonestAvailableTimeAmongMergedTables,
    iconName: 'mergedTable',
    name: nonSecondaryTable.mergeTableName || nonSecondaryTable.name,
    orientation: '0.00',
  };
};

export const convertToMergeAwareTables = (allTables: HostFloorPlanTable[]) =>
  allTables
    .filter((table) => !isPersistedSecondaryMergedTable(table))
    .map((nonSecondaryTable) =>
      convertToMergeAwareTable(nonSecondaryTable, allTables),
    );
