import { type DragEndEvent, useDndMonitor } from '@dnd-kit/core';
import type { FloorPlanTablesRenderer } from '@components/floorPlan/FloorPlan';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { errorToast } from '@components/toasts/Toasts';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import { getFullName } from '@utils/formatName';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  changeReservationSeat,
  seatReservation,
} from 'restaurantAdmin/reservations/apiHelpers';
import { ModalType, useModalContext } from '../../../context/modalContext';
import { useRestaurant } from '../../../context/useRestaurant';
import type { ServiceReservation } from '../apiHelpers';
import { isReservation } from '../apiHelpers';
import { useReservationServiceContext } from '../state/reservationServiceContext';
import { SeatedFloorPlanTableFactory } from './SeatedFloorPlanTableFactory';

export interface SeatedFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  highlightedListingId?: string;
  tables: HostFloorPlanTable[];
  refreshOccupantsAndFloorPlan: () => void;
  exitSeatMode: () => void;
  shouldShowTimers: boolean;
}

export const ReservationSeatModeFloorPlan = ({
  backgroundSrc,
  className,
  highlightedListingId,
  refreshOccupantsAndFloorPlan,
  exitSeatMode,
  tables,
  shouldShowTimers,
}: SeatedFloorPlanProps) => {
  const { id: restaurantId } = useRestaurant();
  const { resetViewMode, selectedOccupant } = useReservationServiceContext();
  const { openModal } = useModalContext();

  const createOrUpdateSeat = async (
    floorPlanTableId: string,
    reservationOccupant: ServiceReservation,
  ): Promise<void> => {
    let response: Response;
    const isSeated = Boolean(reservationOccupant.seatedTableName);
    if (isSeated) {
      response = await changeReservationSeat({
        restaurantId,
        reservationId: reservationOccupant.id,
        floorPlanTableId,
      });
    } else {
      response = await seatReservation({
        restaurantId,
        reservationId: reservationOccupant.id,
        floorPlanTableId,
      });
    }

    if (response.ok) {
      resetViewMode();
      exitSeatMode();
    } else {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }
    refreshOccupantsAndFloorPlan();
  };

  const onSeatReservationOutsideListing = (
    reservationOccupant: ServiceReservation,
    handleConfirm: () => Promise<void>,
  ): void => {
    const guestName = getFullName(
      reservationOccupant.guest.firstName,
      reservationOccupant.guest.lastName,
    );
    openModal(ModalType.SeatOutsideListingConfirmation, {
      handleConfirm,
      guestName,
    });
  };

  const isWithinHighlightedListing = (
    floorPlanTable: HostFloorPlanTable,
  ): boolean =>
    !!highlightedListingId &&
    floorPlanTable.listings.some(
      (listing) => listing.id === highlightedListingId,
    );

  useDndMonitor({
    onDragEnd(event: DragEndEvent) {
      const { active, over } = event;

      const floorPlanTable = over?.data.current
        ?.floorPlanTable as HostFloorPlanTable;
      const reservation = active?.data.current
        ?.reservation as ServiceReservation;

      if (
        over &&
        active &&
        over.data.current?.accepts.includes(active.data.current?.type)
      ) {
        const seatReservationOnDragEnd = () =>
          createOrUpdateSeat(floorPlanTable.id, reservation);
        if (isWithinHighlightedListing(floorPlanTable)) {
          void seatReservationOnDragEnd();
        } else {
          onSeatReservationOutsideListing(
            reservation,
            seatReservationOnDragEnd,
          );
        }
      }
    },
  });

  const handleTableOnClick = (floorPlanTable: HostFloorPlanTable) => {
    if (!isReservation(selectedOccupant)) return;

    const seatReservationOnClick = () =>
      createOrUpdateSeat(floorPlanTable.id, selectedOccupant);
    if (isWithinHighlightedListing(floorPlanTable)) {
      void seatReservationOnClick();
    } else {
      onSeatReservationOutsideListing(selectedOccupant, seatReservationOnClick);
    }
  };

  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) => (
    <>
      {tables.map((floorPlanTable) => (
        <SeatedFloorPlanTableFactory
          floorPlanTable={floorPlanTable}
          calculateIsHighlighted={isWithinHighlightedListing}
          handleTableOnClick={handleTableOnClick}
          key={floorPlanTable.id}
          shouldShowTimers={shouldShowTimers}
          tableIconScale={tableIconScale}
        />
      ))}
    </>
  );

  return (
    <FloorPlan
      backgroundSrc={backgroundSrc}
      className={className}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
    />
  );
};
