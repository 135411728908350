// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MergeUnmergeTablesButton__button___i9FV5{background-color:rgba(0,0,0,0);border:none;color:var(--white);cursor:pointer;padding:0;display:flex;align-items:center}.MergeUnmergeTablesButton__button___i9FV5 svg{margin-right:.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/mergeUnmergeTables/MergeUnmergeTablesButton.scss"],"names":[],"mappings":"AAAA,0CACE,8BAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CACA,SAAA,CACA,YAAA,CACA,kBAAA,CAEA,8CACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "MergeUnmergeTablesButton__button___i9FV5"
};
export default ___CSS_LOADER_EXPORT___;
