import type { Control } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import {
  isCompleteTimeValue,
  parseTimeValues,
} from '@components/formInputs/timeValuesUtils';
import type {
  ListingDetailsFormData,
  ListingTimeAndPriceFormData,
} from '../types';
import { AdminCalendar } from './AdminCalendar';

export interface ControlledAdminCalendarProps {
  className?: string;
  detailsControl: Control<ListingDetailsFormData>;
  timeAndPriceControl: Control<ListingTimeAndPriceFormData>;
}

export const EditListingAdminCalendar = ({
  className,
  detailsControl,
  timeAndPriceControl,
}: ControlledAdminCalendarProps) => {
  const [startDate, endDate, repeat, startTime, endTime] = useWatch({
    control: timeAndPriceControl,
    name: ['startDate', 'endDate', 'repeat', 'startTime', 'endTime'],
  });
  const turnTime = useWatch({
    control: detailsControl,
    name: 'turnTime',
  });

  const isComplete = !!(
    startDate &&
    repeat &&
    repeat.length > 0 &&
    startTime &&
    endTime &&
    isCompleteTimeValue(startTime) &&
    isCompleteTimeValue(endTime)
  );

  const highlightedListing = isComplete
    ? {
        startDate,
        endDate,
        repeat,
        startTime: parseTimeValues(startTime),
        endTime: parseTimeValues(endTime),
        turnTime,
      }
    : undefined;

  return (
    <AdminCalendar
      className={className}
      highlightedListing={highlightedListing}
      interactive={false}
    />
  );
};
