import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { isErrorResponse, isSuccessResponse } from '@shared/types/apiHelpers';
import {
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
} from 'restaurantAdmin/paths';
import {
  checkListingHasConflicts,
  type Listing,
  publishListing,
} from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import styles from './PublishListingConfirmationModal.scss';

export interface PublishListingConfirmationModalProps {
  closeModal: () => void;
  isOpen: boolean;
  listing: Listing;
}

export const PublishListingConfirmationModal = ({
  closeModal,
  isOpen,
  listing,
}: PublishListingConfirmationModalProps) => {
  const [hasConflicts, setHasConflicts] = useState<boolean>(false);

  const { isOnFloorPlan, setSelectedListingId, setSelectedDate } =
    useListingsContext();
  const navigate = useNavigate();

  useAbortEffect(
    async (signal) => {
      if (!isOpen) {
        return;
      }

      const response = await checkListingHasConflicts(
        listing.restaurantId,
        listing.id,
        signal,
      );
      if (isSuccessResponse(response)) {
        setHasConflicts(response.hasConflicts);
      }
    },
    [isOpen],
  );

  const onConfirmPublishListing = () => {
    void (async () => {
      const response = await publishListing(listing.restaurantId, listing.id);
      if (isErrorResponse(response)) {
        errorToast({ message: response.message });
      } else {
        setSelectedListingId(listing.id);
        setSelectedDate(listing.startDate);
        navigate(
          isOnFloorPlan
            ? OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH
            : OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
        );
      }
    })();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Publish Listing"
      subtitle="Publishing this listing will make it Live, meaning guests can make reservations for this listing immediately."
    >
      {hasConflicts && (
        <p className={styles.warning}>
          Warning: The inventory for this listing overlaps with that of another
          listing. This allows double-booking of one or more tables to occur.
          Are you sure you want to proceed?
        </p>
      )}
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Publish Listing"
          onClick={onConfirmPublishListing}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
