import cx from 'classnames';
import type { E164Number } from 'libphonenumber-js';
import Input, { type Country } from 'react-phone-number-input';
import styles from './PhoneNumberInput.scss';
import 'react-phone-number-input/style.css';

export const DEFAULT_COUNTRY: Country = 'US';

interface PhoneNumberInputProps {
  className?: string;
  error?: string;
  label?: string;
  name: string;
  onChange: (value?: E164Number) => void;
}

/**
 * @deprecated use <ControlledFormPhoneInput /> instead
 */
export const PhoneNumberInput = ({
  className = '',
  error = '',
  label = '',
  name,
  onChange,
}: PhoneNumberInputProps) => (
  <div className={styles.inputGroup}>
    {label && (
      <label htmlFor={name} className={styles.inputGroupLabel}>
        {label}
      </label>
    )}
    <div className={cx(styles.inputContainer, error && styles.inputError)}>
      <Input
        data-testid="phone-input"
        data-1p-ignore
        data-lpignore
        autoComplete="false"
        className={cx(styles.input, className)}
        defaultCountry={DEFAULT_COUNTRY}
        id={name}
        international
        name={name}
        onChange={onChange}
      />
    </div>
    <p aria-live="polite" className={styles.error} role="region">
      {error}
    </p>
  </div>
);
