// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FinanceCard__container___HrRz_{display:grid;grid-template-columns:auto 1fr 1fr;margin-top:32px;background-color:var(--secondary300);border-radius:4px;padding:24px;max-width:400px;align-items:center;column-gap:8px}.FinanceCard__container___HrRz_ dt{color:var(--primary100);white-space:nowrap}.FinanceCard__container___HrRz_ dd{display:flex;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/shared/components/financeCard/FinanceCard.scss"],"names":[],"mappings":"AAAA,gCACE,YAAA,CACA,kCAAA,CACA,eAAA,CACA,oCAAA,CACA,iBAAA,CACA,YAAA,CACA,eAAA,CACA,kBAAA,CACA,cAAA,CAEA,mCACE,uBAAA,CACA,kBAAA,CAGF,mCACE,YAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FinanceCard__container___HrRz_"
};
export default ___CSS_LOADER_EXPORT___;
