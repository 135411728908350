import { Button, ButtonVariants } from '@components/button/Button';
import { ModalType, useModalContext } from '../../context/modalContext';
import styles from './OccupantsPage.scss';
import { useCloseRestaurant } from './pageHeader/useCloseRestaurant';

export const OpenCloseRestaurantButton = () => {
  const { openModal, closeModal } = useModalContext();
  const {
    canCloseRestaurant,
    canOpenRestaurant,
    closeRestaurant,
    openRestaurant,
  } = useCloseRestaurant();

  const handleCloseRestaurant = () =>
    openModal(ModalType.CloseRestaurant, {
      onConfirm: async () => {
        await closeRestaurant();
        closeModal();
      },
    });

  const handleOpenRestaurant = () =>
    openModal(ModalType.OpenRestaurant, {
      onConfirm: async () => {
        await openRestaurant();
        closeModal();
      },
    });

  return (
    <>
      {canCloseRestaurant && (
        <Button
          className={styles.closeOrOpenRestaurantButton}
          label="Close Restaurant"
          onClick={handleCloseRestaurant}
          type="submit"
          variant={ButtonVariants.Tertiary}
        />
      )}
      {canOpenRestaurant && (
        <Button
          className={styles.closeOrOpenRestaurantButton}
          label="Open Restaurant"
          onClick={handleOpenRestaurant}
          type="submit"
          variant={ButtonVariants.Tertiary}
        />
      )}
    </>
  );
};
