// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationGuestSheetHeader__container___VmIuK{align-items:center;display:flex;flex-direction:column;gap:8px;justify-content:center}.ReservationGuestSheetHeader__heading___gBIQQ{align-items:center;display:flex;justify-content:space-between;width:100%}.ReservationGuestSheetHeader__heading___gBIQQ .ReservationGuestSheetHeader__guestIdentifiers___X2tfi{align-items:center;display:flex;justify-content:flex-start}.ReservationGuestSheetHeader__heading___gBIQQ .ReservationGuestSheetHeader__guestIdentifiers___X2tfi .ReservationGuestSheetHeader__guestName___XMSZk{justify-content:flex-start;margin-left:1rem;max-width:13rem;text-transform:capitalize}.ReservationGuestSheetHeader__heading___gBIQQ .ReservationGuestSheetHeader__status___BBL4t{margin:0}.ReservationGuestSheetHeader__subheading___AIhMW{align-items:center;display:flex;justify-content:space-between;text-transform:lowercase;width:100%}.ReservationGuestSheetHeader__subheading___AIhMW span:last-child{color:var(--white70);text-transform:capitalize}.ReservationGuestSheetHeader__seatedTableName___Wuuly{width:100%;text-align:left;color:var(--white70);text-transform:uppercase}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/ReservationGuestSheetHeader.scss"],"names":[],"mappings":"AAAA,gDACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CACA,sBAAA,CAGF,8CACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,UAAA,CAEA,qGACE,kBAAA,CACA,YAAA,CACA,0BAAA,CAEA,qJACE,0BAAA,CACA,gBAAA,CACA,eAAA,CACA,yBAAA,CAIJ,2FACE,QAAA,CAIJ,iDACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,wBAAA,CACA,UAAA,CAEA,iEACE,oBAAA,CACA,yBAAA,CAIJ,sDACE,UAAA,CACA,eAAA,CACA,oBAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationGuestSheetHeader__container___VmIuK",
	"heading": "ReservationGuestSheetHeader__heading___gBIQQ",
	"guestIdentifiers": "ReservationGuestSheetHeader__guestIdentifiers___X2tfi",
	"guestName": "ReservationGuestSheetHeader__guestName___XMSZk",
	"status": "ReservationGuestSheetHeader__status___BBL4t",
	"subheading": "ReservationGuestSheetHeader__subheading___AIhMW",
	"seatedTableName": "ReservationGuestSheetHeader__seatedTableName___Wuuly"
};
export default ___CSS_LOADER_EXPORT___;
