import cx from 'classnames';
import { has } from 'lodash-es';
import type {
  Control,
  FieldErrors,
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseFormRegister,
} from 'react-hook-form';
import type { SetTooltipIsOpenDispatch } from '@components/formInputTooltip/FormInputTooltip';
import { ValidationErrorMessage } from '@components/validationErrorMessage/ValidationErrorMessage';
import typography from '~styles/typography.scss';
import styles from './HookAwareTextarea.scss';

const DEFAULT_MAX_LENGTH = 500;

export interface HookAwareTextareaProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  control: Control<T>;
  errors: FieldErrors;
  label: string;
  maxLength?: number;
  name: Name;
  isDisabled?: boolean;
  register: UseFormRegister<T>;
  setTooltipIsOpen?: SetTooltipIsOpenDispatch;
  validations?: UseControllerProps<T, Name>['rules'];
}

export const HookAwareTextarea = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  errors,
  label,
  maxLength = DEFAULT_MAX_LENGTH,
  name,
  register,
  validations,
}: HookAwareTextareaProps<T, Name>) => (
  <div className={styles.fieldContainer}>
    <div className={styles.labelContainer}>
      <label
        htmlFor={name}
        className={cx({
          [typography.c2_20]: true,
          [styles.labelError]: has(errors, name),
        })}
      >
        {label}
      </label>
    </div>
    <div className={styles.inputContainer}>
      <textarea
        aria-errormessage={`${name}-error`}
        aria-invalid={has(errors, name)}
        id={name}
        className={cx({
          [typography.t1]: true,
          [styles.inputError]: has(errors, name),
        })}
        maxLength={maxLength}
        {...register(name, validations)}
      />
      <ValidationErrorMessage errors={errors} label={label} name={name} />
    </div>
  </div>
);
