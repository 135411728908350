// to be removed when serviceStatusFlag is removed
import { forwardRef } from 'react';
import type { TableIconCommonProps } from '@components/floorPlan/InteractiveFloorPlanTable';
import {
  calculateContainerStyle,
  calculateIconStyle,
} from '@components/floorPlan/utils';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import { getAnonymousName } from '@shared/utils/formatName';
import type { SeatedWalkIn } from '../../../floorPlans/apiHelpers';
import styles from './SeatedWalkInFloorPlanTable.scss';

export interface SeatedWalkInFloorPlanTableProps extends TableIconCommonProps {
  seatedWalkIn: SeatedWalkIn;
  tableName: string;
  isDisabled: boolean;
  isHighlighted: boolean;
  handleTableOnClick?: () => void;
}

export const SeatedWalkInFloorPlanTable = forwardRef<
  HTMLDivElement,
  SeatedWalkInFloorPlanTableProps
>(
  (
    {
      handleTableOnClick,
      iconName,
      iconWidthScale,
      isDisabled,
      isHighlighted,
      left,
      orientation,
      seatedWalkIn,
      tableIconScale,
      tableName,
      testId,
      top,
    },
    ref,
  ) => {
    const fullName = getAnonymousName(
      seatedWalkIn.firstName,
      seatedWalkIn.lastName,
    );
    const tooltip = `${fullName} seated at table ${tableName}, party of ${seatedWalkIn.guestCount}`;

    const iconSuffix = () => {
      if (isHighlighted) return 'Selected';
      return '';
    };
    const newIconName = `${iconName}${iconSuffix()}` as AnyIcon;

    return (
      <div
        className={styles.container}
        data-testid={testId}
        ref={ref}
        style={calculateContainerStyle({
          left,
          tableIconScale,
          top,
        })}
        title={tooltip}
      >
        <button
          aria-label={`View details for table ${tableName}`}
          className={styles.button}
          disabled={isDisabled}
          onClick={handleTableOnClick}
          type="button"
        >
          <Icon name="walkIn" className={styles.icon} />
          <Icon
            name={newIconName}
            style={calculateIconStyle({
              iconWidthScale,
              orientation,
              tableIconScale,
            })}
            testId={`icon-${newIconName}`}
          />
        </button>
      </div>
    );
  },
);

SeatedWalkInFloorPlanTable.displayName = 'SeatedWalkInFloorPlanTable';
