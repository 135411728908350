import { useSwitch } from '@mui/base/useSwitch';
import cx from 'classnames';
import typography from '~styles/typography.scss';
import styles from './UpcomingTimerSwitch.scss';

export const UpcomingTimerSwitch = (
  props: React.InputHTMLAttributes<HTMLInputElement>,
) => {
  const { getInputProps, checked, focusVisible } = useSwitch(props);

  return (
    <label className={cx(styles.label, typography.h7)}>
      Show Upcoming Timers
      <div
        className={cx({
          [styles.switchTrack]: true,
          [styles.checked]: checked,
          [styles.focused]: focusVisible,
        })}
        data-testid="switch-track"
      >
        <span
          className={cx({
            [styles.switchThumb]: true,
            [styles.checked]: checked,
          })}
          data-testid="switch-thumb"
        />
        <input
          className={styles.switchInput}
          role="switch"
          {...getInputProps()}
          type="checkbox"
        />
      </div>
    </label>
  );
};
