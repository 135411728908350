import cx from 'classnames';
import { Avatar } from '@components/avatar/Avatar';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { getFullName } from '@utils/formatName';
import { appendGuestCountLabel } from '@utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import { isReservationFinished } from 'restaurantAdmin/reservations/reservationUtils';
import typography from '~styles/typography.scss';
import { type RestaurantAdminFeatureFlag } from '../../../featureFlags';
import type { ReservationsApiGuest, ServiceReservation } from '../apiHelpers';
import { ServiceStatusSelect } from '../serviceStatus/ServiceStatusSelect';
import { useReservationServiceContext } from '../state/reservationServiceContext';
import styles from './ReservationGuestSheetHeader.scss';

export interface ReservationGuestSheetHeaderProps {
  guest: ReservationsApiGuest;
  reservation: ServiceReservation;
}

export const ReservationGuestSheetHeader = ({
  guest: { firstName, lastName },
  reservation: { id, guestCount, seatedTableName, serviceStatus, status, time },
}: ReservationGuestSheetHeaderProps) => {
  const { refreshFloorPlan, refreshOccupants } = useReservationServiceContext();
  const guestFullName = getFullName(firstName, lastName);
  const guestCountText = appendGuestCountLabel(guestCount);

  const isFinished = isReservationFinished(status);

  return (
    <header className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.guestIdentifiers}>
          <FeatureFlagOn<RestaurantAdminFeatureFlag>
            element={
              <ServiceStatusSelect
                occupantType="reservation"
                occupantId={id}
                onUpdated={() => {
                  refreshFloorPlan();
                  refreshOccupants();
                }}
                value={serviceStatus}
              />
            }
            fallback={<Avatar firstName={firstName} lastName={lastName} />}
            name="serviceStatusFlag"
          />
          <h2 className={cx(typography.h5, styles.guestName)}>
            {guestFullName}
          </h2>
        </div>
        {!isFinished && seatedTableName && (
          <p className={cx(typography.t1, styles.status)}>Seated</p>
        )}
        {isFinished && (
          <p className={cx(typography.t1, styles.status)}>Finished</p>
        )}
      </div>
      <div className={styles.subheading}>
        <span className={typography.h6}>{ISOTimeTo12HourTime(time)}</span>
        <span className={typography.t1}>{guestCountText}</span>
      </div>
      {seatedTableName && (
        <div className={cx(typography.t1, styles.seatedTableName)}>
          table #{seatedTableName}
        </div>
      )}
    </header>
  );
};
