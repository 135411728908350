import { useState } from 'react';
import type { FloorPlanTablesRenderer } from '@components/floorPlan/FloorPlan';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { errorToast } from '@components/toasts/Toasts';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import {
  getErrorResponseMessage,
  isSuccessResponse,
} from '@shared/types/apiHelpers';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  changeWalkInSeat,
  getWalkInAvailability,
  type WalkInAvailability,
} from '../../apiHelpers';
import { WalkInSeatingConflictModal } from '../sidePanel/WalkInSeatingConflictModal';
import { WalkInTurnTimeInformationModal } from '../sidePanel/WalkInTurnTimeInformationModal';
import { useReservationServiceContext } from '../state/reservationServiceContext';
import { SeatedFloorPlanTableFactory } from './SeatedFloorPlanTableFactory';

export interface WalkInSeatModeFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  refreshOccupantsAndFloorPlan: () => void;
  exitSeatMode: () => void;
  tables: HostFloorPlanTable[];
  shouldShowTimers: boolean;
}

interface ChangeWalkInSeatState {
  hostFloorPlanTable: HostFloorPlanTable;
  walkInAvailability: WalkInAvailability;
}

export const WalkInSeatModeFloorPlan = ({
  backgroundSrc,
  className,
  refreshOccupantsAndFloorPlan,
  exitSeatMode,
  tables,
  shouldShowTimers,
}: WalkInSeatModeFloorPlanProps) => {
  const {
    isOpen: isSeatingConflictModalOpen,
    close: closeSeatingConflictModal,
    open: openSeatingConflictModal,
  } = useIsOpen();
  const {
    isOpen: isTurnTimeInfoModalOpen,
    close: closeTurnTimeInfoModal,
    open: openTurnTimeInfoModal,
  } = useIsOpen();
  const restaurant = useRestaurant();
  const { resetViewMode, selectedOccupant } = useReservationServiceContext();
  const [changeSeatState, setChangeSeatState] =
    useState<ChangeWalkInSeatState>();

  if (!selectedOccupant) return null;

  const changeSeat = async (changeWalkInSeatData: ChangeWalkInSeatState) => {
    if (!changeWalkInSeatData) return;

    const response = await changeWalkInSeat({
      walkInId: selectedOccupant.id,
      restaurantId: restaurant.id,
      floorPlanTableId: changeWalkInSeatData.hostFloorPlanTable.id,
      turnTime: changeWalkInSeatData.walkInAvailability.turnTime,
      hasAvailability: changeWalkInSeatData.walkInAvailability.hasAvailability,
    });

    if (response.ok) {
      resetViewMode();
      exitSeatMode();
    } else {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }
    refreshOccupantsAndFloorPlan();
  };

  const onChangeWalkInSeating = async (
    hostFloorPlanTable: HostFloorPlanTable,
  ) => {
    const response = await getWalkInAvailability({
      floorPlanTableId: hostFloorPlanTable.id,
      guestCount: selectedOccupant.guestCount,
      restaurantId: restaurant.id,
    });
    if (isSuccessResponse(response)) {
      setChangeSeatState({ hostFloorPlanTable, walkInAvailability: response });

      if (!response.hasAvailability) {
        openSeatingConflictModal();
        return;
      }

      if (response.turnTime !== null) {
        openTurnTimeInfoModal();
        return;
      }

      await changeSeat({
        hostFloorPlanTable,
        walkInAvailability: response,
      });
    } else {
      errorToast({
        message: 'Failed to confirm seat availability. Please try again.',
      });
    }
  };

  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) => (
    <>
      {tables.map((floorPlanTable) => (
        <SeatedFloorPlanTableFactory
          floorPlanTable={floorPlanTable}
          handleTableOnClick={onChangeWalkInSeating}
          key={floorPlanTable.id}
          shouldShowTimers={shouldShowTimers}
          tableIconScale={tableIconScale}
        />
      ))}
    </>
  );

  return (
    <>
      {changeSeatState && (
        <WalkInSeatingConflictModal
          isOpen={isSeatingConflictModalOpen}
          closeModal={closeSeatingConflictModal}
          handleConfirm={() => {
            void changeSeat(changeSeatState);
          }}
        />
      )}
      {changeSeatState && changeSeatState.walkInAvailability.turnTime && (
        <WalkInTurnTimeInformationModal
          isOpen={isTurnTimeInfoModalOpen}
          closeModal={closeTurnTimeInfoModal}
          handleConfirm={() => {
            void changeSeat(changeSeatState);
          }}
          turnTime={changeSeatState.walkInAvailability.turnTime}
        />
      )}
      <FloorPlan
        backgroundSrc={backgroundSrc}
        className={className}
        floorPlanTablesRenderer={floorPlanTablesRenderer}
      />
    </>
  );
};
