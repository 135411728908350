import { useDraggable } from '@dnd-kit/core';
import cx from 'classnames';
import { add, isAfter } from 'date-fns';
import { type MouseEventHandler } from 'react';
import { Avatar } from '@components/avatar/Avatar';
import { Icon } from '@components/icon/Icon';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { toUTCDateTime } from '@utils/date';
import { getFullName } from '@utils/formatName';
import { ISOTimeTo12HourTime } from '@utils/time';
import type { Listing } from 'restaurantAdmin/operations/listings/apiHelpers';
import typography from '~styles/typography.scss';
import { Status, StatusTag } from '../../../components/statusTag/StatusTag';
import { usePublishedListings } from '../../../context/publishedListingsContext';
import { useRestaurant } from '../../../context/useRestaurant';
import { type RestaurantAdminFeatureFlag } from '../../../featureFlags';
import { type RestaurantMetadata } from '../../../restaurants/apiHelpers';
import type { ServiceReservation } from '../apiHelpers';
import { ServiceStatusSelect } from '../serviceStatus/ServiceStatusSelect';
import styles from './ActiveReservationCard.scss';

interface ReservationCardProps {
  isSelected?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onUpdated: () => void;
  reservation: ServiceReservation;
}

export const ActiveReservationCard = ({
  isSelected = false,
  onClick,
  onUpdated,
  reservation,
}: ReservationCardProps) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    data: {
      reservation,
      type: 'reservation',
    },
    id: reservation.id,
  });

  const restaurant = useRestaurant();
  const { listings } = usePublishedListings();

  const {
    guest,
    guestCount,
    hasDietaryRestrictionOrAllergy,
    hasNote,
    isStranded,
    isVIP,
    seatedTableName,
    serviceStatus,
    time,
  } = reservation;

  const isHostBooked = !!reservation.transactionDetails.createdBy.fullName;
  const isSeated = seatedTableName;

  const isOverTime =
    isSeated && calculateIsOverTime(reservation, restaurant, listings);

  const showCardFooter = isStranded || isOverTime;

  return (
    <li
      aria-current={isSelected}
      aria-label={`reservation card for ${guest.firstName} ${guest.lastName}`}
      className={styles.card}
    >
      <FeatureFlagOn<RestaurantAdminFeatureFlag>
        element={
          <ServiceStatusSelect
            occupantType="reservation"
            occupantId={reservation.id}
            onUpdated={onUpdated}
            value={serviceStatus}
          />
        }
        fallback={<span />}
        name="serviceStatusFlag"
      />
      <button
        aria-label="open details"
        className={styles.button}
        onClick={onClick}
        type="button"
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        <div className={styles.avatarAndInfoStack}>
          <FeatureFlagOn<RestaurantAdminFeatureFlag>
            element={<span />}
            fallback={
              <Avatar
                className={styles.avatar}
                firstName={guest.firstName}
                lastName={guest.lastName}
              />
            }
            name="serviceStatusFlag"
          />
          <div className={styles.infoContainer}>
            <div className={styles.timeAndStatusRow}>
              <div className={cx(typography.h8, styles.time)}>
                {ISOTimeTo12HourTime(time)}
                {isVIP && (
                  <Icon
                    ariaLabel="Is a VIP"
                    className={cx(styles.vipIcon)}
                    name="crown"
                    role="img"
                  />
                )}
                {isHostBooked && (
                  <Icon
                    ariaLabel="Host booked"
                    className={styles.fillIcon}
                    name="roomService"
                    role="img"
                  />
                )}
                {hasNote && (
                  <Icon
                    ariaLabel="Has notes"
                    className={cx(styles.strokeIcon, styles.hasNoteIcon)}
                    name="note"
                    role="img"
                  />
                )}
                {hasDietaryRestrictionOrAllergy && (
                  <Icon
                    ariaLabel="Has dietary restriction or allergy"
                    name="cutlery"
                    role="img"
                  />
                )}
              </div>
              {isSeated && (
                <div className={cx(typography.t3, styles.status)}>Seated</div>
              )}
            </div>
            <div className={styles.nameAndGuestCountRow}>
              <div className={styles.userFullName}>
                {getFullName(guest.firstName, guest.lastName)}
              </div>
              <div
                aria-label={`${guestCount} guests`}
                className={styles.guestCount}
              >
                <Icon name="couple" /> {guestCount}
              </div>
            </div>
          </div>
        </div>
        {showCardFooter && (
          <div className={styles.cardFooter}>
            {isOverTime && <StatusTag status={Status.Overtime} />}
            {isStranded && <StatusTag status={Status.Stranded} />}
          </div>
        )}
      </button>
    </li>
  );
};

const calculateIsOverTime = (
  reservation: ServiceReservation,
  restaurant: RestaurantMetadata,
  listings: Listing[],
) => {
  const { timezone } = restaurant;

  const { turnTime } =
    listings.find((listing) => reservation.listingId === listing.id) ||
    ({} as Listing);
  if (!turnTime) {
    return false;
  }

  const reservationDateTimeUTC = toUTCDateTime(
    reservation.date,
    reservation.time,
    timezone,
  );

  const endOfTurn = add(reservationDateTimeUTC, {
    minutes: turnTime,
  });

  return isAfter(new Date(), endOfTurn);
};
