import type { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { omit } from 'lodash-es';
import {
  createContext,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { AnyIcon } from '@components/icon/Icon';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { isErrorResponse } from '@shared/types/apiHelpers';
import type { FloorPlanData } from '@shared/types/floorPlans';
import { useRestaurant } from '../context/useRestaurant';
import { useAdminFloorPlan } from '../hooks/useAdminFloorPlan';
import { saveEdit } from './apiHelpers';

export interface FloorPlanEditorTable {
  iconName: AnyIcon;
  iconWidthScale: string;
  id: string;
  interval: number;
  left: string;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  orientation: string;
  top: string;
  turnTime: number;
}

interface FloorPlanEditorContextProps {
  children: ReactNode;
}

export interface FloorPlanEditorContextValues {
  dirtyAndNewFloorPlanTables: FloorPlanEditorTable[];
  floorPlan: FloorPlanData | null;
  selectedFloorPlanTable?: FloorPlanEditorTable;
  setSelectedFloorPlanTableId: (id: string) => void;
  handleOnAdd: (_: any, value: any) => void;
  handleOnChange: (event: any) => void;
  handleOnChangeIconName: (_: any, value: any) => void;
  handleOnDelete: () => void;
  handleOnReset: () => void;
  handleOnSave: () => void;
}

const FloorPlanEditorContext = createContext<FloorPlanEditorContextValues>(
  {} as FloorPlanEditorContextValues,
);
FloorPlanEditorContext.displayName = 'Drawer';

export const useFloorPlanEditorContext = () =>
  useContext(FloorPlanEditorContext);

export const FloorPlanEditorContextProvider = ({
  children,
}: FloorPlanEditorContextProps) => {
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: { delay: 500, tolerance: 5 },
  });
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { delay: 500, tolerance: 5 },
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const { floorPlan, fetchFloorPlan } = useAdminFloorPlan();
  const { id: restaurantId } = useRestaurant();

  const [updatedFloorPlanTables, setUpdatedFloorPlanTables] = useState<
    Record<string, FloorPlanEditorTable>
  >({});
  const [createdFloorPlanTables, setCreatedFloorPlanTables] = useState<
    FloorPlanEditorTable[]
  >([]);
  const [deletedFloorPlanTableIds, setDeletedFloorPlanTableIds] = useState<
    string[]
  >([]);
  const [selectedFloorPlanTableId, setSelectedFloorPlanTableId] =
    useState<string>();

  const dirtyFloorPlanTables = floorPlan
    ? floorPlan.floorPlanTables
        .filter((table) => !deletedFloorPlanTableIds.includes(table.id))
        .map((table) => {
          const updatedTable = updatedFloorPlanTables[table.id];
          return updatedTable ? { ...table, ...updatedTable } : table;
        })
    : [];
  const dirtyAndNewFloorPlanTables = [
    ...dirtyFloorPlanTables,
    ...createdFloorPlanTables,
  ];

  const selectedFloorPlanTable = dirtyAndNewFloorPlanTables.find(
    (table) => table.id === selectedFloorPlanTableId,
  );

  const isNewFloorPlanTable = selectedFloorPlanTableId?.includes('new-');

  const handleOnReset = () => {
    setUpdatedFloorPlanTables({});
    setSelectedFloorPlanTableId(undefined);
    setDeletedFloorPlanTableIds([]);
    setCreatedFloorPlanTables([]);
  };
  const handleOnSave = async () => {
    if (!floorPlan) return;
    if (window.confirm("it's gon' save")) {
      const createdFloorPlanTablesWithoutId = createdFloorPlanTables.map(
        (table) => omit(table, 'id'),
      );

      const response = await saveEdit(restaurantId, floorPlan.id, {
        createdFloorPlanTables: createdFloorPlanTablesWithoutId,
        updatedFloorPlanTables: Object.values(updatedFloorPlanTables),
        deletedFloorPlanTableIds,
      });
      if (isErrorResponse(response)) {
        errorToast({ message: response.message });
      } else {
        successToast({ message: 'we did it' });
      }
    }
    handleOnReset();
    await fetchFloorPlan();
  };
  const handleOnDragEnd = (event: DragEndEvent) => {
    const floorPlanTableBeingDragged: FloorPlanEditorTable = event.active.data
      .current as FloorPlanEditorTable;
    const movedFloorPlanTable = {
      ...floorPlanTableBeingDragged,
      top: String(Number(floorPlanTableBeingDragged.top) + event.delta.y),
      left: String(Number(floorPlanTableBeingDragged.left) + event.delta.x),
    };
    if (String(event.active.id).includes('new-')) {
      const movedNewFloorPlanTables = createdFloorPlanTables.map((table) =>
        table.id === event.active.id ? movedFloorPlanTable : table,
      );
      setCreatedFloorPlanTables(movedNewFloorPlanTables);
    } else {
      setUpdatedFloorPlanTables({
        ...updatedFloorPlanTables,
        ...{ [event.active.id]: movedFloorPlanTable },
      });
    }
  };
  const handleOnDelete = () => {
    if (selectedFloorPlanTableId === undefined) return;

    if (isNewFloorPlanTable) {
      setCreatedFloorPlanTables(
        createdFloorPlanTables.filter(
          (table) => table.id !== selectedFloorPlanTableId,
        ),
      );
    } else {
      setDeletedFloorPlanTableIds([
        ...deletedFloorPlanTableIds,
        selectedFloorPlanTableId,
      ]);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [selectedFloorPlanTableId]: _, ...rest } = updatedFloorPlanTables;
      setUpdatedFloorPlanTables(rest);
    }

    setSelectedFloorPlanTableId(undefined);
  };
  const handleOnAdd = (_: any, value: any) => {
    const newTable: FloorPlanEditorTable = {
      iconName: value as AnyIcon,
      iconWidthScale: '1.00',
      id: `new-${Date.now()}`,
      interval: 15,
      left: '40.00',
      maximumGuests: 1,
      minimumGuests: 1,
      name: 'New Table',
      orientation: '0.00',
      top: '40.00',
      turnTime: 90,
    };

    setCreatedFloorPlanTables([...createdFloorPlanTables, newTable]);
  };
  const handleOnChangeIconName = (_: any, value: any) => {
    if (!selectedFloorPlanTable || !selectedFloorPlanTableId) return;

    if (isNewFloorPlanTable) {
      const updatedNewFloorPlanTables = createdFloorPlanTables.map((table) =>
        table.id === selectedFloorPlanTableId
          ? { ...selectedFloorPlanTable, iconName: value as AnyIcon }
          : table,
      );
      setCreatedFloorPlanTables(updatedNewFloorPlanTables);
    } else {
      setUpdatedFloorPlanTables({
        ...updatedFloorPlanTables,
        ...{
          [selectedFloorPlanTableId]: {
            ...selectedFloorPlanTable,
            iconName: value as AnyIcon,
          },
        },
      });
    }
  };
  const handleOnChange = (event: any) => {
    if (!selectedFloorPlanTable || !selectedFloorPlanTableId) return;

    if (isNewFloorPlanTable) {
      const updatedNewFloorPlanTables = createdFloorPlanTables.map((table) =>
        table.id === selectedFloorPlanTableId
          ? {
              ...selectedFloorPlanTable,
              [event.target.name]: event.target.value,
            }
          : table,
      );
      setCreatedFloorPlanTables(updatedNewFloorPlanTables);
    } else {
      setUpdatedFloorPlanTables({
        ...updatedFloorPlanTables,
        ...{
          [selectedFloorPlanTableId]: {
            ...selectedFloorPlanTable,
            [event.target.name]: event.target.value,
          },
        },
      });
    }
  };

  const value = useMemo<FloorPlanEditorContextValues>(
    () => ({
      dirtyAndNewFloorPlanTables,
      floorPlan,
      selectedFloorPlanTable,
      handleOnAdd,
      handleOnChange,
      handleOnChangeIconName,
      handleOnDelete,
      handleOnReset,
      handleOnSave,
      setSelectedFloorPlanTableId,
    }),
    [dirtyAndNewFloorPlanTables, floorPlan, selectedFloorPlanTable],
  );

  return (
    <FloorPlanEditorContext.Provider value={value}>
      <DndContext onDragEnd={handleOnDragEnd} sensors={sensors}>
        {children}
      </DndContext>
    </FloorPlanEditorContext.Provider>
  );
};
