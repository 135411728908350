import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { collectCancellationFee } from '../apiHelpers';
import { useOccupantContext } from '../state/occupantContext';

export interface CollectFeeConfirmationModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const CollectFeeConfirmationModal = ({
  isOpen,
  closeModal,
}: CollectFeeConfirmationModalProps) => {
  const { selectedOccupant, refreshOccupants } = useOccupantContext();
  const restaurant = useRestaurant();
  const [isCollectingFeePending, setIsCollectingFeePending] = useState(false);

  if (!selectedOccupant) return null;

  const handleConfirm = () => {
    void (async () => {
      setIsCollectingFeePending(true);
      const response = await collectCancellationFee(
        restaurant.id,
        selectedOccupant.id,
      );
      if (response.ok) {
        successToast({
          message:
            "Success: The guest's reservation cancellation fee has been collected.",
        });
      } else {
        const errorMessage = await getErrorResponseMessage(response);
        errorToast({ message: errorMessage });
      }
      setIsCollectingFeePending(false);
      closeModal();
      refreshOccupants();
    })();
  };

  return (
    <Modal
      ariaLabel="Confirm Fee Collection"
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you would like to collect a cancellation fee for this
        reservation?"
    >
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Confirm"
          onClick={handleConfirm}
          isDisabled={isCollectingFeePending}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
