// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditListingTimeAndPricePageBody__main___LVTm0{display:flex;gap:2.5rem}.EditListingTimeAndPricePageBody__main___LVTm0 .EditListingTimeAndPricePageBody__displayPanel___WPYdv{display:flex;flex-direction:column;max-width:64%;width:100%}.EditListingTimeAndPricePageBody__main___LVTm0 .EditListingTimeAndPricePageBody__displayPanel___WPYdv .EditListingTimeAndPricePageBody__calendar___PYR1z{height:100%}.EditListingTimeAndPricePageBody__main___LVTm0 .EditListingTimeAndPricePageBody__sidePanelContainer___H34N_{display:flex;flex-direction:column;max-width:34%;width:100%}.EditListingTimeAndPricePageBody__main___LVTm0 .EditListingTimeAndPricePageBody__sidePanelContainer___H34N_ .EditListingTimeAndPricePageBody__sidePanel___UmtHJ{flex:1 0 0;display:flex;flex-direction:column;row-gap:1rem;overflow:auto}.EditListingTimeAndPricePageBody__main___LVTm0 .EditListingTimeAndPricePageBody__sidePanelContainer___H34N_ .EditListingTimeAndPricePageBody__sidePanel___UmtHJ h2 span{display:block}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/edit/EditListingTimeAndPricePageBody.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,UAAA,CAEA,sGACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,UAAA,CAEA,yJACE,WAAA,CAIJ,4GACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,UAAA,CAEA,gKACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,aAAA,CAGE,wKACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "EditListingTimeAndPricePageBody__main___LVTm0",
	"displayPanel": "EditListingTimeAndPricePageBody__displayPanel___WPYdv",
	"calendar": "EditListingTimeAndPricePageBody__calendar___PYR1z",
	"sidePanelContainer": "EditListingTimeAndPricePageBody__sidePanelContainer___H34N_",
	"sidePanel": "EditListingTimeAndPricePageBody__sidePanel___UmtHJ"
};
export default ___CSS_LOADER_EXPORT___;
