// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MergeUnmergeTablesSheet__container____KoAA{padding:1.5rem}.MergeUnmergeTablesSheet__container____KoAA ul{list-style-type:disc;padding:1.5rem}.MergeUnmergeTablesSheet__container____KoAA button{margin-top:.5rem;width:100%}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/mergeUnmergeTables/MergeUnmergeTablesSheet.scss"],"names":[],"mappings":"AAAA,4CACE,cAAA,CAEA,+CACE,oBAAA,CACA,cAAA,CAGF,mDACE,gBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MergeUnmergeTablesSheet__container____KoAA"
};
export default ___CSS_LOADER_EXPORT___;
