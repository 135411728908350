import cx from 'classnames';
import { type HTMLAttributes, useMemo } from 'react';
import { Icon } from '@components/icon/Icon';
import { reportAppError } from '@shared/reportAppError';
import { SERVICE_STATUS_META, type ServiceStatus } from './ServiceStatus';
import styles from './ServiceStatusIcon.scss';

interface ServiceStatusIconProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  serviceStatus: ServiceStatus;
}

export const ServiceStatusIcon = ({
  className,
  serviceStatus,
  ...attrs
}: ServiceStatusIconProps) => {
  const iconMeta = useMemo(() => {
    const meta = SERVICE_STATUS_META[serviceStatus];
    if (!meta) {
      reportAppError(new Error(`unknown service status: ${serviceStatus}`));
    }
    return meta;
  }, [serviceStatus]);
  if (!iconMeta) return null;
  const { color, label } = iconMeta;
  return (
    <div
      {...attrs}
      className={cx(styles.main, className)}
      data-testid="service-status-icon"
      style={{ backgroundColor: color }}
    >
      <Icon ariaLabel={label} name={serviceStatus} role="img" />
    </div>
  );
};
