// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublishListingConfirmationModal__warning___rXc7c{color:var(--white70);margin-bottom:1.5rem;max-width:30em}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/kebab/PublishListingConfirmationModal.scss"],"names":[],"mappings":"AAAA,kDACE,oBAAA,CACA,oBAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "PublishListingConfirmationModal__warning___rXc7c"
};
export default ___CSS_LOADER_EXPORT___;
