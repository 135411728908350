import type { SelectStringOption } from '@components/formSelect/FormSelect';

export const REPEAT_OPTIONS_DEFAULT: SelectStringOption[] = [
  {
    label: 'Daily',
    value: '-1',
  },
  {
    label: 'Sunday',
    value: '0',
  },
  {
    label: 'Monday',
    value: '1',
  },
  {
    label: 'Tuesday',
    value: '2',
  },
  {
    label: 'Wednesday',
    value: '3',
  },
  {
    label: 'Thursday',
    value: '4',
  },
  {
    label: 'Friday',
    value: '5',
  },
  {
    label: 'Saturday',
    value: '6',
  },
];

export const REPEAT_OPTION_DAILY = REPEAT_OPTIONS_DEFAULT[0];
const SUNDAY_THROUGH_SATURDAY = 1;

export const REPEAT_OPTIONS_WEEKDAYS = REPEAT_OPTIONS_DEFAULT.slice(
  SUNDAY_THROUGH_SATURDAY,
);
