import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast } from '@components/toasts/Toasts';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import { useRestaurant } from '../../context/useRestaurant';
import { type Admin, deactivateAdmin } from './apiHelpers';

export interface DeactivateAdminConfirmationModalProps {
  admin: Admin;
  closeModal: () => void;
  refetchRestaurantAdmins: () => void;
}

export const DeactivateAdminConfirmationModal = ({
  admin,
  closeModal,
  refetchRestaurantAdmins,
}: DeactivateAdminConfirmationModalProps) => {
  const { id: restaurantId } = useRestaurant();
  const [isLoading, setIsLoading] = useState(false);

  const handleDeactivate = async () => {
    setIsLoading(true);
    const response = await deactivateAdmin(restaurantId, admin.id);

    if (response.ok) {
      await refetchRestaurantAdmins();
    } else {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }
    closeModal();
  };

  return (
    <Modal
      ariaLabel="deactivate admin"
      isOpen
      onClose={closeModal}
      subtitle={`Are you sure you want to deactivate ${admin.fullName}'s account?`}
    >
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isLoading}
          label="Confirm"
          onClick={handleDeactivate}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
