import type { ApiResponse } from '@shared/types/apiHelpers';
import type { ReservationStatus } from 'restaurantAdmin/reservations/apiHelpers';
import API from '../api/apiClient';

export interface CreatedGuestNote {
  id: string;
}

export interface GuestNote {
  id: string;
  note: string;
}

interface TransactionDetails {
  createdBy: {
    fullName?: string;
  };
  fee: number;
  price: number;
  receiptNumber: string;
  refundAmount: number | null;
  tax: number;
}

export interface GuestReservation {
  date: string;
  guestCount: number;
  id: string;
  publicName: string;
  seatedTableName: string | null;
  status: ReservationStatus;
  time: string;
  transactionDetails: TransactionDetails;
}

export interface GuestsApiGuest {
  email: string;
  firstName: string;
  id: string;
  isVip: boolean;
  lastName: string;
  phone: string;
}

export type GuestTagCategory =
  | 'allergies'
  | 'guest attributes'
  | 'dietary restrictions'
  | 'other';

export interface GuestTag {
  category: GuestTagCategory;
  id: string;
  title: string;
}

export enum ReservationEventType {
  InitialBooking = 'initialBooking',
}

export interface ReservationEvent {
  createdAt: string;
  id: string;
  reservationId: string;
  type: ReservationEventType;
}

export const createGuestNotes = async ({
  restaurantId,
  guestId,
  guestNoteContent,
}: {
  restaurantId: string;
  guestId: string;
  guestNoteContent: string;
}): Promise<ApiResponse<CreatedGuestNote>> => {
  const response = await API.post(
    `/restaurants/${restaurantId}/guests/${guestId}/guest-notes`,
    {
      note: guestNoteContent,
    },
  );
  return response.json();
};

export const getAllTags = async (): Promise<ApiResponse<GuestTag[]>> => {
  const response = await API.get(`/tags`);
  return response.json();
};

export const getGuests = async ({
  restaurantId,
  limit,
  offset,
  search,
  vipOnly = false,
}: {
  restaurantId: string;
  limit: number;
  offset: number;
  search: string;
  vipOnly: boolean;
}): Promise<ApiResponse<GuestsApiGuest[]>> => {
  const url =
    search === ''
      ? `/restaurants/${restaurantId}/guests?limit=${limit}&offset=${offset}&vipOnly=${vipOnly}`
      : `/restaurants/${restaurantId}/guests?limit=${limit}&offset=${offset}&vipOnly=${vipOnly}&search=${search.replace(
          /\s/g,
          '',
        )}`;
  const response = await API.get(url);

  return response.json();
};

export const getGuestNotes = async (
  restaurantId: string,
  guestId: string,
): Promise<ApiResponse<GuestNote>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/guests/${guestId}/guest-notes`,
  );

  return response.json();
};

export const getGuestTagsForGuest = async (
  restaurantId: string,
  guestId: string,
): Promise<ApiResponse<GuestTag[]>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/guests/${guestId}/tags`,
  );

  return response.json();
};

export const getReservationsForGuest = async (
  restaurantId: string,
  guestId: string,
): Promise<ApiResponse<GuestReservation[]>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/guests/${guestId}/reservations`,
  );

  return response.json();
};

export const getTransactionLogForReservation = async (
  restaurantId: string,
  reservationId: string,
): Promise<ApiResponse<ReservationEvent[]>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/reservations/${reservationId}/events`,
  );

  return response.json();
};

export const updateGuestNotes = async ({
  id,
  restaurantId,
  guestId,
  guestNoteContent,
}: {
  id: string;
  restaurantId: string;
  guestId: string;
  guestNoteContent: string;
}): Promise<Response> =>
  API.put(`/restaurants/${restaurantId}/guests/${guestId}/guest-notes/${id}`, {
    note: guestNoteContent,
  });

export const updateGuestTags = async ({
  restaurantId,
  guestId,
  tagIds,
}: {
  restaurantId: string;
  guestId: string;
  tagIds: string[];
}): Promise<Response> =>
  API.put(`/restaurants/${restaurantId}/guests/${guestId}/tags`, {
    tagIds,
  });
