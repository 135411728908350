import cx from 'classnames';
import { addMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Icon } from '@components/icon/Icon';
import { getFullName } from '@utils/formatName';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../../context/useRestaurant';
import type { WaitListEntry } from './apiHelpers';
import styles from './WaitListEntryCard.scss';
import { WaitListTimer } from './WaitListTimer';

export interface WaitListEntryCardProps {
  onClick: () => void;
  waitListEntry: WaitListEntry;
}

export const WaitListEntryCard = ({
  onClick,
  waitListEntry,
}: WaitListEntryCardProps) => {
  const { timezone } = useRestaurant();

  const waitEnd = addMinutes(
    new Date(waitListEntry.createdAt),
    waitListEntry.estimatedWait,
  );
  const waitEndDisplay = formatInTimeZone(waitEnd, timezone, 'p');

  return (
    <li className={styles.card}>
      <button onClick={onClick} className={styles.button}>
        <WaitListTimer waitListEntry={waitListEntry} />
        <div className={styles.infoContainer}>
          <div className={cx(typography.h8, styles.waitEstimate)}>
            Estimate - {waitEndDisplay}
          </div>
          <div className={styles.nameAndGuestCountRow}>
            <div className={styles.userFullName}>
              {getFullName(waitListEntry.firstName, waitListEntry.lastName)}
            </div>
            <div
              aria-label={`${waitListEntry.guestCount} guests`}
              className={styles.guestCount}
            >
              <Icon name="couple" /> {waitListEntry.guestCount}
            </div>
          </div>
        </div>
      </button>
    </li>
  );
};
