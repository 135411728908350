import { useDroppable } from '@dnd-kit/core';
import { kebabCase } from 'lodash-es';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { type RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import { useReservationServiceContext } from 'restaurantAdmin/reservations/service/state/reservationServiceContext';
import { SeatedFloorPlanTable } from './SeatedFloorPlanTable';
import { SeatedGuestFloorPlanTable } from './SeatedGuestFloorPlanTable';
import { SeatedWalkInFloorPlanTable } from './SeatedWalkInFloorPlanTable';
import { UnoccupiedFloorPlanTable } from './UnoccupiedFloorPlanTable';

interface SeatedFloorPlanTableFactoryProps {
  calculateIsHighlighted?: (floorPlanTable: HostFloorPlanTable) => boolean;
  floorPlanTable: HostFloorPlanTable;
  handleTableOnClick: (
    floorPlanTable: HostFloorPlanTable,
  ) => void | Promise<void>;
  shouldShowTimers: boolean;
  tableIconScale: number;
}
export const SeatedFloorPlanTableFactory = ({
  calculateIsHighlighted = () => false,
  floorPlanTable,
  handleTableOnClick,
  shouldShowTimers,
  tableIconScale,
}: SeatedFloorPlanTableFactoryProps) => {
  const { seatedParty, seatedOccupant } = floorPlanTable;
  const { selectedOccupant } = useReservationServiceContext();
  const isSeated = seatedParty !== null && seatedOccupant !== null;
  const { setNodeRef } = useDroppable({
    id: floorPlanTable.id,
    data: {
      accepts: !isSeated ? ['reservation'] : [],
      floorPlanTable,
    },
  });
  const isReservation = seatedParty && 'reservation' in seatedParty;
  const testId = kebabCase(`floor-plan-table-${floorPlanTable.name}`);

  const fallBackFloorPlanTable = () => {
    if (isReservation) {
      return (
        <SeatedGuestFloorPlanTable
          iconName={floorPlanTable.iconName}
          iconWidthScale={floorPlanTable.iconWidthScale}
          isDisabled
          isHighlighted={selectedOccupant?.id === seatedParty.reservation.id}
          key={floorPlanTable.id}
          left={floorPlanTable.left}
          name={floorPlanTable.name}
          orientation={floorPlanTable.orientation}
          ref={setNodeRef}
          seatedGuest={seatedParty}
          tableIconScale={tableIconScale}
          tableName={floorPlanTable.name}
          testId={testId}
          top={floorPlanTable.top}
        />
      );
    }

    return (
      <SeatedWalkInFloorPlanTable
        iconName={floorPlanTable.iconName}
        iconWidthScale={floorPlanTable.iconWidthScale}
        isDisabled
        // remove unnecessary non-null assertion operator when serviceStatusFlag is removed
        isHighlighted={selectedOccupant?.id === seatedParty!.id}
        key={floorPlanTable.id}
        left={floorPlanTable.left}
        name={floorPlanTable.name}
        orientation={floorPlanTable.orientation}
        ref={setNodeRef}
        seatedWalkIn={seatedParty!}
        tableIconScale={tableIconScale}
        tableName={floorPlanTable.name}
        testId={testId}
        top={floorPlanTable.top}
      />
    );
  };

  if (isSeated) {
    return (
      <FeatureFlagOn<RestaurantAdminFeatureFlag>
        element={
          <SeatedFloorPlanTable
            iconName={floorPlanTable.iconName}
            iconWidthScale={floorPlanTable.iconWidthScale}
            isDisabled
            isHighlighted={selectedOccupant?.id === seatedOccupant.id}
            key={floorPlanTable.id}
            left={floorPlanTable.left}
            name={floorPlanTable.name}
            orientation={floorPlanTable.orientation}
            ref={setNodeRef}
            seatedOccupant={seatedOccupant}
            tableIconScale={tableIconScale}
            tableName={floorPlanTable.name}
            testId={testId}
            top={floorPlanTable.top}
          />
        }
        fallback={fallBackFloorPlanTable()}
        name="serviceStatusFlag"
      />
    );
  }

  return (
    <UnoccupiedFloorPlanTable
      availableTime={shouldShowTimers ? floorPlanTable.availableTime : null}
      handleTableOnClick={() => {
        void handleTableOnClick(floorPlanTable);
      }}
      iconName={floorPlanTable.iconName}
      iconWidthScale={floorPlanTable.iconWidthScale}
      isHighlighted={calculateIsHighlighted(floorPlanTable)}
      isSelected={false}
      key={floorPlanTable.id}
      left={floorPlanTable.left}
      name={floorPlanTable.name}
      orientation={floorPlanTable.orientation}
      ref={setNodeRef}
      tableIconScale={tableIconScale}
      testId={testId}
      top={floorPlanTable.top}
    />
  );
};
