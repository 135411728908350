import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FeatureFlagContextProvider } from '@shared/context/featureFlagContext';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { AuthContextProvider } from './context/authContext';
import { ModalContextProvider } from './context/modalContext';
import { featureFlagConfig } from './featureFlags';
import { AvailabilityDrawerContextProvider } from './reservations/concierge/state/availabilityDrawerContext';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.overrides.css';
import './index.scss';

export const Root = () => {
  useDocumentTitle('Peak Reservations');
  return (
    <FeatureFlagContextProvider environmentConfig={featureFlagConfig}>
      <ToastContainer
        autoClose={5000}
        closeButton
        hideProgressBar
        limit={2}
        newestOnTop
        pauseOnHover
        position="top-right"
        theme="colored"
      />
      <AuthContextProvider>
        <ModalContextProvider>
          <AvailabilityDrawerContextProvider>
            <Outlet />
          </AvailabilityDrawerContextProvider>
        </ModalContextProvider>
      </AuthContextProvider>
    </FeatureFlagContextProvider>
  );
};
