import cx from 'classnames';
import { useEffect, useState } from 'react';
import type { MultiValue } from 'react-select';
import { Button, ButtonVariants } from '@components/button/Button';
import type { FormSelectItem } from '@components/formSelect/FormSelect';
import { FormSelect } from '@components/formSelect/FormSelect';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { useRestaurant } from '../../../context/useRestaurant';
import {
  getAllTags,
  type GuestTag,
  updateGuestTags,
} from '../../../guestBook/apiHelpers';
import styles from './GuestTagEditForm.scss';

const EMPTY_GUEST_TAGS = 0;

const mapGuestTagsToFormSelectItem = (tags: GuestTag[]): FormSelectItem[] =>
  tags.map(
    (tag) =>
      ({
        label: tag.title,
        value: tag.id,
      }) as FormSelectItem,
  );

interface GuestTagEditFormProps {
  guestId: string;
  guestTags: GuestTag[];
  onComplete: () => void;
}

export const GuestTagEditForm = ({
  guestId,
  guestTags,
  onComplete,
}: GuestTagEditFormProps) => {
  const currentGuestTags = mapGuestTagsToFormSelectItem(guestTags);
  const [allTags, setAllTags] = useState<FormSelectItem[]>([]);
  const [selectedTags, setSelectedTags] = useState<FormSelectItem[]>([]);
  const { id: restaurantId } = useRestaurant();

  useEffect(() => {
    const fetchTagOptions = async () => {
      const response = await getAllTags();
      if (isSuccessResponse(response)) {
        setAllTags(mapGuestTagsToFormSelectItem(response));
      }
    };
    if (currentGuestTags.length > EMPTY_GUEST_TAGS)
      setSelectedTags(currentGuestTags);
    if (allTags.length === EMPTY_GUEST_TAGS) fetchTagOptions();
  }, []);

  const onTagChange = (tags: MultiValue<FormSelectItem>) => {
    setSelectedTags(tags.map((tag: FormSelectItem) => tag));
  };

  const handleOnSave = async () => {
    await updateGuestTags({
      guestId,
      restaurantId,
      tagIds: selectedTags.map((tag) => tag.value as string),
    });

    onComplete();
  };

  return (
    <div className={styles.container}>
      <FormSelect
        className={styles.selection}
        defaultValue={currentGuestTags}
        hideLabel
        isMulti
        label="Select Guest Tags"
        onChange={onTagChange}
        options={allTags}
        value={selectedTags}
      />
      <div className={styles.actions}>
        <Button
          className={cx(styles.btn, styles.secondaryBtn)}
          label="Discard"
          onClick={onComplete}
          variant={ButtonVariants.DefaultWhite}
        />
        <Button
          className={styles.btn}
          label="Save"
          onClick={handleOnSave}
          variant={ButtonVariants.Primary}
        />
      </div>
    </div>
  );
};
