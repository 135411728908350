// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EndlessDateFormControl__container___JexV5{display:flex;flex-direction:row;align-items:center;justify-content:space-between;width:100%}.EndlessDateFormControl__labelContainer___XfQn8{align-items:center;display:flex;gap:8px}.EndlessDateFormControl__labelError___RXcUx{color:var(--pink)}.EndlessDateFormControl__switchEndlessDateContainer___DaLr1{color:var(--white);position:relative;width:15rem}.EndlessDateFormControl__switch___VUIcP{align-items:center;display:flex;flex-direction:row;justify-content:space-between;width:100%}.EndlessDateFormControl__checkEndless___tS1VO{color:var(--darkBlue);font-size:12px;font-weight:400;line-height:16px;position:absolute;right:16px;text-decoration-line:underline;top:16px}@-moz-document url-prefix(\"\"){.EndlessDateFormControl__checkEndless___tS1VO{margin-right:20%;margin-top:.25rem}}.EndlessDateFormControl__setEndlessDate___bhOZx{color:var(--gold)}", "",{"version":3,"sources":["webpack://./src/shared/components/endlessDateFormControl/EndlessDateFormControl.scss"],"names":[],"mappings":"AAAA,2CACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CAGF,gDACE,kBAAA,CACA,YAAA,CACA,OAAA,CAGF,4CACE,iBAAA,CAGF,4DACE,kBAAA,CACA,iBAAA,CACA,WAAA,CAGF,wCACE,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CAGF,8CACE,qBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CACA,8BAAA,CACA,QAAA,CAGA,8BAXF,8CAYI,gBAAA,CACA,iBAAA,CAAA,CAKJ,gDACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EndlessDateFormControl__container___JexV5",
	"labelContainer": "EndlessDateFormControl__labelContainer___XfQn8",
	"labelError": "EndlessDateFormControl__labelError___RXcUx",
	"switchEndlessDateContainer": "EndlessDateFormControl__switchEndlessDateContainer___DaLr1",
	"switch": "EndlessDateFormControl__switch___VUIcP",
	"checkEndless": "EndlessDateFormControl__checkEndless___tS1VO",
	"setEndlessDate": "EndlessDateFormControl__setEndlessDate___bhOZx"
};
export default ___CSS_LOADER_EXPORT___;
