// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConciergePage__pageContent___Upimt{display:flex;flex-direction:column}.ConciergePage__filters___F6lOR{display:flex;flex-direction:row;gap:1rem}.ConciergePage__content___Jymhp{flex:1 0 0;margin-top:1.5rem;min-height:0}@media only screen and (min-width: 768px){.ConciergePage__content___Jymhp{column-gap:8%;display:flex}.ConciergePage__content___Jymhp .ConciergePage__floorPlan___J1Rcd{max-width:58%;min-width:58%}}@media only screen and (min-width: 1024px){.ConciergePage__content___Jymhp{column-gap:4rem}}.ConciergePage__reserveButton___u8P2O{height:2rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/concierge/ConciergePage.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,qBAAA,CAGF,gCACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,gCACE,UAAA,CACA,iBAAA,CACA,YAAA,CCqCE,0CDxCJ,gCAMI,aAAA,CACA,YAAA,CAEA,kEACE,aAAA,CACA,aAAA,CAAA,CC6CF,2CDxDJ,gCAgBI,eAAA,CAAA,CAIJ,sCACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "ConciergePage__pageContent___Upimt",
	"filters": "ConciergePage__filters___F6lOR",
	"content": "ConciergePage__content___Jymhp",
	"floorPlan": "ConciergePage__floorPlan___J1Rcd",
	"reserveButton": "ConciergePage__reserveButton___u8P2O"
};
export default ___CSS_LOADER_EXPORT___;
