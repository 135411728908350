// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OccupantSheetWrapper__container___LXw9I{display:flex;flex-direction:column;padding:24px;height:100%;overflow-y:auto}.OccupantSheetWrapper__container___LXw9I .OccupantSheetWrapper__toolBar___Y_uB_{align-items:center;display:flex;justify-content:space-between;margin-bottom:16px}.OccupantSheetWrapper__container___LXw9I .OccupantSheetWrapper__toolBar___Y_uB_ .OccupantSheetWrapper__occupantPosition___bIi9b{margin:0 16px;color:var(--white70)}.OccupantSheetWrapper__container___LXw9I .OccupantSheetWrapper__toolBar___Y_uB_ .OccupantSheetWrapper__button___wSxkB{background-color:rgba(0,0,0,0);border:none;cursor:pointer;padding:0}.OccupantSheetWrapper__container___LXw9I .OccupantSheetWrapper__toolBar___Y_uB_ .OccupantSheetWrapper__button___wSxkB .OccupantSheetWrapper__icon___oTEdT{stroke:var(--white70);width:20px}.OccupantSheetWrapper__container___LXw9I .OccupantSheetWrapper__toolBar___Y_uB_ .OccupantSheetWrapper__button___wSxkB .OccupantSheetWrapper__arrowRightIcon___soGyB{transform:rotate(180deg)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/sidePanel/OccupantSheetWrapper.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,WAAA,CACA,eAAA,CAEA,gFACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAEA,gIACE,aAAA,CACA,oBAAA,CAGF,sHACE,8BAAA,CACA,WAAA,CACA,cAAA,CACA,SAAA,CAEA,0JACE,qBAAA,CACA,UAAA,CAGF,oKACE,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OccupantSheetWrapper__container___LXw9I",
	"toolBar": "OccupantSheetWrapper__toolBar___Y_uB_",
	"occupantPosition": "OccupantSheetWrapper__occupantPosition___bIi9b",
	"button": "OccupantSheetWrapper__button___wSxkB",
	"icon": "OccupantSheetWrapper__icon___oTEdT",
	"arrowRightIcon": "OccupantSheetWrapper__arrowRightIcon___soGyB"
};
export default ___CSS_LOADER_EXPORT___;
