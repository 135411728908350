import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import { DEFAULT_NO_RECORDED_GUEST_NOTE_TEXT } from '../constants';
import styles from './GuestNotesShow.scss';

export interface GuestNotesShowFormProps {
  note: string;
  onEdit: React.MouseEventHandler<HTMLButtonElement>;
}

export const GuestNotesShow = ({ onEdit, note }: GuestNotesShowFormProps) => (
  <section aria-labelledby="guest-notes" className={styles.showForm}>
    <h3 className={typography.h7} id="guest-notes">
      Guest Notes
    </h3>
    <button onClick={onEdit}>
      <Icon name="pencil" />
      Edit
    </button>
    {/* p element has the "paragraph" aria-role implicitly, but bug waiting to be fixed: https://stackoverflow.com/questions/65122974/getbyrole-query-for-paragraph-not-working-during-react-testing#comment135839956_65123080 */}
    <p className={typography.t1} role="paragraph">
      {note || DEFAULT_NO_RECORDED_GUEST_NOTE_TEXT}
    </p>
  </section>
);
