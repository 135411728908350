// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SeatedFloorPlanTable__container___cGg6q{display:flex;position:absolute;padding:0;border:none}.SeatedFloorPlanTable__container___cGg6q .SeatedFloorPlanTable__button___hZRKm{background-color:rgba(0,0,0,0);padding:0;border:none}.SeatedFloorPlanTable__container___cGg6q .SeatedFloorPlanTable__icon___FJ9_A{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:1;display:flex;flex-direction:column;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/floorPlan/SeatedFloorPlanTable.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,iBAAA,CACA,SAAA,CACA,WAAA,CAEA,+EACE,8BAAA,CACA,SAAA,CACA,WAAA,CAGF,6EAEE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,SAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SeatedFloorPlanTable__container___cGg6q",
	"button": "SeatedFloorPlanTable__button___hZRKm",
	"icon": "SeatedFloorPlanTable__icon___FJ9_A"
};
export default ___CSS_LOADER_EXPORT___;
