import { type ApiResponse } from '@shared/types/apiHelpers';
import type {
  FloorPlanData,
  IconNameType,
  ListingId,
} from '@shared/types/floorPlans';
import { type ServiceStatus } from 'restaurantAdmin/reservations/service/serviceStatus/ServiceStatus';
import API from '../api/apiClient';
// to be removed when serviceStatusFlag is removed
export interface SeatedGuest {
  guest: {
    firstName: string;
    lastName: string;
  };
  reservation: {
    id: string;
    guestCount: number;
  };
}
// to be removed when serviceStatusFlag is removed
export interface SeatedWalkIn {
  firstName?: string;
  guestCount: number;
  id: string;
  lastName?: string;
}
// to be removed when serviceStatusFlag is removed
export type SeatedParty = SeatedGuest | SeatedWalkIn;

export interface SeatedOccupant {
  firstName?: string;
  guestCount: number;
  id: string;
  lastName?: string;
  serviceStatus: ServiceStatus;
  type: 'reservation' | 'walkIn';
}

export interface HostFloorPlanTable {
  availableTime: number | null;
  iconName: IconNameType;
  iconWidthScale: string;
  id: string;
  interval: number;
  left: string;
  maximumGuests: number;
  mergeTableId: string | null;
  mergeTableName: string | null;
  minimumGuests: number;
  name: string;
  orientation: string;
  seatedOccupant: SeatedOccupant | null;
  // to be removed when serviceStatusFlag is removed
  seatedParty: SeatedParty | null;
  listings: ListingId[];
  top: string;
  turnTime: number;
}

export interface HostFloorPlanData {
  id: string;
  backgroundSrc?: string;
  floorPlanTables: HostFloorPlanTable[];
}

export const getFloorPlanForRestaurantId = async (
  restaurantId: string,
): Promise<ApiResponse<FloorPlanData>> => {
  const response = await API.get(`/restaurants/${restaurantId}/floor-plan`);

  return response.json();
};

export const getHostFloorPlanForRestaurantId = async (
  restaurantId: string,
): Promise<ApiResponse<HostFloorPlanData>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/admin-floor-plans/seating`,
  );
  return response.json();
};
