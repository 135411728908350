import cx from 'classnames';
import { Button, ButtonVariants } from '@components/button/Button';
import { Icon } from '@components/icon/Icon';
import { TimeFilterPopover } from '@components/timeFilter/TimeFilterPopover';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { ISOTimeTo12HourTime } from '@utils/time';
import { useListingsContext } from '../ListingsContext';
import { generateTimes } from '../utils/listingUtils';
import styles from './FloorPlanTimeSelector.scss';

export const FloorPlanTimeSelector = () => {
  const {
    isOpen: isOpenPill,
    toggle: togglePill,
    close: closePill,
  } = useIsOpen();
  const { ref } = useHandleClickOutside(closePill);
  const { selectedFloorPlanTime, setSelectedFloorPlanTime } =
    useListingsContext();

  const handleTimeClick = (time: string): void => {
    setSelectedFloorPlanTime(time);
    closePill();
  };

  const timePickerClassNames = cx({
    [styles.pill]: true,
    [styles.isOpen]: isOpenPill,
  });

  return (
    <div ref={ref} className={styles.container}>
      <button
        data-testid="time-filter-pill"
        className={timePickerClassNames}
        onClick={togglePill}
      >
        <Icon className={styles.icon} name="clock" />
        {selectedFloorPlanTime
          ? ISOTimeTo12HourTime(selectedFloorPlanTime)
          : 'All Day'}
      </button>
      <Button
        dataTestId="all-day-button"
        className={styles.allDayButton}
        isDisabled={!selectedFloorPlanTime}
        label="All Day"
        onClick={() => setSelectedFloorPlanTime(null)}
        variant={ButtonVariants.Tertiary}
      />
      {isOpenPill && (
        <TimeFilterPopover
          availableTimes={generateTimes()}
          selectedTime={selectedFloorPlanTime ?? undefined}
          setSelectedTime={handleTimeClick}
        />
      )}
    </div>
  );
};
