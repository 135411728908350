// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestaurantEventForm__form___JSVz1{background-color:var(--primary500);border-radius:8px;display:flex;flex-direction:column;gap:1.5rem;padding:3rem 2.5rem;width:47rem}.RestaurantEventForm__form___JSVz1 header{border-bottom:1px solid var(--hairline);padding-bottom:24px}.RestaurantEventForm__form___JSVz1 header h2{color:var(--white);margin:0}.RestaurantEventForm__form___JSVz1 input{width:25rem}.RestaurantEventForm__form___JSVz1 input+div{width:25rem}.RestaurantEventForm__form___JSVz1 input[type=date]::-webkit-calendar-picker-indicator{margin-right:65%}.RestaurantEventForm__form___JSVz1 .RestaurantEventForm__buttonContainer___VfJzz{display:flex;justify-content:flex-end;gap:.5rem}.RestaurantEventForm__form___JSVz1 .RestaurantEventForm__buttonContainer___VfJzz button{height:2rem;width:10rem}.RestaurantEventForm__form___JSVz1 .RestaurantEventForm__endDate___ONFDI>div:last-child{width:400px}.RestaurantEventForm__form___JSVz1 .RestaurantEventForm__repeatContainer___tHYFZ>div:nth-child(2){width:25rem}.RestaurantEventForm__form___JSVz1 .RestaurantEventForm__repeatFormSelect___hCoB6{width:400px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/events/RestaurantEventForm.scss"],"names":[],"mappings":"AAAA,mCACE,kCAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,mBAAA,CACA,WAAA,CAEA,0CACE,uCAAA,CACA,mBAAA,CAEA,6CACE,kBAAA,CACA,QAAA,CAIJ,yCACE,WAAA,CAGF,6CACE,WAAA,CAGF,uFACE,gBAAA,CAGF,iFACE,YAAA,CACA,wBAAA,CACA,SAAA,CAEA,wFACE,WAAA,CACA,WAAA,CAKF,wFACE,WAAA,CAKF,kGACE,WAAA,CAIJ,kFACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "RestaurantEventForm__form___JSVz1",
	"buttonContainer": "RestaurantEventForm__buttonContainer___VfJzz",
	"endDate": "RestaurantEventForm__endDate___ONFDI",
	"repeatContainer": "RestaurantEventForm__repeatContainer___tHYFZ",
	"repeatFormSelect": "RestaurantEventForm__repeatFormSelect___hCoB6"
};
export default ___CSS_LOADER_EXPORT___;
