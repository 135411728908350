// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TransactionLog__container___nFI4p{background-color:var(--panelBlue);border-radius:.5rem;display:flex;flex-direction:column;gap:1rem;padding:1.5rem 1.5rem 2rem;width:100%}.TransactionLog__container___nFI4p header{align-items:center;display:flex;gap:.25rem}.TransactionLog__container___nFI4p header svg{fill:var(--white);height:1.5rem;width:1.5rem}.TransactionLog__container___nFI4p p{color:var(--white70);margin:0}.TransactionLog__container___nFI4p dl{display:flex;flex-direction:column;gap:.5rem}.TransactionLog__container___nFI4p dd{color:var(--white70);margin:0}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/guestBook/TransactionLog.scss"],"names":[],"mappings":"AAAA,mCACE,iCAAA,CACA,mBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,0BAAA,CACA,UAAA,CAEA,0CACE,kBAAA,CACA,YAAA,CACA,UAAA,CAEA,8CACE,iBAAA,CACA,aAAA,CACA,YAAA,CAIJ,qCACE,oBAAA,CACA,QAAA,CAGF,sCACE,YAAA,CACA,qBAAA,CACA,SAAA,CAGF,sCACE,oBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TransactionLog__container___nFI4p"
};
export default ___CSS_LOADER_EXPORT___;
