import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

export interface DiscardWalkInConfirmationModalProps {
  closeModal: () => void;
  handleDiscardWalkIn: () => void;
  isLoading: boolean;
  isOpen: boolean;
}

export const DiscardWalkInConfirmationModal = ({
  closeModal,
  handleDiscardWalkIn,
  isLoading,
  isOpen,
}: DiscardWalkInConfirmationModalProps) => (
  <Modal
    ariaLabel="discard walk-in"
    isOpen={isOpen}
    onClose={closeModal}
    subtitle="Are you sure you wish to discard this walk-in?"
  >
    <ModalActions>
      <Button
        label="Go Back"
        onClick={closeModal}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        isDisabled={isLoading}
        label="Confirm"
        onClick={handleDiscardWalkIn}
        variant={ButtonVariants.Primary}
      />
    </ModalActions>
  </Modal>
);
