import 'react-phone-number-input/style.css';

import cx from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { Card } from '@components/card/Card';
import { GuestCountPicker } from '@components/guestCountPicker/GuestCountPicker';
import { HookAwareFormInput } from '@components/hookAwareFormInput/HookAwareFormInput';
import { HookAwarePhoneInput } from '@components/hookAwarePhoneNumberInput/HookAwarePhoneInput';
import { IconButton } from '@components/iconButton/IconButton';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../../context/useRestaurant';
import { createWaitListEntry } from './apiHelpers';
import { useWaitListContext } from './state/waitListContext';
import styles from './WaitListForm.scss';

export interface WaitListFormData {
  estimatedWait: string;
  firstName: string;
  guestCount: number;
  guestPhone: string;
  lastName: string;
}

export interface WaitListFormProps {
  className?: string;
  onClose: () => void;
}

const DEFAULT_GUEST_COUNT = 2;

export const WaitListForm = ({ className, onClose }: WaitListFormProps) => {
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<WaitListFormData>({
    defaultValues: {
      guestCount: DEFAULT_GUEST_COUNT,
    },
  });
  const { fetchWaitListEntries } = useWaitListContext();
  const { id: restaurantId, maxReservationGuests } = useRestaurant();

  const onSubmit = async (data: WaitListFormData) => {
    const response = await createWaitListEntry({
      estimatedWait: parseInt(data.estimatedWait, 10),
      firstName: data.firstName,
      guestCount: data.guestCount,
      lastName: data.lastName,
      phone: data.guestPhone,
      restaurantId,
    });

    if (response.ok) {
      successToast({ message: 'Successfully added guest to wait list' });
      await fetchWaitListEntries();
      onClose();
    } else {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }
  };

  return (
    <Card bordered className={cx(styles.container, className)}>
      <div className={styles.header}>
        <h2 className={typography.h4}>Add to Waitlist</h2>
        <IconButton
          ariaLabel="close form"
          onClick={onClose}
          iconName="close"
          className={styles.closeButton}
          iconClassName={styles.icon}
        />
      </div>
      <form
        aria-label="Wait List Form"
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.inputContainer}>
          <HookAwarePhoneInput
            control={control}
            errors={errors}
            label="Guest Phone"
            name="guestPhone"
          />
          <HookAwareFormInput
            className={styles.waitListInput}
            errors={errors}
            inputType="text"
            isDisabled={false}
            label="First Name"
            name="firstName"
            register={register}
            validations={{ required: true }}
          />
          <HookAwareFormInput
            className={styles.waitListInput}
            errors={errors}
            inputType="text"
            isDisabled={false}
            label="Last Name"
            name="lastName"
            register={register}
            validations={{ required: true }}
          />
          <HookAwareFormInput
            className={styles.waitListInput}
            errors={errors}
            inputType="number"
            isDisabled={false}
            label="Wait (in minutes)"
            name="estimatedWait"
            register={register}
            validations={{
              required: true,
              valueAsNumber: true,
              min: {
                value: 1,
                message: 'Wait time must be greater than 0',
              },
              max: {
                value: 599,
                message: 'Wait time must be less than 600',
              },
            }}
          />
        </div>
        <Controller
          control={control}
          name="guestCount"
          render={({ field: { onChange, value } }) => (
            <GuestCountPicker
              guestCount={value}
              max={maxReservationGuests}
              onChange={onChange}
            />
          )}
        />
        <div className={styles.actionContainer}>
          <Button
            label="Add to Waitlist"
            type="submit"
            variant={ButtonVariants.Primary}
          />
          <Button
            label="Cancel"
            onClick={onClose}
            variant={ButtonVariants.Tertiary}
          />
        </div>
      </form>
    </Card>
  );
};
