import { sortBy } from 'lodash-es';

export interface TimeRange {
  startTime: string;
  endTime: string;
}

/**
 * Returns true if the two time ranges overlap.
 * Ranges that are adjacent at the outer bounds yield false.
 */
export const timeRangeOverlaps = (a: TimeRange, b: TimeRange) =>
  a.endTime > b.startTime && a.startTime < b.endTime;

/**
 * Returns true if the time range includes the given time.
 * Times equal to the outer bounds yield true.
 */
export const timeRangeIncludes = (timeRange: TimeRange, time: string) =>
  time >= timeRange.startTime && time <= timeRange.endTime;

export const mergeOverlappingTimeRanges = (
  ranges: TimeRange[],
): TimeRange[] => {
  const result: TimeRange[] = [];
  sortBy(ranges, 'startTime').forEach((range) => {
    const last = result.length === 0 ? null : result[result.length - 1];
    if (last == null || range.startTime >= last.endTime) {
      result.push({
        startTime: range.startTime,
        endTime: range.endTime,
      });
    } else if (range.endTime > last.endTime) {
      last.endTime = range.endTime;
    }
  });
  return result;
};
