import API from '../../api/apiClient';

// Cancellation policy ===================================================

export const createCancellationPolicy = async ({
  fee,
  hoursBeforeReservation,
  restaurantId,
  threshold,
}: {
  fee: number;
  hoursBeforeReservation: number;
  restaurantId: string;
  threshold: number;
}): Promise<boolean> => {
  const response = await API.post(
    `/restaurants/${restaurantId}/cancellation-policy`,
    {
      fee,
      hoursBeforeReservation,
      threshold,
    },
  );

  return response.ok;
};

export const deleteCancellationPolicy = async (
  restaurantId: string,
  cancellationPolicyId: string,
): Promise<Response> =>
  API.delete(
    `/restaurants/${restaurantId}/cancellation-policy/${cancellationPolicyId}`,
  );

export const editCancellationPolicy = async ({
  fee,
  hoursBeforeReservation,
  id,
  threshold,
  restaurantId,
}: {
  fee: number;
  hoursBeforeReservation: number;
  id: string;
  threshold: number;
  restaurantId: string;
}): Promise<boolean> => {
  const response = await API.put(
    `/restaurants/${restaurantId}/cancellation-policy`,
    {
      fee,
      hoursBeforeReservation,
      id,
      threshold,
    },
  );

  return response.ok;
};

// Cover Limit ===================================================

export const deleteCoverLimit = async (
  restaurantId: string,
): Promise<Response> => API.delete(`/restaurants/${restaurantId}/cover-limit`);

export const updateCoverLimit = async (
  restaurantId: string,
  coverLimit: number,
): Promise<boolean> => {
  const response = await API.put(`/restaurants/${restaurantId}/cover-limit`, {
    coverLimit,
  });

  return response.ok;
};

// Max guest count =============================================

export const updateMaxGuestCount = async (
  restaurantId: string,
  maxGuestCount: number,
): Promise<boolean> => {
  const response = await API.put(
    `/restaurants/${restaurantId}/max-guest-count`,
    {
      maxGuestCount,
    },
  );

  return response.ok;
};

// Schedule ===================================================

export const updateSchedule = async (
  restaurantId: string,
  schedule: number,
): Promise<boolean> => {
  const response = await API.put(`/restaurants/${restaurantId}/schedule`, {
    schedule,
  });

  return response.ok;
};
