// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FloorPlanFieldset__floorPlanFieldset___RvckM{align-items:center;display:flex;flex-direction:column;max-width:var(--maxFloorPlanTabletWidth)}@media only screen and (min-width: 1024px){.FloorPlanFieldset__floorPlanFieldset___RvckM{max-width:var(--maxFloorPlanDesktopWidth)}}.FloorPlanFieldset__floorPlanLegend___LEjKt{margin-bottom:16px}.FloorPlanFieldset__floorPlanInstructions___1IFkY{color:rgba(255,255,255,.7);margin:0}.FloorPlanFieldset__floorPlanInstructions___1IFkY.FloorPlanFieldset__error___q1Vy0{color:var(--pink)}.FloorPlanFieldset__clearSelectionButton___l6_xT{align-items:center;align-self:center;backdrop-filter:blur(10px);background:rgba(221,221,221,.13);border-radius:24px;border:none;color:var(--white);cursor:pointer;display:flex;justify-content:center;padding:16px 24px}.FloorPlanFieldset__clearSelectionButton___l6_xT:focus,.FloorPlanFieldset__clearSelectionButton___l6_xT:hover,.FloorPlanFieldset__clearSelectionButton___l6_xT:active{background-color:var(--primary200);color:var(--black)}.FloorPlanFieldset__unselectText___WHSbm{color:var(--gold)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/FloorPlanFieldset.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,8CACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,wCAAA,CC+DE,2CDnEJ,8CAOI,yCAAA,CAAA,CAIJ,4CACE,kBAAA,CAGF,kDACE,0BAAA,CACA,QAAA,CAEA,mFACE,iBAAA,CAIJ,iDACE,kBAAA,CACA,iBAAA,CACA,0BAAA,CACA,gCAAA,CACA,kBAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CACA,YAAA,CACA,sBAAA,CACA,iBAAA,CAEA,sKAGE,kCAAA,CACA,kBAAA,CAIJ,yCACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floorPlanFieldset": "FloorPlanFieldset__floorPlanFieldset___RvckM",
	"floorPlanLegend": "FloorPlanFieldset__floorPlanLegend___LEjKt",
	"floorPlanInstructions": "FloorPlanFieldset__floorPlanInstructions___1IFkY",
	"error": "FloorPlanFieldset__error___q1Vy0",
	"clearSelectionButton": "FloorPlanFieldset__clearSelectionButton___l6_xT",
	"unselectText": "FloorPlanFieldset__unselectText___WHSbm"
};
export default ___CSS_LOADER_EXPORT___;
