import { kebabCase } from 'lodash-es';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { type RestaurantAdminFeatureFlag } from 'restaurantAdmin/featureFlags';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import { useReservationServiceContext } from '../state/reservationServiceContext';
import type { FloorPlanOccupant } from '../state/types';
import { SeatedFloorPlanTable } from './SeatedFloorPlanTable';
import { SeatedGuestFloorPlanTable } from './SeatedGuestFloorPlanTable';
import { SeatedWalkInFloorPlanTable } from './SeatedWalkInFloorPlanTable';
import { UnoccupiedFloorPlanTable } from './UnoccupiedFloorPlanTable';

interface OccupantFloorPlanTableFactoryProps {
  floorPlanTable: HostFloorPlanTable;
  handleTableOnClick: (floorPlanTable: HostFloorPlanTable) => void;
  seatedTableOnClick: (occupant: FloorPlanOccupant) => void;
  selectedEmptyTableId: string | undefined;
  shouldShowTimers: boolean;
  tableIconScale: number;
}
export const OccupantFloorPlanTableFactory = ({
  floorPlanTable,
  handleTableOnClick,
  seatedTableOnClick,
  selectedEmptyTableId,
  shouldShowTimers,
  tableIconScale,
}: OccupantFloorPlanTableFactoryProps) => {
  const { seatedParty, seatedOccupant } = floorPlanTable;
  const { selectedOccupant } = useReservationServiceContext();
  const isSeated = seatedParty !== null && seatedOccupant !== null;
  const isReservation = seatedParty && 'reservation' in seatedParty;
  const testId = kebabCase(`floor-plan-table-${floorPlanTable.name}`);
  const availableTime = shouldShowTimers ? floorPlanTable.availableTime : null;

  const fallBackFloorPlanTable = () =>
    isReservation ? (
      <SeatedGuestFloorPlanTable
        handleTableOnClick={() => {
          seatedTableOnClick({
            id: seatedParty.reservation.id,
            type: 'reservation',
          });
        }}
        iconName={floorPlanTable.iconName}
        iconWidthScale={floorPlanTable.iconWidthScale}
        isDisabled={false}
        isHighlighted={selectedOccupant?.id === seatedParty.reservation.id}
        key={floorPlanTable.id}
        left={floorPlanTable.left}
        name={floorPlanTable.name}
        orientation={floorPlanTable.orientation}
        seatedGuest={seatedParty}
        tableIconScale={tableIconScale}
        tableName={floorPlanTable.name}
        testId={testId}
        top={floorPlanTable.top}
      />
    ) : (
      <SeatedWalkInFloorPlanTable
        handleTableOnClick={() => {
          seatedTableOnClick({ id: seatedParty!.id, type: 'walkIn' });
        }}
        iconName={floorPlanTable.iconName}
        iconWidthScale={floorPlanTable.iconWidthScale}
        isDisabled={false}
        isHighlighted={selectedOccupant?.id === seatedParty!.id}
        key={floorPlanTable.id}
        left={floorPlanTable.left}
        name={floorPlanTable.name}
        orientation={floorPlanTable.orientation}
        seatedWalkIn={seatedParty!}
        tableIconScale={tableIconScale}
        tableName={floorPlanTable.name}
        testId={testId}
        top={floorPlanTable.top}
      />
    );

  if (isSeated) {
    return (
      <FeatureFlagOn<RestaurantAdminFeatureFlag>
        element={
          <SeatedFloorPlanTable
            handleTableOnClick={() => {
              // remove unnecessary non-null assertion operator when serviceStatusFlag is removed
              seatedTableOnClick({
                id: seatedOccupant.id,
                type: seatedOccupant.type,
              });
            }}
            iconName={floorPlanTable.iconName}
            iconWidthScale={floorPlanTable.iconWidthScale}
            isDisabled={false}
            isHighlighted={selectedOccupant?.id === seatedOccupant.id}
            key={floorPlanTable.id}
            left={floorPlanTable.left}
            name={floorPlanTable.name}
            orientation={floorPlanTable.orientation}
            seatedOccupant={seatedOccupant}
            tableIconScale={tableIconScale}
            tableName={floorPlanTable.name}
            testId={testId}
            top={floorPlanTable.top}
          />
        }
        fallback={fallBackFloorPlanTable()}
        name="serviceStatusFlag"
      />
    );
  }

  const isSelectedTable = selectedEmptyTableId
    ? selectedEmptyTableId === floorPlanTable.id
    : false;

  return (
    <UnoccupiedFloorPlanTable
      availableTime={availableTime}
      handleTableOnClick={() => {
        void handleTableOnClick(floorPlanTable);
      }}
      iconName={floorPlanTable.iconName}
      iconWidthScale={floorPlanTable.iconWidthScale}
      isHighlighted={isSelectedTable}
      isSelected={isSelectedTable}
      key={floorPlanTable.id}
      left={floorPlanTable.left}
      name={floorPlanTable.name}
      orientation={floorPlanTable.orientation}
      tableIconScale={tableIconScale}
      testId={testId}
      top={floorPlanTable.top}
    />
  );
};
