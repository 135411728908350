import API from '../../api/apiClient';
import { type RestaurantReservationsReportResponse } from '../apiHelpers';

export const getRestaurantsReservationsReport = async (
  restaurantId: string,
  date: string,
): Promise<RestaurantReservationsReportResponse> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/reservations-report?date=${date}`,
  );

  return response.json();
};
