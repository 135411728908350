import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { FeatureFlagOn } from '@shared/context/FeatureFlagOn';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { Status, StatusTag } from '../../../components/statusTag/StatusTag';
import { type RestaurantAdminFeatureFlag } from '../../../featureFlags';
import { type ServiceWalkIn } from '../apiHelpers';
import { ServiceStatusSelect } from '../serviceStatus/ServiceStatusSelect';
import styles from './ActiveReservationCard.scss';

export interface WalkInCardProps {
  onClick: () => void;
  onUpdated: () => void;
  walkIn: ServiceWalkIn;
}

const ONE_GUEST = 1;

export const SeatedWalkInCard = ({
  onClick,
  onUpdated,
  walkIn,
}: WalkInCardProps) => {
  const walkInName =
    `${walkIn.firstName || ''} ${walkIn.lastName || ''}`.trim() || 'WALK-IN';

  return (
    <li aria-label={`walk-in card for ${walkInName}`} className={styles.card}>
      <FeatureFlagOn<RestaurantAdminFeatureFlag>
        element={
          <ServiceStatusSelect
            occupantType="walkIn"
            occupantId={walkIn.id}
            onUpdated={onUpdated}
            value={walkIn.serviceStatus}
          />
        }
        fallback={<span />}
        name="serviceStatusFlag"
      />
      <button
        className={styles.button}
        onClick={onClick}
        aria-label="walk-in details"
      >
        <div className={styles.avatarAndInfoStack}>
          <FeatureFlagOn<RestaurantAdminFeatureFlag>
            element={<span />}
            fallback={
              <div className={styles.walkInIcon}>
                <Icon
                  ariaLabel="walkInNoBackground"
                  name="walkInNoBackground"
                />
              </div>
            }
            name="serviceStatusFlag"
          />

          <div className={styles.infoContainer}>
            <div className={styles.timeAndStatusRow}>
              <div className={cx(typography.h8, styles.time)}>
                {ISOTimeTo12HourTime(walkIn.time)}
              </div>
              <div className={cx(typography.t3, styles.status)}>Seated</div>
            </div>
            <div className={styles.nameAndGuestCountRow}>
              <div className={styles.userFullName}>{walkInName}</div>
              <div
                aria-label={`${walkIn.guestCount} guest${
                  walkIn.guestCount > ONE_GUEST ? 's' : ''
                }`}
                className={styles.guestCount}
              >
                <Icon name="couple" /> {walkIn.guestCount}
              </div>
            </div>
          </div>
        </div>
        {walkIn.hasUpcomingReservation && (
          <div className={styles.cardFooter}>
            <StatusTag status={Status.Upcoming} />
          </div>
        )}
      </button>
    </li>
  );
};
