// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DailyReservationsReportPage__container___k2ZoL{padding:8px;background-color:var(--white);color:var(--black);min-height:100vh}.DailyReservationsReportPage__container___k2ZoL .DailyReservationsReportPage__heading___MgVv5{margin-bottom:16px}.DailyReservationsReportPage__container___k2ZoL .DailyReservationsReportPage__timeDivider___AXyds{margin-bottom:8px;border-bottom:var(--black) 1px solid}.DailyReservationsReportPage__container___k2ZoL .DailyReservationsReportPage__reservationsList___m7jmn{margin-top:32px}.DailyReservationsReportPage__container___k2ZoL .DailyReservationsReportPage__reservationsList___m7jmn .DailyReservationsReportPage__reservation___ppUh9{margin-bottom:8px;display:grid;grid-template-columns:auto auto 1fr;column-gap:32px}.DailyReservationsReportPage__container___k2ZoL .DailyReservationsReportPage__reservationsList___m7jmn .DailyReservationsReportPage__reservation___ppUh9:last-child{margin-bottom:16px}.DailyReservationsReportPage__container___k2ZoL .DailyReservationsReportPage__reservationsList___m7jmn .DailyReservationsReportPage__reservation___ppUh9 .DailyReservationsReportPage__fillRow___D96rd{grid-column:2/4}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/report/DailyReservationsReportPage.scss"],"names":[],"mappings":"AAAA,gDACE,WAAA,CACA,6BAAA,CACA,kBAAA,CACA,gBAAA,CAEA,8FACE,kBAAA,CAGF,kGACE,iBAAA,CACA,oCAAA,CAGF,uGACE,eAAA,CAEA,yJACE,iBAAA,CACA,YAAA,CACA,mCAAA,CACA,eAAA,CAEA,oKACE,kBAAA,CAGF,uMACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DailyReservationsReportPage__container___k2ZoL",
	"heading": "DailyReservationsReportPage__heading___MgVv5",
	"timeDivider": "DailyReservationsReportPage__timeDivider___AXyds",
	"reservationsList": "DailyReservationsReportPage__reservationsList___m7jmn",
	"reservation": "DailyReservationsReportPage__reservation___ppUh9",
	"fillRow": "DailyReservationsReportPage__fillRow___D96rd"
};
export default ___CSS_LOADER_EXPORT___;
