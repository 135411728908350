import cx from 'classnames';
import { useRef } from 'react';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import {
  FloorPlan,
  type FloorPlanTablesRenderer,
} from '@components/floorPlan/FloorPlan';
import { interactiveFloorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { Icon } from '@components/icon/Icon';
import { useResetZoom } from '@shared/hooks/useResetZoom';
import type { FloorPlanTableData } from '@shared/types/floorPlans';
import typography from '~styles/typography.scss';
import { useListingsContext } from '../ListingsContext';
import { FloorPlanDateSelector } from './FloorPlanDateSelector';
import { FloorPlanTimeSelector } from './FloorPlanTimeSelector';
import styles from './InteractiveAdminFloorPlan.scss';

interface InteractiveAdminFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  tables: FloorPlanTableData[];
}

export const InteractiveAdminFloorPlan = ({
  backgroundSrc,
  className,
  tables,
}: InteractiveAdminFloorPlanProps) => {
  const zoomRef = useRef<ReactZoomPanPinchRef>(null);
  const {
    selectedListing,
    clearSelectedListing,
    pageListings: listings,
    selectedFloorPlanTableListingIds,
    setSelectedFloorPlanTableListingIds,
    selectedDate,
    selectedFloorPlanTime,
  } = useListingsContext();
  useResetZoom(zoomRef, [selectedListing], !!selectedListing);
  useResetZoom(zoomRef, [selectedDate, selectedFloorPlanTime]);

  const calculateIsSelectable = (floorPlanTable: FloorPlanTableData) =>
    floorPlanTable.listings.some((highlightedListing) =>
      listings.some((listing) => listing.id === highlightedListing.id),
    );

  const highlightedListingIds = selectedListing
    ? [selectedListing.id]
    : selectedFloorPlanTableListingIds.filter((listingId) =>
        listings.some((listing) => listing.id === listingId),
      );

  const calculateIsHighlighted = (
    floorPlanTable: FloorPlanTableData,
  ): boolean =>
    calculateIsSelectable(floorPlanTable) &&
    floorPlanTable.listings.some((listing) =>
      highlightedListingIds.includes(listing.id),
    );

  const handleTableOnClick = (floorPlanTable: FloorPlanTableData) => {
    if (calculateIsSelectable(floorPlanTable)) {
      clearSelectedListing();
      setSelectedFloorPlanTableListingIds(
        floorPlanTable.listings.map(({ id }) => id),
      );
    }
  };

  const clearFloorPlanSelection = () => setSelectedFloorPlanTableListingIds([]);

  const floorPlanTablesRenderer: FloorPlanTablesRenderer =
    interactiveFloorPlanTablesRendererFactory<FloorPlanTableData>({
      calculateIsHighlighted,
      calculateIsSelectable,
      handleTableOnClick,
      tables,
      unselectableTooltipText:
        'This table is not listed at the selected date and time.',
    });

  return (
    <div className={styles.floorPlanContainer}>
      <div>
        <FloorPlanDateSelector />
        <FloorPlanTimeSelector />
      </div>
      <FloorPlan
        backgroundSrc={backgroundSrc}
        className={className}
        floorPlanTablesRenderer={floorPlanTablesRenderer}
        handleBackgroundOnClick={clearFloorPlanSelection}
        ref={zoomRef}
      />
      {!!selectedFloorPlanTableListingIds.length && (
        <button
          className={cx(typography.t1, styles.clearSelectionButton)}
          data-testid="clear-floor-plan-selection-button-wide"
          onClick={clearFloorPlanSelection}
        >
          <Icon className={styles.resetIcon} name="reset" /> Clear floor plan
          selection
        </button>
      )}
    </div>
  );
};
