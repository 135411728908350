import { Button, ButtonVariants } from '@components/button/Button';
import type { FloorPlanTablesRenderer } from '@components/floorPlan/FloorPlan';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { floorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { StripeElements } from '@components/StripeElements';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import type { FloorPlanTableData } from '@shared/types/floorPlans';
import { toISODateFormat } from '@utils/date';
import { useAdminFloorPlan } from 'restaurantAdmin/hooks/useAdminFloorPlan';
import { useAvailabilityDrawer } from 'restaurantAdmin/reservations/concierge/state/availabilityDrawerContext';
import { TimeFilterPill } from '../../components/timeFilterPill/TimeFilterPill';
import { useAdminAvailability } from '../../hooks/useAdminAvailability';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import { AdminAvailability } from './AdminAvailability';
import { ConciergeDrawer } from './ConciergeDrawer';
import styles from './ConciergePage.scss';
import { DateAndGuestCountFilterPill } from './DateAndGuestCountFilterPill';

export const ConciergePage = () => {
  const { isOpen: isAvailabilityDrawerOpen } = useAvailabilityDrawer();
  const { floorPlan, isLoading } = useAdminFloorPlan();
  const {
    availabilities,
    availableTimes,
    handleShowEarlier,
    handleShowLater,
    hasEarlier,
    hasLater,
    selectedAvailability,
    selectedDate,
    selectedGuestCount,
    selectedTime,
    setSelectedAvailability,
    setSelectedDate,
    setSelectedGuestCount,
    setSelectedTime,
  } = useAdminAvailability();
  const { openDrawer } = useAvailabilityDrawer();
  const {
    close: closeGuestCountAndDateFilter,
    isOpen: isGuestCountAndDateFilterOpen,
    open: openGuestCountAndDateFilter,
  } = useIsOpen();
  const {
    close: closeTimeFilter,
    isOpen: isTimeFilterOpen,
    open: openTimeFilter,
  } = useIsOpen();

  const calculateIsHighlighted = (floorPlanTable: FloorPlanTableData) =>
    !!(
      selectedAvailability &&
      floorPlanTable.listings.find(
        ({ id }) => id === selectedAvailability.listing.id,
      )
    );

  const floorPlanTablesRenderer: FloorPlanTablesRenderer =
    floorPlanTablesRendererFactory({
      calculateIsHighlighted,
      tables: floorPlan?.floorPlanTables || [],
    });

  const handleReserveClick = () => {
    if (selectedAvailability) {
      openDrawer({
        date: toISODateFormat(selectedDate),
        guestCount: selectedGuestCount,
        listingPrice: selectedAvailability.listing.price,
        listingId: selectedAvailability.listing.id,
        time: selectedAvailability.time,
      });
    }
  };

  return (
    <StripeElements>
      <PageHeader category="Reservations" title="Concierge" />
      <PageContent className={styles.pageContent}>
        <div className={styles.filters}>
          <DateAndGuestCountFilterPill
            closeFilter={closeGuestCountAndDateFilter}
            isOpenFilter={isGuestCountAndDateFilterOpen}
            onFilterApply={() => setSelectedAvailability(undefined)}
            openFilter={() => {
              closeTimeFilter();
              openGuestCountAndDateFilter();
            }}
            selectedDate={selectedDate}
            selectedGuestCount={selectedGuestCount}
            setSelectedDate={setSelectedDate}
            setSelectedGuestCount={setSelectedGuestCount}
          />
          <TimeFilterPill
            availableTimes={availableTimes}
            closeFilter={closeTimeFilter}
            isOpenFilter={isTimeFilterOpen}
            onSelectTime={setSelectedTime}
            openFilter={() => {
              closeGuestCountAndDateFilter();
              openTimeFilter();
            }}
            selectedTime={selectedTime}
          />
        </div>
        <div className={styles.content}>
          {!isLoading && floorPlan && (
            <FloorPlan
              backgroundSrc={floorPlan?.backgroundSrc}
              className={styles.floorPlan}
              floorPlanTablesRenderer={floorPlanTablesRenderer}
            />
          )}
          <AdminAvailability
            availabilities={availabilities}
            availabilityItemAction={
              <Button
                className={styles.reserveButton}
                label="Reserve"
                onClick={handleReserveClick}
                variant={ButtonVariants.Primary}
              />
            }
            handleShowEarlier={handleShowEarlier}
            handleShowLater={handleShowLater}
            hasEarlier={hasEarlier}
            hasLater={hasLater}
            selectedAvailability={selectedAvailability}
            selectedGuestCount={selectedGuestCount}
            setSelectedAvailability={setSelectedAvailability}
          />
        </div>
        {isAvailabilityDrawerOpen && <ConciergeDrawer />}
      </PageContent>
    </StripeElements>
  );
};
