// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StrandedSheet__container___RKSLX{backdrop-filter:blur(5px);background:rgba(23,28,39,.9);border-radius:8px;border:1px solid var(--hairline);bottom:0;overflow-y:auto;padding:24px;position:fixed;top:272px;width:380px;z-index:var(--z_strandedSheet);display:flex;flex-direction:column;gap:16px}.StrandedSheet__container___RKSLX header{display:flex;flex-direction:column;row-gap:.5rem}.StrandedSheet__container___RKSLX .StrandedSheet__guestIdentifiers___Acajq{display:flex;flex-direction:row;align-items:center;justify-content:flex-start;column-gap:1rem}.StrandedSheet__container___RKSLX .StrandedSheet__guestIdentifiers___Acajq h2{text-wrap:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:75%}.StrandedSheet__container___RKSLX .StrandedSheet__details___PEESd{align-items:center;display:flex;justify-content:space-between;padding-top:8px}.StrandedSheet__container___RKSLX .StrandedSheet__closeButton___bW7oN{background-color:rgba(0,0,0,0);border:none;cursor:pointer;position:absolute;right:1rem}.StrandedSheet__container___RKSLX .StrandedSheet__closeButton___bW7oN .StrandedSheet__icon___gaKDb{stroke:var(--white70);width:20px}.StrandedSheet__container___RKSLX .StrandedSheet__guestCount___b7BZg{color:var(--white70);text-transform:capitalize}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/stranded/StrandedSheet.scss"],"names":[],"mappings":"AAAA,kCACE,yBAAA,CACA,4BAAA,CACA,iBAAA,CACA,gCAAA,CACA,QAAA,CACA,eAAA,CACA,YAAA,CACA,cAAA,CACA,SAAA,CACA,WAAA,CACA,8BAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,yCACE,YAAA,CACA,qBAAA,CACA,aAAA,CAGF,2EACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,0BAAA,CACA,eAAA,CAEA,8EACE,gBAAA,CACA,eAAA,CACA,sBAAA,CACA,aAAA,CAIJ,kEACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,eAAA,CAGF,sEACE,8BAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,UAAA,CAEA,mGACE,qBAAA,CACA,UAAA,CAIJ,qEACE,oBAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "StrandedSheet__container___RKSLX",
	"guestIdentifiers": "StrandedSheet__guestIdentifiers___Acajq",
	"details": "StrandedSheet__details___PEESd",
	"closeButton": "StrandedSheet__closeButton___bW7oN",
	"icon": "StrandedSheet__icon___gaKDb",
	"guestCount": "StrandedSheet__guestCount___b7BZg"
};
export default ___CSS_LOADER_EXPORT___;
