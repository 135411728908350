// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestTags__container___VkSkw{display:flex;align-items:flex-start;gap:.5rem;background-color:var(--panelBlue);border-radius:8px;padding:1rem}.GuestTags__containerEdit___gPTJm{display:block}.GuestTags__heading___j4kv4{margin-right:1rem}.GuestTags__btn___KzimX{background:none;border:none;color:var(--white);cursor:pointer;white-space:nowrap}.GuestTags__iconBtn___r1a3q{align-items:center;float:right;margin-left:auto}.GuestTags__icon___Ng6YB{height:16px;margin-right:4px;stroke:var(--white);width:16px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/guestTags/GuestTags.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,sBAAA,CACA,SAAA,CACA,iCAAA,CACA,iBAAA,CACA,YAAA,CAGF,kCACE,aAAA,CAGF,4BACE,iBAAA,CAGF,wBACE,eAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CACA,kBAAA,CAGF,4BACE,kBAAA,CACA,WAAA,CACA,gBAAA,CAGF,yBACE,WAAA,CACA,gBAAA,CACA,mBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GuestTags__container___VkSkw",
	"containerEdit": "GuestTags__containerEdit___gPTJm",
	"heading": "GuestTags__heading___j4kv4",
	"btn": "GuestTags__btn___KzimX",
	"iconBtn": "GuestTags__iconBtn___r1a3q",
	"icon": "GuestTags__icon___Ng6YB"
};
export default ___CSS_LOADER_EXPORT___;
